import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Grid, Card, CardHeader, Typography, CardContent, Avatar, Button, Divider } from '@material-ui/core';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { makeStyles } from '@material-ui/styles';
import * as HistoricoActions from '~/store/modules/historico/actions';
import * as LoadingActions from '~/store/modules/loading/actions';
import { getInitials } from 'helpers';
import HistoricoList from '~/components/HistoricoList';

import api from '../../services/api';
import EditarHistorico from './EditarHistorico';

// import { Container } from './styles';

const useStyles = makeStyles(theme => ({
 root: {
  padding: theme.spacing(3)
 },
 content: {
  marginTop: theme.spacing(2)
 }
}));

function EditarAvaliacao(props) {
 const dispatch = useDispatch();
 const { id_agendamento, id_cliente } = useParams();
 const { historicoAtivo } = useSelector(state => state.historico);
 const [appointment, setAppointment] = useState(false);
 const [lastAppointments, setLastAppointments] = useState([]);

 const classes = useStyles();

 useEffect(() => {
  async function loadHistoric() {
   dispatch(LoadingActions.setLoading());
   const res = await api.get(`/appointments/${id_agendamento}`);
   setAppointment(res.data);
   dispatch(LoadingActions.closeLoading());
  }
  loadHistoric();
 }, [id_agendamento]);

 function handleEdit(historico) {
  dispatch(HistoricoActions.editHistoricoRequest(historico));
 }

 const loadLastAppointments = useCallback(async () => {
  dispatch(LoadingActions.setLoading());
  const res = await api.get(`/customers/${id_cliente}/appointments`);
  setLastAppointments(res.data.results.filter(appointment => appointment.appointment_id != parseInt(id_agendamento)));
  dispatch(LoadingActions.closeLoading());
 }, [id_cliente]);

 return (
  <>
   <div className={classes.root}>
    <div className={classes.content}>
     <Grid container spacing={4}>
      <Grid item xs={12}>
       <Card>
        {appointment && (
         <>
          <CardHeader
           avatar={
            <Avatar className={classes.avatar} src={appointment.customer_id.avatar}>
             {getInitials(appointment.customer_id.name)}
            </Avatar>
           }
           title={
            <Grid container direction="row" alignItems="center" justify="space-between">
             <Grid item>
              <Typography variant="h5" component="h2">
               {appointment.customer_id.name}
              </Typography>
             </Grid>
             <Grid item>
              <Grid container direction="row" alignItems="center">
               <PhoneIphoneIcon />
               <Typography variant="h5">{appointment.customer_id.phone}</Typography>
              </Grid>
             </Grid>
            </Grid>
           }
           subheader={appointment.customer_id.age}
          />
          <CardContent>
           <Grid container direction="row" alignItems="center" justify="space-between">
            <Typography variant="h5" component="h2">
             Pronturário do dia: {appointment.date} às {appointment.time}
            </Typography>
            <Grid>
             <Typography variant="body1">{appointment.type.name}</Typography>
             <Typography variant="body1">{appointment.healthplan_id.name}</Typography>
            </Grid>
           </Grid>
           <Typography className={classes.pos} color="textSecondary">
            Observações:
           </Typography>
           <Typography variant="body2" color="textSecondary" component="p">
            {appointment.note}
           </Typography>
          </CardContent>
         </>
        )}
       </Card>
      </Grid>
      <Grid item xs={12}>
       <Card>{appointment && <EditarHistorico handleSubmit={handleEdit} historico={appointment.historic[0]} />}</Card>
      </Grid>

      <Grid item container spacing={4} justifyContent="center">
       <Divider />
       {lastAppointments.length > 0 ? (
        <>
         <Grid item alignContent="center" spacing={4}>
          <Typography variant="h2" color="textSecondary">
           Últimos Atendimentos
          </Typography>
         </Grid>
         {lastAppointments
          .filter(appointment => Date.parse(appointment.datetime) <= Date.now())
          .map(historico => {
           return (
            <Grid item key={historico.appointment_id}>
             <HistoricoList
              historico={historico}
              //    handleEditarAvaliacao={handleEditarAvaliacao}
              //    handleOpenFoto={handleOpenFoto}
             />
            </Grid>
           );
          })}
        </>
       ) : (
        <Button onClick={loadLastAppointments} color="primary" variant="outlined">
         Carregar Últimos Atendimentos
        </Button>
       )}
      </Grid>
     </Grid>
    </div>
   </div>
  </>
 );
}

export default EditarAvaliacao;
