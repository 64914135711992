export function setClientesRequest() {
 return {
  type: '@clientes/SET_CLIENTE_REQUEST'
 };
}

export function setClientesSuccess(clientes) {
 return {
  type: '@clientes/SET_CLIENTE_SUCCESS',
  payload: {
   clientes
  }
 };
}

export function addClienteRequest(data) {
 return {
  type: '@clientes/ADD_CLIENTES_REQUEST',
  payload: {
   data
  }
 };
}

export function setAvatarCliente(avatar, customer_id) {
 return {
  type: '@clientes/SET_AVATAR_CLIENTE',
  payload: {
   avatar,
   customer_id
  }
 };
}

export function setClienteAtivoSuccess(cliente) {
 return {
  type: '@clientes/SET_CLIENTE_ATIVO_SUCCESS',
  payload: {
   cliente
  }
 };
}

export function editClienteRequest(cliente) {
 return {
  type: '@clientes/EDIT_CLIENTE_REQUEST',
  payload: {
   cliente
  }
 };
}

export function setLoading(status) {
 return {
  type: '@clientes/SET_LOADING',
  payload: {
   status
  }
 };
}
