import React, { useMemo } from 'react';

import { Card, CardActions, CardContent, Grid, Button, CardHeader, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import BoxAvaliacao from '~/components/BoxAvaliacao';
import ImageThumbnail from '~/components/ImageThumbnail';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';

const useStyles = makeStyles(() => ({
 thumb: {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
  '&:hover': {
   width: 120,
   height: 120,
   transition: '0.5s'
  },
  borderRadius: 5,
  '&:hover': {
   opacity: 0.5,
   transition: '0.5s'
  }
 },
 thumbInner: {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
 },
 img: {
  display: 'block',
  width: 'auto',
  height: '100%'
 },
 excludeThumb: {
  position: 'absolute',
  float: 'right'
 },
 downloadFile: {
  marginTop: -20,
  marginRight: -20,
  textAlign: 'right'
 }
}));

export default function ListExams({ exam, handleOpenFoto, handleEditExam }) {
 const classes = useStyles();

 const dateFormatted = useMemo(() => {
  const date = new Date(exam.created_at);
  if (date instanceof Date && !isNaN(date)) {
   return format(date, "d 'de' MMMM 'de' y", { locale: pt });
  }
  return '';
 }, [exam]);

 return (
  <Card>
   <CardHeader title={dateFormatted} subheader={`Detalhes do exame número ${exam?.exam_id}`} />
   <CardContent>
    <Grid container spacing={2} item xs={12}>
     <Grid item xs={12} md={6}>
      <BoxAvaliacao title="Resultado" value={exam?.result} />
     </Grid>
     <Grid item xs={12} md={6}>
      <BoxAvaliacao title="Conduta" value={exam?.conduct} />
     </Grid>
     <Grid container direction="row" xs={12} md={12} justifyContent="center" alignContent="center" alignItems="center">
      {exam?.images
       ? exam?.images.map((file, index) => (
          <ImageThumbnail key={index} index={index} images={exam?.images} file={file} handleOpenFoto={handleOpenFoto} />
         ))
       : 'Sem arquivos'}
     </Grid>
     {exam?.appointment_id && (
      <Grid item xs={12} md={12}>
       <Card>
        <CardContent>
         <Typography variant="h6" color="textPrimary">
          Vinculado ao atendimento do dia:
         </Typography>
         <Typography variant="h6" color="textSecondary">
          {exam?.appointment_id?.date} {exam?.appointment_id?.time}
         </Typography>
        </CardContent>
       </Card>
      </Grid>
     )}
    </Grid>
   </CardContent>
   <Grid item container justify="flex-end">
    <CardActions>
     <Button onClick={() => handleEditExam(exam)} variant="outlined" color="primary">
      Editar
     </Button>
    </CardActions>
   </Grid>
  </Card>
 );
}
