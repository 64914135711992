import React from 'react';

import { Grid, Switch, FormControlLabel, CardContent, IconButton, TextField, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const LegalGuardian = props => {
 const { guardian, remove, handleChange } = props;

 const { className, ...rest } = props;

 return (
  <>
   <CardContent>
    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
     <Grid item md={1} xs={12}>
      <Tooltip title="Remover responsável" arrow placement="top">
       <IconButton onClick={() => remove(guardian)}>
        <DeleteIcon />
       </IconButton>
      </Tooltip>
     </Grid>
     <Grid item container spacing={2} alignItems="stretch" md={11} xs={12}>
      <Grid item md={2} xs={12}>
       <TextField
        fullWidth
        helperText="Ex: Mãe, Pai.."
        label="Parentesco"
        margin="dense"
        name="kinship"
        onChange={e => handleChange(e, guardian?.legal_guardian_id)}
        value={guardian?.kinship || ''}
        variant="outlined"
        // error={Boolean(errors.kinship && touched.kinship)}
        // onBlur={handleBlur}
       />
      </Grid>
      <Grid item md={10} xs={12}>
       <TextField
        fullWidth
        helperText="Nome completo do responsável pelo paciente"
        label="Nome do Responsável"
        margin="dense"
        name="name"
        onChange={e => handleChange(e, guardian?.legal_guardian_id)}
        value={guardian?.name || ''}
        variant="outlined"
        // error={Boolean(errors.name && touched.name)}
        // onBlur={handleBlur}
       />
      </Grid>
      <Grid item md={2} xs={12}>
       <FormControlLabel
        control={
         <Switch
          checked={guardian?.main || false}
          onChange={e => handleChange(e, guardian?.legal_guardian_id)}
          name="main"
         />
        }
        label="Principal"
       />
      </Grid>
      <Grid item md={3} xs={12}>
       <TextField
        fullWidth
        label="CPF do Responsável"
        margin="dense"
        name="cpf"
        onChange={e => handleChange(e, guardian?.legal_guardian_id)}
        value={guardian?.cpf || ''}
        variant="outlined"
        // error={Boolean(errors.cpf && touched.cpf)}
        // onBlur={handleBlur}
       />
      </Grid>
      <Grid item md={3} xs={12}>
       <TextField
        fullWidth
        label="RG do Responsável"
        margin="dense"
        name="rg"
        onChange={e => handleChange(e, guardian?.legal_guardian_id)}
        value={guardian?.rg || ''}
        variant="outlined"
        // error={Boolean(errors.rg && touched.rg)}
        // onBlur={handleBlur}
       />
      </Grid>
      <Grid item md={3} xs={12}>
       <TextField
        fullWidth
        label="Celular do Responsável"
        margin="dense"
        name="phone"
        onChange={e => handleChange(e, guardian?.legal_guardian_id)}
        value={guardian?.phone || ''}
        variant="outlined"
        // error={Boolean(errors.phone && touched.phone)}
        // onBlur={handleBlur}
       />
      </Grid>
     </Grid>
    </Grid>
   </CardContent>
  </>
 );
};

export default LegalGuardian;
