import api from '~/services/api';
import history from '~/services/history';
import * as AlertActions from '~/store/modules/alert/actions';
import * as LoadingActions from '~/store/modules/loading/actions';
import { format, isAfter, subDays } from 'date-fns';
import { pt } from 'date-fns/locale';
import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import {
 setChartSuccess,
 setLoading,
 setAgendaSuccess,
 setIdAgendamentoAtivo,
 cancelAgendamentoSuccess,
 setAppointmentStatusSuccess
} from './actions';

function* add({ payload }) {
 yield put(setLoading(true));
 const { data } = payload;
 var date = data.date.getFullYear() + '-' + (data.date.getMonth() + 1) + '-' + data.date.getDate();
 var time = data.time.getHours() + ':' + data.time.getMinutes() + ':' + data.time.getSeconds();
 var datetime = date + ' ' + time;

 const appointment = {
  datetime: datetime,
  cancel: false,
  note: data.note,
  type: data.type.appointment_type_id,
  customer_id: data.customer_id.customer_id,
  id_user: data.id_user.id_user,
  status: data.status.appointment_status_id,
  healthplan_id: data.healthplan_id.healthplan_id
 };

 try {
  const res = yield call(api.post, 'appointments', appointment);

  yield put(setIdAgendamentoAtivo(res.data));

  history.push('/agenda');

  yield put(AlertActions.success('Agendamento feito com sucesso!'));
 } catch (error) {
  yield put(AlertActions.error('Erro ao tentar agendar.'));
  yield put(setLoading(false));
 }
 yield put(setLoading(false));
}

function* edit({ payload }) {
 yield put(setLoading(true));

 const { data } = payload;
 let time = undefined;
 let date = undefined;
 let datetime = undefined;
 let appointment = data;
 let type = Number.isInteger(data.type) ? data.type : data.type.appointment_type_id;
 let customer_id = Number.isInteger(data.customer_id) ? data.customer_id : data.customer_id?.customer_id;
 let id_user = Number.isInteger(data.id_user) ? data.id_user : data.id_user?.id_user;
 let status = Number.isInteger(data.status) ? data.status : data.status?.appointment_status_id;
 let healthplan = Number.isInteger(data.healthplan_id) ? data.healthplan_id : data.healthplan_id?.healthplan_id;

 if (data?.time instanceof Date) {
  time = data.time.getHours() + ':' + data.time.getMinutes() + ':' + data.time.getSeconds();
  let date = data.datetime.split('T')[0];
  datetime = date + ' ' + time.split('-')[0];
 }

 if (data?.date instanceof Date) {
  date = data.date.getFullYear() + '-' + (data.date.getMonth() + 1) + '-' + data.date.getDate();
  let time = data.datetime.slice(0, -1).split('T')[1];
  datetime = date + ' ' + time.split('-')[0];
 }

 if (data?.time instanceof Date && data?.date instanceof Date) {
  time = data.time.getHours() + ':' + data.time.getMinutes() + ':' + data.time.getSeconds();
  date = data.date.getFullYear() + '-' + (data.date.getMonth() + 1) + '-' + data.date.getDate();
  datetime = date + ' ' + time;
 }

 appointment = {
  datetime: datetime
   ? datetime
   : `${data.datetime.slice(0, -1).split('T')[0]} ${
      data.datetime
       .slice(0, -1)
       .split('T')[1]
       ?.split('-')[0]
     }`,
  cancel: false,
  note: data.note,
  type: type,
  customer_id: customer_id,
  id_user: id_user,
  status: status,
  healthplan_id: healthplan
 };

 try {
  yield call(api.put, `appointments/${data.appointment_id}`, appointment);
  yield put(AlertActions.success('Agendamento editado com sucesso!'));
  history.push('/agenda');
 } catch (error) {
  yield put(AlertActions.error('Erro ao tentar editar o agendamento.'));
  yield put(setLoading(false));
 }
 yield put(setLoading(false));
}

function* setChart({ payload }) {
 yield put(setLoading(true));

 const { user } = yield select();
 const { filter } = payload;

 const res = yield call(api.get, `agendamentos/?id_user=${user.profile.id_user}`);
 const resClientes = yield call(api.get, `clientes/?id_user=${user.profile.id_user}`);

 const ultimosAtendimentos = [];
 const ultimosDias = [['Dias', 'Atendimentos']];

 let agendamentos = res.data.filter(
  agen =>
   isAfter(Date.parse(agen.data_agendamento), Date.parse(format(new Date(), "Y'-'MM'-'dd"))) ||
   (parseInt(format(new Date(), 'H')) < parseInt(agen.hora_agendamento.split(':')[0]) &&
    agen.data_agendamento === format(new Date(), "Y'-'MM'-'dd") &&
    !agen.cancel_agendamento)
 );

 agendamentos.slice(0, 6).map(agen => {
  let date = agen.data_agendamento.split('-');

  let data = {
   id: agen.id_agendamento,
   data: `${date[2]}/${date[1]}/${date[0]}`,
   hora: `${agen.hora_agendamento}:00h`,
   customer: {
    name: ''
   },
   createdAt: agen.data_criacao_agendamento,
   cel: ''
  };
  resClientes.data.map(cliente => {
   if (cliente.id_cliente === agen.id_cliente) {
    data['customer']['name'] = cliente.nome_cliente;
    data['cel'] = cliente.cel_cliente;
   }
   return cliente;
  });
  ultimosAtendimentos.push(data);
  return agen;
 });

 for (let i = filter.value; i > 0; i--) {
  const date = format(subDays(new Date(), i), "Y'-'MM'-'dd");
  const res = yield call(api.get, `agendamentos/?id_user=${user.profile.id_user}&data_agendamento=${date}`);

  const labelDate = format(subDays(new Date(), i), 'd MMM', { locale: pt });

  let data = [labelDate, res.data.length];

  ultimosDias.push(data);
 }

 yield put(setChartSuccess(ultimosAtendimentos, ultimosDias));
 yield put(setLoading(false));
}

function* cancelAgendamento({ payload }) {
 const { id } = payload;

 try {
  const res = yield call(api.patch, `/appointments/${id}/cancel`);

  yield put(cancelAgendamentoSuccess(id));

  yield put(AlertActions.success('Agendamento cancelado!'));
 } catch (error) {
  yield put(AlertActions.error('Erro ao tentar cancelar.'));
 }
}

function* setAgenda({ payload }) {
 yield put(LoadingActions.setLoading());

 const date = format(payload.date, 'yyyy-MM-dd');

 try {
  // const resHorarios = yield call(api.get, `horarios/`);
  // let horarios = resHorarios.data;

  const resAgendamentos = yield call(api.get, `/appointments/date/${date}`, {
   params: {
    id_user: payload.id_user
   }
  });

  // const agendamentos = resAgendamentos.data;

  // const new_agendamento = horarios
  //   .filter(h => h.active)
  //   .map(h => {
  //     h = {
  //       ...h,
  //       agendamento: agendamentos.find(a => a.hora_agendamento === h.horario)
  //     };
  //     return h;
  //   });

  yield put(setAgendaSuccess(resAgendamentos.data));
 } catch (error) {}
 yield put(LoadingActions.closeLoading());
}

function* setAppointmentStatus({ payload }) {
 try {
  yield put(LoadingActions.setLoading());
  const { status_id, appointment_id } = payload;

  const status = {
   status: status_id
  };

  const { data } = yield call(api.patch, `/appointments/${appointment_id}`, status);

  yield put(setAppointmentStatusSuccess(data));
 } catch (error) {
  yield put(LoadingActions.closeLoading());
 }
 yield put(LoadingActions.closeLoading());
}

export default all([
 takeLatest('@agenda/ADD_AGENDAMENTO', add),
 takeLatest('@agenda/EDIT_AGENDAMENTO', edit),
 // takeLatest('@agenda/SET_CHART_REQUEST', setChart),
 takeLatest('@agenda/CANCEL_AGENDAMENTO_REQUEST', cancelAgendamento),
 takeLatest('@agenda/SET_AGENDA_REQUEST', setAgenda),
 takeLatest('@agenda/SET_APPOINTMENT_STATUS_REQUEST', setAppointmentStatus)
]);
