import produce from 'immer';

const INITIAL_STATE = {
 loading: false,
 newRegister: {}
};

export default function register(state = INITIAL_STATE, action) {
 return produce(state, draft => {
  switch (action.type) {
   case '@register/LOADING': {
    draft.loading = action.payload.status;
    break;
   }

   case '@register/REGISTER_REQUEST': {
    draft.newRegister = {
     email: '',
     first_name: '',
     last_name: '',
     phone: ''
    };
    break;
   }

   default:
    return state;
  }
 });
}
