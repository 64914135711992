import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
 Card,
 CardHeader,
 CardContent,
 CardActions,
 Divider,
 Grid,
 Button,
 TextField,
 FormControlLabel,
 CircularProgress,
 FormControl,
 FormLabel,
 Radio,
 RadioGroup
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import LegalGuardian from '~/components/LegalGuardian';
import * as ClientesActions from '~/store/modules/clientes/actions';
import cep from 'cep-promise';
import clsx from 'clsx';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

const useStyles = makeStyles(() => ({
 root: {},
 buttonProgress: {
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12
 }
}));

const schema = yup.object().shape({
 name: yup.string().required('Campo obrigatório'),
 birthday: yup.date().required('Campo obrigatório'),
 gender: yup.string().required('Campo obrigatório'),
 address: yup.string(),
 number_address: yup.number(),
 neighborhood: yup.string(),
 rg: yup.string(),
 cpf: yup.string(),
 email: yup.string().email(),
 phone: yup.string().min(11)
});

const AddCliente = props => {
 const dispatch = useDispatch();
 const { loading } = useSelector(state => state.clientes);
 const { className, ...rest } = props;
 const [postalCodeCEP, setPostalCodeCEP] = useState('');
 const [initialValues, setInitialValues] = useState({
  name: '',
  birthday: null,
  address: '',
  number_address: 0,
  neighborhood: '',
  postal_code: null,
  email: '',
  phone: '',
  rg: '',
  cpf: '',
  gender: '',
  city: ''
 });
 const [guardians, setGuardians] = useState([{ legal_guardian_id: 0 }]);

 const classes = useStyles();

 function handleSubmit(data) {
  let hasLegalGuardian = false;
  guardians.map(g => {
   if (g?.kinship) {
    hasLegalGuardian = true;
   }
  });
  if (hasLegalGuardian) {
   data = {
    ...data,
    legal_guardians: guardians
   };
  }

  dispatch(ClientesActions.addClienteRequest(data));
 }

 useEffect(() => {
  async function loadCEP() {
   try {
    const data = await cep(postalCodeCEP);
    setInitialValues({
     ...initialValues,
     address: data.street,
     city: data.city,
     neighborhood: data.neighborhood,
     postal_code: postalCodeCEP
    });
   } catch (error) {}
  }
  if (postalCodeCEP.length === 8) {
   loadCEP();
  } else {
   setInitialValues({
    ...initialValues,
    address: '',
    city: '',
    neighborhood: '',
    postal_code: postalCodeCEP
   });
  }
 }, [postalCodeCEP]);

 const handleGuardian = useCallback(() => {
  setGuardians([...guardians, { legal_guardian_id: guardians.length }]);
 }, [guardians]);

 const removeGuardian = useCallback(
  guardian => {
   let notRemoved = guardians.filter(g => g?.legal_guardian_id !== guardian?.legal_guardian_id);
   setGuardians(notRemoved);
  },
  [guardians]
 );

 function handleChangeGuardian(e, id) {
  let { name, value } = e.target;
  if (!e.target.value) {
   value = e.target.checked;
  }
  let newListLegalGuardianEdited = guardians.map(guardian => {
   if (guardian?.legal_guardian_id === id) {
    guardian[name] = value;
   }
   return guardian;
  });
  setGuardians(newListLegalGuardianEdited);
 }

 return (
  <Card {...rest} className={clsx(classes.root, className)}>
   <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema} enableReinitialize={true}>
    {props => {
     const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = props;

     function handleChangeCep(postal_code) {
      setInitialValues(values);
      handleChange(postal_code);
      setPostalCodeCEP(postal_code);
     }

     return (
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
       <CardHeader subheader="Cadastrar novo paciente" title="Paciente" />
       <Divider />
       <CardContent>
        <Grid container spacing={3}>
         <Grid item md={6} xs={12}>
          <TextField
           fullWidth
           helperText="Nome completo do paciente"
           label="Nome"
           margin="dense"
           name="name"
           onChange={handleChange}
           required
           value={values.name}
           variant="outlined"
           error={Boolean(errors.name && touched.name)}
           onBlur={handleBlur}
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <KeyboardDatePicker
           margin="dense"
           label="Data de nascimento"
           name="birthday"
           format="dd/MM/yyyy"
           value={values.birthday}
           inputVariant="outlined"
           onChange={date => setFieldValue('birthday', date)}
           KeyboardButtonProps={{
            'aria-label': 'change date'
           }}
           InputLabelProps={{
            shrink: true
           }}
           error={Boolean(errors.birthday && touched.birthday)}
           onBlur={handleBlur}
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <TextField
           fullWidth
           label="RG"
           margin="dense"
           name="rg"
           type="number"
           onChange={handleChange}
           value={values.rg}
           variant="outlined"
           error={Boolean(errors.rg && touched.rg)}
           onBlur={handleBlur}
           helperText="Somente números"
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <TextField
           fullWidth
           label="CPF"
           margin="dense"
           name="cpf"
           type="number"
           onChange={handleChange}
           value={values.cpf}
           variant="outlined"
           error={Boolean(errors.cpf && touched.cpf)}
           onBlur={handleBlur}
           helperText="Somente números"
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <TextField
           fullWidth
           label="CEP"
           margin="dense"
           name="postal_code"
           onChange={e => handleChangeCep(e.target.value)}
           value={values.postal_code}
           variant="outlined"
           type="number"
           error={Boolean(errors.postal_code && touched.postal_code)}
           onBlur={handleBlur}
           helperText={touched.postal_code && errors.postal_code && 'Insira um número válido'}
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <TextField
           fullWidth
           label="Rua / Avenida"
           margin="dense"
           name="address"
           onChange={handleChange}
           value={values.address}
           variant="outlined"
           error={Boolean(errors.address && touched.address)}
           onBlur={handleBlur}
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <TextField
           fullWidth
           label="Num"
           margin="dense"
           name="number_address"
           onChange={handleChange}
           type="number"
           value={values.number_address}
           variant="outlined"
           helperText={touched.number_address && errors.number_address && 'Insira um número válido'}
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <TextField
           fullWidth
           label="Bairro"
           margin="dense"
           name="neighborhood"
           onChange={handleChange}
           value={values.neighborhood}
           variant="outlined"
           error={Boolean(errors.neighborhood && touched.neighborhood)}
           onBlur={handleBlur}
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <TextField
           fullWidth
           label="Cidade"
           margin="dense"
           name="city"
           onChange={handleChange}
           value={values.city}
           variant="outlined"
           error={Boolean(errors.city && touched.city)}
           onBlur={handleBlur}
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <TextField
           fullWidth
           label="E-mail"
           margin="dense"
           name="email"
           onChange={handleChange}
           value={values.email}
           variant="outlined"
           error={Boolean(errors.email && touched.email)}
           onBlur={handleBlur}
           helperText={touched.email && errors.email && 'Insira um e-mail válido'}
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <TextField
           fullWidth
           label="Celular"
           margin="dense"
           name="phone"
           onChange={handleChange}
           value={values.phone}
           variant="outlined"
           error={Boolean(errors.phone && touched.phone)}
           onBlur={handleBlur}
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <FormControl required error={Boolean(errors.gender && touched.gender)} margin="normal" component="fieldset">
           <FormLabel component="legend" style={{ fontWeight: 'bold' }}>
            Sexo
           </FormLabel>
           <RadioGroup name="gender" onChange={handleChange}>
            <Grid item>
             <FormControlLabel value="F" control={<Radio color="primary" />} label="Feminino" color="primary" />
             <FormControlLabel value="M" control={<Radio color="primary" />} label="Masculino" color="primary" />
             <FormControlLabel value="O" control={<Radio color="primary" />} label="Outros" color="primary" />
            </Grid>
           </RadioGroup>
          </FormControl>
         </Grid>
        </Grid>
       </CardContent>
       <Divider />
       <CardHeader
        title="Responsável"
        subheader="Pessoa responsável pelo paciente"
        action={
         <Button color="primary" variant="outlined" onClick={handleGuardian}>
          Novo Responsável
         </Button>
        }
       />
       {guardians.map(guardian => (
        <LegalGuardian
         key={guardian?.legal_guardian_id}
         guardian={guardian}
         remove={removeGuardian}
         handleChange={handleChangeGuardian}
        />
       ))}
       <Divider />
       <CardContent>
        <Grid container>
         <TextField
          fullWidth
          multiline
          rows={6}
          label="Detalhes"
          margin="dense"
          onChange={handleChange}
          variant="outlined"
          id="details"
          name="details"
          error={Boolean(errors.details)}
          onBlur={handleBlur}
         />
        </Grid>
       </CardContent>
       <CardActions>
        <Button type="submit" color="primary" variant="contained" disabled={loading}>
         Salvar
         {loading && <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />}
        </Button>
       </CardActions>
      </form>
     );
    }}
   </Formik>
  </Card>
 );
};

AddCliente.propTypes = {
 className: PropTypes.string
};

export default AddCliente;
