import api from '~/services/api';
import * as AlertActions from '~/store/modules/alert/actions';
import * as LoadingActions from '~/store/modules/loading/actions';
import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import { saveProfileSuccess, loading, imageSuccess } from './actions';

export function* saveProfile({ payload }) {
 yield put(LoadingActions.setLoading());
 yield put(loading(true));
 const { editedProfile } = payload;
 const { user } = yield select();

 const profile = {
  first_name: editedProfile.first_name,
  last_name: editedProfile.last_name,
  email: editedProfile.email,
  professional_document: editedProfile.professional_document,
  specialty: editedProfile.specialty,
  title: editedProfile.title
 };

 try {
  const res = yield call(api.patch, `users/${user.profile.id_user}`, profile);

  yield put(saveProfileSuccess(res.data));
  yield put(loading(false));
  yield put(LoadingActions.closeLoading());
  yield put(AlertActions.success('Perfil salvo com sucesso!'));
 } catch (error) {
  yield put(LoadingActions.closeLoading());
  yield put(AlertActions.error('Erro ao tentar salvar seu perfil.'));
 }
}

export function* setImage({ payload }) {
 yield put(loading(true));
 const { user } = yield select();

 const { image } = payload;

 let data = new FormData();
 data.append('avatar', image[0]);

 try {
  const res = yield call(api.patch, `users/${user.profile.id_user}`, data);

  yield put(imageSuccess(res.data.avatar));
 } catch (error) {
  yield put(AlertActions.error('Erro ao tentar salvar seu perfil.'));
  yield put(loading(false));
 }
}

function* changePassword({ payload }) {
 yield put(LoadingActions.setLoading());

 const { user } = yield select();
 const { passwords } = payload;

 try {
  const res = yield call(api.put, `change-password`, passwords);

  yield put(AlertActions.success('Nova senha salva com sucesso!'));
  yield put(LoadingActions.closeLoading());
 } catch (error) {
  yield put(AlertActions.error('Erro ao tentar alterar sua senha.'));
  yield put(LoadingActions.closeLoading());
 }
}

export default all([
 takeLatest('@user/SAVE_PROFILE', saveProfile),
 takeLatest('@user/IMAGE_REQUEST', setImage),
 takeLatest('@user/CHANGE_PASSWORD', changePassword)
]);
