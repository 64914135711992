import React from 'react';

import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import convertToDateTimeBrazil from '~/helpers/convertToDateTimeBrazil';

const useStyles = makeStyles(theme => ({
 index: {
  padding: theme.spacing(2)
 }
}));

export default function UpdateAt({ hasUpdateBy = false, firstName = '', lastName = '', updateAt = null }) {
 const classes = useStyles();

 return (
  <Grid container spacing={3} className={classes.index}>
   {hasUpdateBy && (
    <Grid item justify="center" alignItems="center">
     <Typography variant="subtitle2" component="h6">
      Última atualização por:{' '}
     </Typography>
     <Typography variant="caption" component="p">
      {`${firstName} ${lastName}`}
     </Typography>
    </Grid>
   )}
   <Grid item justify="center" alignItems="center">
    <Typography variant="subtitle2" component="h6">
     Última atualização em:{' '}
    </Typography>
    <Typography variant="caption" component="p">
     {updateAt && convertToDateTimeBrazil(new Date(updateAt))}
    </Typography>
   </Grid>
  </Grid>
 );
}
