import produce from 'immer';

const INITIAL_STATE = {
 plan: {},
 loading: false,
 newRegister: {},
 modal: false,
 activeOrder: {},
 affiliate: null
};

export default function order(state = INITIAL_STATE, action) {
 return produce(state, draft => {
  switch (action.type) {
   case '@order/SET_PLAN': {
    draft.plan = action.payload.plan;
    break;
   }

   case '@auth/SIGN_IN_TO_ORDER_SUCCESS': {
    draft.newRegister = action.payload.user;
    break;
   }

   case '@order/SET_MODAL': {
    draft.modal = action.payload.bool;
    break;
   }

   case '@order/SET_ACTIVE_ORDER': {
    draft.activeOrder = action.payload.activeOrder;
    break;
   }

   case '@register/SET_AFFILIATE': {
    draft.affiliate = action.payload.affiliate;
    break;
   }

   default:
    return state;
  }
 });
}
