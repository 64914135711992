import React, { useCallback } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';

import { Grid, Button, IconButton, TextField, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import logo from '~/assets/logo-clinica.png';
import { sendEvent } from '~/helpers';
import * as AuthActions from '~/store/modules/auth/actions';
import { Formik } from 'formik';
import { Facebook as FacebookIcon } from 'icons';
import PropTypes from 'prop-types';
import * as yup from 'yup';

const schema = yup.object().shape({
 email: yup
  .string()
  .email()
  .required('Required field.'),
 password: yup.string().required('Required field.')
});

const useStyles = makeStyles(theme => ({
 root: {
  backgroundColor: theme.palette.background.default,
  height: '100%'
 },
 grid: {
  height: '100%'
 },
 quoteContainer: {
  [theme.breakpoints.down('md')]: {
   display: 'none'
  }
 },
 quote: {
  backgroundColor: theme.palette.neutral,
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: `url(${logo})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center'
 },
 quoteInner: {
  textAlign: 'center',
  flexBasis: '00px'
 },
 quoteText: {
  color: theme.palette.white,
  fontWeight: 300
 },
 name: {
  marginTop: theme.spacing(3),
  color: theme.palette.white
 },
 bio: {
  color: theme.palette.white
 },
 contentContainer: {},
 content: {
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
 },
 contentHeader: {
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(5),
  paddingBototm: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2)
 },
 logoImage: {
  marginLeft: theme.spacing(4)
 },
 contentBody: {
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
   justifyContent: 'center'
  }
 },
 form: {
  paddingLeft: 100,
  paddingRight: 100,
  paddingBottom: 125,
  flexBasis: 750,
  [theme.breakpoints.down('sm')]: {
   paddingLeft: theme.spacing(2),
   paddingRight: theme.spacing(2)
  }
 },
 title: {
  marginTop: theme.spacing(3)
 },
 socialButtons: {
  marginTop: theme.spacing(3)
 },
 socialIcon: {
  marginRight: theme.spacing(1)
 },
 sugestion: {
  marginTop: theme.spacing(2)
 },
 textField: {
  marginTop: theme.spacing(2)
 },
 signInButton: {
  margin: theme.spacing(2, 0)
 },
 signUpContainer: {
  marginTop: theme.spacing(2)
 }
}));

const SignIn = props => {
 const dispatch = useDispatch();
 const { history } = props;

 const classes = useStyles();

 // const [formState, setFormState] = useState({
 //   isValid: false,
 //   values: {},
 //   touched: {},
 //   errors: {}
 // });

 // useEffect(() => {
 //   const errors = validate(formState.values, schema);

 //   setFormState(formState => ({
 //     ...formState,
 //     isValid: errors ? false : true,
 //     errors: errors || {}
 //   }));
 // }, [formState.values]);

 const handleBack = () => {
  history.goBack();
 };

 // const handleChange = event => {
 //   event.persist();

 //   setFormState(formState => ({
 //     ...formState,
 //     values: {
 //       ...formState.values,
 //       [event.target.name]:
 //         event.target.type === 'checkbox'
 //           ? event.target.checked
 //           : event.target.value
 //     },
 //     touched: {
 //       ...formState.touched,
 //       [event.target.name]: true
 //     }
 //   }));
 // };

 function handleSubmit({ email, password }) {
  sendEvent('Login', 'Click - Entrar ', `Clicou no botão Entrar para fazer o login com o e-mail: ${email}`);
  dispatch(AuthActions.signInRequest(email, password));
 }

 // const hasError = field =>
 //   formState.touched[field] && formState.errors[field] ? true : false;

 const responseFacebook = response => {
  dispatch(AuthActions.signInWithSocialRequest(response?.accessToken));
 };

 const featureToggleLoginFacebook = () => {
  return Boolean(process.env.REACT_APP_DISABLE_TOGGLE_LOGIN_FACEBOOK) === true;
 };

 const handleSignUp = useCallback(() => {
  history.push('/registrar');
  sendEvent('Login', 'Click - Botão Criar Conta', 'Clicou no botão criar conta para se cadastrar');
 }, []);

 return (
  <div className={classes.root}>
   <Grid className={classes.grid} container>
    <Grid className={classes.quoteContainer} item lg={5}>
     <div className={classes.quote}>
      <div className={classes.quoteInner}>
       {/* <Typography className={classes.quoteText} variant="h1">
                Clinica 29
              </Typography> */}
       <div className={classes.person}>
        <Typography className={classes.name} variant="body1">
         {/* impulcetto */}
        </Typography>
        {/* <Typography className={classes.bio} variant="body2">
                  piertech
                </Typography> */}
       </div>
      </div>
     </div>
    </Grid>
    <Grid className={classes.content} item lg={7} xs={12}>
     <div className={classes.content}>
      <div className={classes.contentHeader}>
       <IconButton onClick={handleBack}>{/* <ArrowBackIcon /> */}</IconButton>
      </div>
      <div className={classes.contentBody}>
       <Formik
        initialValues={{
         email: '',
         password: ''
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        {props => {
         const {
          values: { email, password },
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit
         } = props;
         return (
          <form className={classes.form}>
           <Typography className={classes.title} variant="h2">
            Entrar
           </Typography>
           <Typography color="textSecondary" gutterBottom>
            Entrar com rede social
           </Typography>
           <Grid className={classes.socialButtons} container spacing={2} justify="space-evenly">
            <Grid
             item
             xs={12}
             md={12}
             onClick={() =>
              sendEvent(
               'Login',
               'Click - Entrar com Facebook',
               'Clicou no botão Entrar com Facebook para criar uma conta e fazer login'
              )
             }>
             <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
              autoLoad={false}
              callback={responseFacebook}
              cookie={false}
              render={renderProps => (
               <Button
                disabled={featureToggleLoginFacebook()}
                color="primary"
                size="large"
                fullWidth
                variant="contained"
                onClick={renderProps.onClick}
                style={{
                 backgroundColor: !featureToggleLoginFacebook() && '#1877f2',
                 color: 'white'
                }}>
                <FacebookIcon className={classes.socialIcon} />
                Entrar com Facebook
               </Button>
              )}
             />
            </Grid>
            <Grid item xs={12} md={6}>
             {/* <Button
                            disabled
                            fullWidth
                            onClick={handleSubmit}
                            size="large"
                            variant="contained">
                            <GoogleIcon className={classes.socialIcon} />
                            Entrar com Google
                          </Button> */}
            </Grid>
           </Grid>
           <Typography align="center" className={classes.sugestion} color="textSecondary" variant="body1">
            ou fazer login com seu endereço de email
           </Typography>
           <TextField
            className={classes.textField}
            onChange={handleChange}
            onBlur={handleBlur}
            value={email?.toLowerCase()}
            error={errors.email && touched.email}
            helperText={touched.email && errors.email && 'Digite um e-mail válido'}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
           />
           <TextField
            className={classes.textField}
            onChange={handleChange}
            onBlur={handleBlur}
            value={password}
            error={errors.password && touched.password}
            helperText={touched.password ? errors.password : ''}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
           />
           <Button
            className={classes.signInButton}
            color="primary"
            fullWidth
            size="large"
            onClick={handleSubmit}
            variant="outlined">
            Entrar
           </Button>
           <Grid container justify="flex-end">
            <Grid item>
             <Typography color="textSecondary" variant="body1">
              Esqueceu a senha?{' '}
              <Link
               onClick={() =>
                sendEvent(
                 'Login',
                 'Click - Esqueceu a senha',
                 'Clicou no botão Clique aqui do esqueceu minha senha para recuperar a senha'
                )
               }
               component={RouterLink}
               to="/recuperar-senha"
               variant="h6">
               Clique aqui
              </Link>
             </Typography>
            </Grid>
           </Grid>
           <Grid container justify="center" className={classes.signUpContainer}>
            <Button
             className={classes.signInButton}
             color="secondary"
             fullWidth
             size="large"
             onClick={() => handleSignUp()}
             variant="contained">
             Criar conta
            </Button>
            <Grid item>
             <Typography color="textSecondary" variant="body2">
              Cadastre-se totalmente de graça, sem precisar colocar nenhuma informação de pagamento.
             </Typography>
            </Grid>
           </Grid>
          </form>
         );
        }}
       </Formik>
      </div>
     </div>
    </Grid>
   </Grid>
  </div>
 );
};

SignIn.propTypes = {
 history: PropTypes.object
};

export default withRouter(SignIn);
