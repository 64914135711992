import React, { useState, useEffect, useCallback } from 'react';
import Lightbox from 'react-image-lightbox';
import { useSelector, useDispatch } from 'react-redux';

import { useMediaQuery, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles, useTheme } from '@material-ui/styles';
import colorAlertTest from '~/helpers/colorAlertTest';
import history from '~/services/history';
import * as AuthActions from '~/store/modules/auth/actions';
import * as GlobalActions from '~/store/modules/global/actions';
import * as HistoricoActions from '~/store/modules/historico/actions';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Sidebar, Topbar, Footer } from './components';

const useStyles = makeStyles(theme => ({
 root: {
  paddingTop: 56,
  height: '100%',
  [theme.breakpoints.up('sm')]: {
   paddingTop: 64
  }
 },
 shiftContent: {
  paddingLeft: 240
 },
 content: {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%'
 }
}));

const Main = props => {
 const { openFoto, historicoAtivo } = useSelector(state => state.historico);
 const { profile } = useSelector(state => state.user);
 const { notifications, lenNotifications } = useSelector(state => state.global);
 const dispatch = useDispatch();
 const { children } = props;

 const classes = useStyles();
 const theme = useTheme();
 const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
  defaultMatches: true
 });

 const [openSidebar, setOpenSidebar] = useState(false);

 useEffect(() => {
  function loadNotification() {
   dispatch(GlobalActions.getNotifications());
  }
  loadNotification();
 }, []);

 const handleSidebarOpen = () => {
  setOpenSidebar(true);
 };

 const handleSidebarClose = () => {
  setOpenSidebar(false);
 };

 const shouldOpenSidebar = isDesktop ? true : openSidebar;

 function handleOpenFoto(index) {
  dispatch(HistoricoActions.setOpenFotoRequest(historicoAtivo, index));
 }

 const handleFinishedHistoric = useCallback((customer_id, appointment_id) => {
  history.push(`/pacientes/detalhes/${customer_id}/prontuarios/${appointment_id}`);
  // dispatch(HistoricoActions.finishedHistoricRequest(historic_id))
 });

 const handleSubscriverNow = useCallback(() => {
  dispatch(AuthActions.signOut());
  dispatch(AuthActions.signInToOrderSuccess(profile));
  history.push('/planos');
 }, []);

 return (
  <>
   <div
    className={clsx({
     [classes.root]: true,
     [classes.shiftContent]: isDesktop
    })}>
    {openFoto.open ? (
     <Lightbox
      imageTitle={openFoto.title}
      imageCaption={openFoto.caption}
      mainSrc={historicoAtivo[openFoto.index].image}
      nextSrc={historicoAtivo[(openFoto.index + 1) % historicoAtivo.length].image}
      prevSrc={historicoAtivo[(openFoto.index + historicoAtivo.length - 1) % historicoAtivo.length].image}
      onCloseRequest={() => dispatch(HistoricoActions.closeFoto())}
      onMovePrevRequest={() => handleOpenFoto((openFoto.index + historicoAtivo.length - 1) % historicoAtivo.length)}
      onMoveNextRequest={() => handleOpenFoto((openFoto.index + 1) % historicoAtivo.length)}
     />
    ) : (
     <>
      {process.env.REACT_APP_SANDBOX == 'true' && (
       <Alert severity="error">VOCÊ ESTÁ UTILIZANDO UMA VERSÃO DE TESTE, NÃO UTILIZE DADOS REAIS</Alert>
      )}
      {profile?.trial && (
       <Alert
        severity={colorAlertTest(profile?.missing_days)}
        action={
         <Button color="inherit" size="small" onClick={handleSubscriverNow}>
          ASSINAR AGORA
         </Button>
        }>
        Você pode testar totalmente de graça por mais{' '}
        <span style={{ fontWeight: 'bold' }}>{profile?.missing_days} dias</span>
       </Alert>
      )}
      <Topbar
       onSidebarOpen={handleSidebarOpen}
       notifications={notifications}
       lenNotifications={lenNotifications}
       handleFinishedHistoric={handleFinishedHistoric}
      />
      <Sidebar onClose={handleSidebarClose} open={shouldOpenSidebar} variant={isDesktop ? 'persistent' : 'temporary'} />
     </>
    )}
    <main className={classes.content}>
     {children}
     <Footer />
    </main>
   </div>
  </>
 );
};

Main.propTypes = {
 children: PropTypes.node
};

export default Main;
