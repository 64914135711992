import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Grid } from '@material-ui/core';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import EventIcon from '@material-ui/icons/Event';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PeopleIcon from '@material-ui/icons/People';
import { makeStyles } from '@material-ui/styles';
import * as AgendaActions from '~/store/modules/agenda/actions';

import { Planos } from './components';

const useStyles = makeStyles(theme => ({
 root: {
  padding: theme.spacing(4),
  display: 'grid',
  justifyContent: 'center'
 }
}));

const Dashboard = () => {
 const dispatch = useDispatch();
 const classes = useStyles();
 const [filter, setFilter] = useState({ label: 'Últimos 7 dias', value: 7 });

 useEffect(() => {
  dispatch(AgendaActions.setChartRequest(filter));
 }, [filter, dispatch]);

 return (
  <div className={classes.root}>
   <Grid container spacing={4} style={{ maxWdth: 900 }}>
    <Grid item lg={6} md={6} xl={3} xs={12}>
     <Planos icon={<PeopleIcon />} title="Pacientes" />
    </Grid>
    <Grid item lg={6} md={6} xl={3} xs={12}>
     <Planos icon={<EventIcon />} title="Agenda" />
    </Grid>
    <Grid item lg={6} md={6} xl={3} xs={12}>
     <Planos icon={<MonetizationOnIcon />} title="Financeiro" />
    </Grid>
    <Grid item lg={6} md={6} xl={3} xs={12}>
     <Planos icon={<AssignmentTurnedInIcon />} title="Avaliacoes" />
    </Grid>
   </Grid>
  </div>
 );
};

export default Dashboard;
