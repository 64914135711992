import React from 'react';

import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
 thumb: {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
  '&:hover': {
   width: 120,
   height: 120,
   transition: '0.5s'
  },
  borderRadius: 5,
  '&:hover': {
   opacity: 0.5,
   transition: '0.5s'
  }
 },
 thumbInner: {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
 },
 img: {
  display: 'block',
  width: 'auto',
  height: '100%'
 },
 excludeThumb: {
  position: 'absolute',
  float: 'right'
 },
 downloadFile: {
  marginTop: -20,
  marginRight: -5,
  textAlign: 'right'
 }
}));

const ImageThumbnail = ({ index, images, file, handleOpenFoto }) => {
 const classes = useStyles();
 return (
  <Grid item direction="column" justifyContent="center">
   <Grid item>
    <div className={classes.thumb}>
     <div onClick={() => handleOpenFoto(images, index)} className={classes.thumbInner}>
      <img src={file?.image} className={classes.img} />
     </div>
    </div>
   </Grid>
   <Grid className={classes.downloadFile} item>
    <IconButton size="small" color="default">
     <a style={{ color: 'inherit' }} href={file?.image} target="_blank" rel="noreferrer">
      <CloudDownloadOutlined fontSize="small" />
     </a>
    </IconButton>
   </Grid>
  </Grid>
 );
};

export default ImageThumbnail;
