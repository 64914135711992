import React, { useEffect } from 'react';

export default function GoogleAdsense() {
 useEffect(() => {
  (window.adsbygoogle = window.adsbygoogle || []).push({});
 }, []);

 return (
  <ins
   className="adsbygoogle"
   // style={{ display: 'block' }}
   // data-ad-format="fluid"
   data-ad-client="ca-pub-9425439262993893"
   // data-ad-layout-key="-f7+4t+89-f6+3z"
   data-ad-slot="8163358686"
  />
 );
}
