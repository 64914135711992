import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { store } from '~/store';
import PropTypes from 'prop-types';

const RouteWithLayout = props => {
 const { layout: Layout, component: Component, isPrivate, ...rest } = props;

 const signed = store.getState().auth.signed;

 if (!signed && isPrivate) {
  return <Redirect to="/entrar" />;
 }

 if (signed && !isPrivate) {
  return <Redirect to="/agenda" />;
 }

 return (
  <Route
   {...rest}
   render={matchProps => (
    <Layout>
     <Component {...matchProps} />
    </Layout>
   )}
  />
 );
};

RouteWithLayout.propTypes = {
 component: PropTypes.any.isRequired,
 layout: PropTypes.any.isRequired,
 path: PropTypes.string
};

export default RouteWithLayout;
