import api from '~/services/api';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import createStore from './createStore';
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';
import persistReducers from './persistReducers';

const sagaMiddleware = createSagaMiddleware();

const middleWares = [sagaMiddleware];

const store = createStore(persistReducers(rootReducer), middleWares);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

api.registerInterceptWithStore(store);

export { store, persistor };
