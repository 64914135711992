import produce from 'immer';

const INITIAL_STATE = {
 notifications: [],
 lenNotifications: 0
};

export default function global(state = INITIAL_STATE, action) {
 return produce(state, draft => {
  switch (action.type) {
   case '@global/GET_NOTIFICATIONS_SUCCESS': {
    draft.notifications = action.payload.notifications;
    draft.lenNotifications = action.payload.len;
    break;
   }

   case '@historico/FINISHED_HISTORIC_SUCCESS': {
    let newListNotifications = draft.notifications.filter(
     notification => notification.historic_id !== action.payload.historic_id
    );
    draft.notifications = newListNotifications;
    draft.lenNotifications = newListNotifications.length;
    break;
   }

   default:
    return state;
  }
 });
}
