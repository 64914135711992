import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useParams } from 'react-router-dom';

import {
 Card,
 CardHeader,
 CardContent,
 CardActions,
 Divider,
 Grid,
 Button,
 Typography,
 CircularProgress,
 Collapse
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles, useTheme } from '@material-ui/styles';
import avatarprofile from '~/assets/avatar.png';
import { CustomerRating } from '~/components';
import HistoricoList from '~/components/HistoricoList';
import history from '~/services/history';
import * as AlertActions from '~/store/modules/alert/actions';
import * as HistoricoActions from '~/store/modules/historico/actions';
import * as LoadingActions from '~/store/modules/loading/actions';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { maskDate, maskCpf, convertToDate } from 'helpers';
import PropTypes from 'prop-types';
import api from 'services/api';

import AppointmentsTable from './components/Appointments/Appointments';
import { AvatarContainer, Avatar, AddAvatar } from './styles';

const useStyles = makeStyles(theme => ({
 root: {
  padding: theme.spacing(3)
 },
 content: {
  marginTop: theme.spacing(2)
 },
 buttonProgress: {
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12
 },
 input: {
  display: 'none'
 },
 avatar: {
  width: theme.spacing(20),
  height: theme.spacing(20)
 }
}));

const DetalhesCliente = props => {
 const dispatch = useDispatch();
 const match = useRouteMatch();
 const theme = useTheme();

 const { id_cliente } = useParams();
 const { loading } = useSelector(state => state.clientes);
 const { profile } = useSelector(state => state.user);
 const { className, ...rest } = props;

 const [clienteAtivo, setClienteAtivo] = useState({});
 // const [lastAppointment, setLastAppointment] = useState(null)
 const [appointments, setAppointments] = useState([]);
 const [birthday, setBirthday] = useState('');
 const [viewAppointment, setViewAppointment] = useState(null);
 const [appointmentToday, setAppointmentToday] = useState(null);
 const [appointmentDataToday, setAppointmentDataToday] = useState(null);

 const classes = useStyles();

 useEffect(() => {
  async function loadEffect() {
   dispatch(LoadingActions.setLoading());
   try {
    const dataCustomer = await api.get(`/customers/${id_cliente}`);
    setClienteAtivo(dataCustomer.data);
    let birthday = format(Date.parse(dataCustomer.data.birthday), "dd 'de' MMMM 'de' yyyy", {
     locale: pt
    });
    setBirthday(birthday);

    const dataAppointments = await api.get(`/customers/${id_cliente}/appointments`);
    setAppointments(dataAppointments.data.results);

    const today = convertToDate(new Date());
    const appointmentToday = dataAppointments.data.results.filter(a => convertToDate(new Date(a.datetime)) === today);
    if (appointmentToday.length > 0) {
     setAppointmentDataToday(convertToDate(new Date(appointmentToday[0].datetime)));
     setAppointmentToday(appointmentToday[0]);
    }

    dispatch(LoadingActions.closeLoading());
   } catch (error) {
    console.log(error);
    console.log('Não foi possível carregar os dados do cliente.');
    // setLastAppointment(null)
    setAppointments([]);
    dispatch(LoadingActions.closeLoading());
   }
   //   try {
   //     const dataLastAppointment = await api.get(`/customers/${id_cliente}/appointments/last`)
   //     setLastAppointment(dataLastAppointment.data)
   //   } catch (error) {
   //     console.log(error)
   //   }
  }
  loadEffect();
 }, [id_cliente]);

 function handleEdit() {
  history.push(`/pacientes/detalhes/${clienteAtivo.customer_id}/editar`);
 }

 function handleEditarAvaliacao(id_agendamento) {
  history.push(`${match.url}/prontuarios/${id_agendamento}`);
 }

 function handleAvaliacoes() {
  history.push(`${match.url}/prontuarios`);
 }

 const handleAvatar = useCallback(
  async avatar => {
   LoadingActions.setLoading();

   let newAvatar = new FormData();
   newAvatar.append('avatar', avatar[0]);

   await api
    .patch(`/customers/${clienteAtivo.customer_id}`, newAvatar)
    .then(res => {
     setClienteAtivo({ ...clienteAtivo, avatar: res.data.avatar });
     dispatch(AlertActions.success('Imagem alterada com sucesso!'));
     dispatch(LoadingActions.closeLoading());
    })
    .catch(err => {
     dispatch(LoadingActions.closeLoading());
    });
  },
  [clienteAtivo]
 );

 const handleOpenFoto = useCallback((listImages, index) => {
  dispatch(HistoricoActions.setOpenFotoRequest(listImages, index));
 }, []);

 const handleViewAppointment = useCallback(
  appointment => {
   if (appointment?.appointment_id === viewAppointment?.appointment_id) {
    setViewAppointment(null);
    return;
   }
   setViewAppointment(appointment);
   return;
  },
  [appointments, viewAppointment]
 );

 function handleEditarAvaliacao() {
  if (!appointmentToday) return;
  history.push(`${match.url}/prontuarios/${appointmentToday?.appointment_id}`);
 }

 const handleExamPage = useCallback(() => {
  history.push(`${match.url}/exames`);
 }, []);

 const handlePrescriptionPage = useCallback(() => {
  history.push(`${match.url}/receitas`);
 }, []);

 return (
  <>
   <div className={classes.root}>
    <div className={classes.content}>
     <Grid container spacing={2} direction="column">
      <Grid item>
       <Card {...rest}>
        <Grid container justify="space-between">
         <CardHeader
          title="Detalhes do paciente"
          // title={`Cliente - id: ${clienteAtivo.customer_id}`}
         />
         <CardActions>
          <Button onClick={handleEdit} color="primary" variant="outlined" disabled={loading}>
           Editar
           {loading && <CircularProgress size={24} className={classes.buttonProgress} color="primary" />}
          </Button>
         </CardActions>
        </Grid>
        <Divider />
        <CardContent>
         <Grid container spacing={3}>
          <Grid item>
           <AvatarContainer>
            {clienteAtivo?.avatar ? (
             <AddAvatar>
              <input
               accept="image/*"
               type="file"
               id="profile-image"
               className={classes.input}
               onChange={e => handleAvatar(e.target.files)}
              />
              <label htmlFor="profile-image">
               <Avatar src={clienteAtivo?.avatar} className={classes.avatar} />
              </label>
             </AddAvatar>
            ) : (
             <AddAvatar>
              <input
               accept="image/*"
               type="file"
               id="profile-image"
               className={classes.input}
               onChange={e => handleAvatar(e.target.files)}
              />
              <label htmlFor="profile-image">
               <Avatar src={avatarprofile} width={150} height={150} />
              </label>
             </AddAvatar>
            )}
           </AvatarContainer>
          </Grid>
          <Grid item md={6} xs={12}>
           {loading ? (
            <Typography gutterBottom variant="h4">
             <Skeleton animation="wave" variant="text" />
            </Typography>
           ) : (
            <>
             <Typography gutterBottom variant="h4">
              {clienteAtivo?.name}
             </Typography>
             <Typography gutterBottom variant="h4">
              {clienteAtivo?.age}
             </Typography>
             <Typography gutterBottom variant="h4">
              {clienteAtivo?.rating?.number >= 0 && (
               <CustomerRating size="large" rating={clienteAtivo?.rating?.number} label={clienteAtivo?.rating?.label} />
              )}
             </Typography>
            </>
           )}
          </Grid>
          <Grid item md={6} xs={12}>
           {loading ? (
            <Typography gutterBottom variant="h4">
             <Skeleton animation="wave" variant="text" />
            </Typography>
           ) : (
            <>
             <Typography variant="h6">Data de nascimento: </Typography>
             <Typography gutterBottom variant="h4">
              {clienteAtivo?.birthday && birthday}
             </Typography>
            </>
           )}
          </Grid>
          <Grid item md={6} xs={12}>
           {loading ? (
            <Typography gutterBottom variant="h4">
             <Skeleton animation="wave" variant="text" />
            </Typography>
           ) : (
            <>
             <Typography variant="h6">RG: </Typography>

             <Typography gutterBottom variant="h4">
              {clienteAtivo?.rg || 'Não cadastrado'}
             </Typography>
            </>
           )}
          </Grid>
          <Grid item md={6} xs={12}>
           {loading ? (
            <Typography gutterBottom variant="h4">
             <Skeleton animation="wave" variant="text" />
            </Typography>
           ) : (
            <>
             <Typography variant="h6">CPF: </Typography>

             <Typography gutterBottom variant="h4">
              {clienteAtivo?.cpf || 'Não cadastrado'}
             </Typography>
            </>
           )}
          </Grid>
          <Grid item md={6} xs={12}>
           {loading ? (
            <Typography gutterBottom variant="h4">
             <Skeleton animation="wave" variant="text" />
            </Typography>
           ) : (
            <>
             <Typography variant="h6">Endereço: </Typography>
             <Typography variant="h4">
              {clienteAtivo?.address
               ? `${clienteAtivo?.address}, ${clienteAtivo?.number_address}
                            - ${clienteAtivo?.neighborhood}`
               : 'Não cadastrado'}
             </Typography>
             <Typography variant="h5">
              {clienteAtivo?.address && clienteAtivo?.city && `${clienteAtivo?.city} - ${clienteAtivo?.postal_code}`}
             </Typography>
            </>
           )}
          </Grid>
          <Grid item md={6} xs={12}>
           {loading ? (
            <Typography gutterBottom variant="h4">
             <Skeleton animation="wave" variant="text" />
            </Typography>
           ) : (
            <>
             <Typography variant="h6">E-mail: </Typography>

             <Typography gutterBottom variant="h4">
              {clienteAtivo?.email || 'Não cadastrado'}
             </Typography>
            </>
           )}
          </Grid>
          <Grid item md={6} xs={12}>
           {loading ? (
            <Typography gutterBottom variant="h4">
             <Skeleton animation="wave" variant="text" />
            </Typography>
           ) : (
            <>
             <Typography variant="h6">Telefone: </Typography>

             <Typography gutterBottom variant="h4">
              {maskDate(clienteAtivo?.phone) || 'Não cadastrado'}
             </Typography>
            </>
           )}
          </Grid>
          <Grid item md={6} xs={12}>
           <Typography variant="subtitle2">Cadastrado em: </Typography>
           {loading ? (
            <Typography gutterBottom variant="h6">
             <Skeleton animation="wave" variant="text" />
            </Typography>
           ) : (
            <Typography gutterBottom variant="h6">
             {clienteAtivo?.created_at}
            </Typography>
           )}
          </Grid>
          <Grid item md={6} xs={12}></Grid>
         </Grid>
        </CardContent>
        <Divider />
        {clienteAtivo.legal_guardians && (
         <CardContent>
          {clienteAtivo?.legal_guardians?.map((guardian, index) => (
           <>
            <Grid key={index} container justify="space-between" alignItems="stretch">
             <Grid item xs={8}>
              <Typography color="textSecondary" variant="h6">
               {guardian?.kinship}
              </Typography>
             </Grid>
             <Grid item>
              <Typography color="textSecondary" variant="h6">
               CPF
              </Typography>
             </Grid>
             <Grid item>
              <Typography color="textSecondary" variant="h6">
               Telefone/Celular
              </Typography>
             </Grid>
             <Grid item xs={8}>
              <Typography color="textPrimary" variant="h5" gutterBottom>
               {guardian?.name}
              </Typography>
             </Grid>
             <Grid item>
              <Typography color="textPrimary" variant="h5" gutterBottom>
               {maskCpf(guardian?.cpf)}
              </Typography>
             </Grid>
             <Grid item>
              <Typography color="textPrimary" variant="h5" gutterBottom>
               {maskDate(guardian?.phone)}
              </Typography>
             </Grid>
            </Grid>
           </>
          ))}
         </CardContent>
        )}
        <Divider />
        {clienteAtivo.details && (
         <CardContent>
          <Grid container>
           <Grid item xs={12}>
            <Typography color="textSecondary" variant="h5" gutterBottom>
             Detalhes
            </Typography>
           </Grid>
           <Grid item xs={12}>
            <Typography color="textSecondary" variant="p" gutterBottom>
             {clienteAtivo?.details}
            </Typography>
           </Grid>
          </Grid>
         </CardContent>
        )}
       </Card>
      </Grid>
      {profile.is_professional && (
       <Grid item xs={12} container justify="space-between" alignItems="center">
        {/* <Grid item>
                    <Typography color='textSecondary' variant="h3">
                      Último prontuário
                    </Typography>
                  </Grid> */}
        <Grid item container xs={6}>
         {appointmentToday && (
          <Grid item>
           <Button onClick={handleEditarAvaliacao} variant="contained" color="primary">
            Iniciar Atendimento
           </Button>
          </Grid>
         )}
        </Grid>
        <Grid item container spacing={1} xs={6} justify="flex-end">
         <Grid item>
          <Button onClick={handlePrescriptionPage} variant="outlined" color="primary">
           Receitas
          </Button>
         </Grid>
         <Grid item>
          <Button onClick={handleExamPage} variant="outlined" color="primary">
           Exames
          </Button>
         </Grid>
         <Grid item>
          <Button onClick={handleAvaliacoes} variant="outlined" color="primary">
           Atendimentos
          </Button>
         </Grid>
        </Grid>
        <Grid></Grid>
       </Grid>
      )}
      <Grid item xs={12}>
       <AppointmentsTable
        appointments={appointments}
        handleViewAppointment={handleViewAppointment}
        user_id={profile.id_user}
       />
      </Grid>
      <Collapse in={Boolean(viewAppointment && viewAppointment.id_user === profile.id_user)} timeout={250}>
       <Grid item xs={12}>
        <HistoricoList historico={viewAppointment ? viewAppointment : {}} handleOpenFoto={handleOpenFoto} />
       </Grid>
      </Collapse>
     </Grid>
    </div>
   </div>
  </>
 );
};

DetalhesCliente.propTypes = {
 className: PropTypes.string
};

export default DetalhesCliente;
