import React, { forwardRef } from 'react';

import { Grid, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import logoByDoctor from '~/assets/logo-bydoctor-planos.png';
import { maskDate } from '~/helpers';

import { contentGenerator } from './contentGenerator';

const useStyles = makeStyles(theme => ({
 root: {
  padding: theme.spacing(3),
  height: '100vh'
 },
 content: {
  marginLeft: theme.spacing(10),
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  marginRight: theme.spacing(10)
 },
 pagination: {},
 title: {
  fontSize: 20,
  color: '#000'
 }
}));

export const ModelToPrint = forwardRef((props, ref) => {
 const classes = useStyles();
 const {
  companyLogo,
  professionalFirstName,
  professionalLastName,
  professionalDocument,
  professionalSpecialty,
  companyAddress,
  companyNumber,
  companyNeighborhood,
  companyCity,
  companyPhone,
  patientName,
  patientAge,
  content
 } = props;
 return (
  <Grid
   container
   className={classes.root}
   ref={ref}
   direction="column"
   justifyContentContent="space-between"
   alignItems="center"
   alignContent="center"
   spacing={4}>
   <Grid item container justifyContent="center" alignItems="center" alignContent="center">
    <Grid item xs={3}>
     <img src={companyLogo} width="200px" height="50px" />
    </Grid>
    <Grid item container direction="column" alignContent="center" alignItems="center" xs={9}>
     <Typography variant="h3" color="textPrimary">
      Dr(a). {professionalFirstName} {professionalLastName}
     </Typography>
     <Typography variant="h4" color="textPrimary">
      {professionalSpecialty}
     </Typography>
     <Typography variant="h5" color="textPrimary">
      CRM {professionalDocument}
     </Typography>
     {companyAddress && (
      <>
       <Typography variant="body2">
        {companyAddress}, {companyNumber} - {companyNeighborhood} - {companyCity}
       </Typography>
       <Typography variant="body2">Telefone: {maskDate(companyPhone)}</Typography>
      </>
     )}
    </Grid>
   </Grid>
   <Divider style={{ width: '100%' }} />
   <Grid item container direction="column" justifyContent="center" alignItems="center" xs>
    <Grid item container spacing={4} direction="column" justifyContent="center" alignItems="center" xs>
     <Grid item>
      <Typography variant="h1" color="textPrimary">
       RECEITUÁRIO MÉDICO
      </Typography>
     </Grid>
     <Grid container item direction="column" justifyContent="center" alignItems="center">
      <Typography variant="h4" color="textPrimary">
       {patientName}
      </Typography>
      <Typography variant="h5" color="textPrimary">
       {patientAge}
      </Typography>
     </Grid>
    </Grid>
    <br />
    <br />
    <br />
    <Grid item container justifyContent="flex-start" alignItems="flex-start" alignContent="flex-start" xs={8}>
     {content?.split('\n')?.map(paragraph => {
      return contentGenerator(paragraph);
     })}
    </Grid>
    <Grid item container direction="row" justifyContent="space-evenly" alignItems="center" alignContent="center" xs>
     <Typography color="textSecondary" variant="h4">
      ____/____/{new Date().getUTCFullYear()}
     </Typography>
     <Grid item container direction="column" alignItems="center" xs={6}>
      <Typography color="textSecondary">____________________________________________</Typography>
      <Typography color="textSecondary">ASSINATURA</Typography>
     </Grid>
    </Grid>
   </Grid>
   <Divider style={{ width: '100%' }} />
   <Grid item container direction="column" justifyContent="center" alignItems="center">
    <img src={logoByDoctor} width="180px" height="38px" alt="byDoctor - Software Medico" />
    <Typography variant="body2">www.bydoctor.com.br</Typography>
   </Grid>
  </Grid>
 );
});
