export default phone => {
 if (!phone) {
  return '';
 }

 phone = phone.replace(/[^\w\s]/gi, '');

 if (phone.length === 11) {
  phone = `(${phone.substring(0, 2)}) ${phone.substring(2, 7)}-${phone.substring(7, phone.length)}`;
 }

 if (phone.length === 12) {
  phone = `(${phone.substring(0, 3)}) ${phone.substring(3, 8)}-${phone.substring(8, phone.length)}`;
 }

 if (phone.length === 10) {
  phone = `(${phone.substring(0, 2)}) ${phone.substring(2, 6)}-${phone.substring(6, phone.length)}`;
 }

 if (phone.length === 9) {
  phone = `${phone.substring(0, 5)}-${phone.substring(5, phone.length)}`;
 }

 if (phone.length === 8) {
  phone = `${phone.substring(0, 4)}-${phone.substring(4, phone.length)}`;
 }

 return phone;
};
