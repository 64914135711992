import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import useStyles from './styles.js';

function BoxAvaliacao(props) {
 const { title, value, icon } = props;
 const classes = useStyles();
 return (
  <>
   <Card className={classes.root}>
    <CardContent>
     <Typography variant="h5" component="h2">
      {title}
     </Typography>

     <Typography style={{ whiteSpace: 'pre-line' }} variant="body2" component="p">
      {value ? value : '-'}
     </Typography>
    </CardContent>
    {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
   </Card>
  </>
 );
}

export default BoxAvaliacao;
