import { makeStyles } from '@material-ui/core/styles';
export default makeStyles(theme => ({
 root: {
  flexGrow: 1,
  height: '100vh',
  padding: theme.spacing(2)
 },
 paper: {
  padding: theme.spacing(2),
  textAlign: 'center'
 },
 title: {
  textAlign: 'center',
  fontWeight: 'bold',
  padding: theme.spacing(0)
 },
 via: {
  padding: theme.spacing(0),
  fontWeight: 'bold'
 },
 container: {
  border: '1px solid black',
  borderRadius: '4px',
  width: '100%',
  height: '100%'
 },
 info: {
  padding: theme.spacing(1),
  overflow: 'hidden'
 },
 dates: {
  fontWeight: 'bold'
 },
 rightColumn: {
  border: '1px solid #000',
  borderRadius: 5
 },
 signature: {
  padding: theme.spacing(2),
  textAlign: 'center'
 },
 signaturePharma: {
  textAlign: 'center'
 },
 conteinerHeight: {}
}));
