import api from '~/services/api';
import history from '~/services/history';
import * as AlertActions from '~/store/modules/alert/actions';
import * as LoadingActions from '~/store/modules/loading/actions';
import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import { setHistoricoAtivoSuccess, setLoading, setOpenFotoSuccess, finishedHistoricSuccess } from './actions';

function* setHistoricoAtivo({ payload }) {
 yield put(LoadingActions.setLoading());
 yield put(setLoading(true));
 const { id_agendamento } = payload;

 try {
  const res = yield call(api.get, `agendamentos/list/`, {
   params: {
    id_agendamento
   }
  });
  yield put(setHistoricoAtivoSuccess(res.data[0]));

  yield put(setLoading(false));
 } catch (error) {
  yield put(setLoading(false));

  console.log(`ERROR historicos: ${error}`);
 }
 yield put(LoadingActions.closeLoading());
}

function* addHistorico({ payload }) {
 yield put(setLoading(true));

 try {
  const { agenda } = yield select();

  let { data } = payload;

  data = {
   ...data,
   id_agendamento: agenda.idAgendamentoAtivo,
   read: false
  };

  const resAdd = yield call(api.post, `histories`, data);

  history.push('/agenda');

  if (data.images) {
   const { historic_id } = resAdd.data;
   const { images } = data;

   for (const image of images) {
    const newImage = new FormData();
    newImage.append('image', image);
    newImage.append('historic_id', historic_id);

    const resImage = yield call(api.post, `images/histories`, newImage);
   }
  }
 } catch (error) {
  yield put(AlertActions.error('Erro ao tentar salvar.'));
 }

 yield put(setLoading(false));
}

function* setOpenFoto({ payload }) {
 const { listImages, index } = payload;

 const openFoto = {
  open: true,
  index: index,
  caption: '',
  title: ''
 };

 yield put(setOpenFotoSuccess(openFoto, listImages));
}

function* editHistorico({ payload }) {
 yield put(LoadingActions.setLoading());
 try {
  let { historico } = payload;

  historico = {
   ...historico,
   read: true
  };

  const res = yield call(api.put, `histories/${historico.historic_id}`, historico);

  yield put(finishedHistoricSuccess(historico.historic_id));
  history.push('/pacientes');
  yield put(AlertActions.success('Prontuário salvo com sucesso!'));

  if (historico.images.length > 0) {
   const { images } = historico;

   for (const image of images) {
    if (!image.image_id) {
     const newImage = new FormData();
     newImage.append('image', image);
     newImage.append('historic_id', historico.historic_id);

     const resImage = yield call(api.post, `images/histories`, newImage);
    }
   }
  }
 } catch (error) {
  yield put(AlertActions.error('Erro ao tentar salvar.'));
 }

 yield put(LoadingActions.closeLoading());
}

function* finishedHistoric({ payload }) {
 const { historic_id } = payload;

 const data = {
  read: true
 };

 try {
  const res = yield call(api.patch, `/histories/${historic_id}`, data);

  yield put(finishedHistoricSuccess(historic_id));
 } catch (error) {
  console.log(`ERROR historicos: ${error}`);
 }
 yield put(LoadingActions.closeLoading());
}

function* addExam({ payload }) {
 yield put(LoadingActions.setLoading());

 try {
  let { exam } = payload;

  const resAdd = yield call(api.post, `exams`, exam);

  history.push(`/pacientes/detalhes/${exam.customer_id}`);

  if (exam.images) {
   const { exam_id } = resAdd.data;
   const { images } = exam;

   for (const image of images) {
    const newImage = new FormData();
    newImage.append('company_id', exam.company_id);
    newImage.append('image', image);
    newImage.append('exam_id', exam_id);

    const resImage = yield call(api.post, `images/exams`, newImage);
   }
  }
 } catch (error) {
  yield put(AlertActions.error('Erro ao tentar salvar.'));
  yield put(LoadingActions.closeLoading());
 }

 yield put(LoadingActions.closeLoading());
}

function* editExam({ payload }) {
 yield put(LoadingActions.setLoading());
 try {
  let { exam } = payload;

  const res = yield call(api.put, `exams/${exam?.exam_id}`, exam);

  history.push(`/pacientes/detalhes/${exam.customer_id}`);
  yield put(AlertActions.success('Exame salvo com sucesso!'));

  if (exam.images.length > 0) {
   const { images } = exam;

   for (const image of images) {
    if (!image.image_id) {
     const newImage = new FormData();
     newImage.append('image', image);
     newImage.append('company_id', exam.company_id);
     newImage.append('exam_id', exam.exam_id);

     const resImage = yield call(api.post, `images/exams`, newImage);
    }
   }
  }
 } catch (error) {
  yield put(AlertActions.error('Erro ao tentar salvar.'));
 }

 yield put(LoadingActions.closeLoading());
}

export default all([
 takeLatest('@historico/SET_HISTORICO_ATIVO_REQUEST', setHistoricoAtivo),
 takeLatest('@historico/ADD_HISTORICO_REQUEST', addHistorico),
 takeLatest('@historico/OPEN_FOTO_REQUEST', setOpenFoto),
 takeLatest('@historico/EDIT_HISTORICO_REQUEST', editHistorico),
 takeLatest('@historico/FINISHED_HISTORIC_REQUEST', finishedHistoric),
 takeLatest('@historico/ADD_EXAM_REQUEST', addExam),
 takeLatest('@historico/EDIT_EXAM_REQUEST', editExam)
]);
