import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';

import { Grid, Typography, Button, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/styles';
import history from '~/services/history';
import * as AlertActions from '~/store/modules/alert/actions';
import * as HistoricoActions from '~/store/modules/historico/actions';
import * as LoadingActions from '~/store/modules/loading/actions';
import Cliente from '~/views/Avaliacoes/Cliente';

import api from '../../services/api';
import AddExam from './Add';
import ListExams from './List';

const useStyles = makeStyles(theme => ({
 root: {
  padding: theme.spacing(3)
 },
 content: {
  marginTop: theme.spacing(2)
 },
 pagination: {}
}));

function Exams() {
 const { id_cliente, id_exam } = useParams();
 const match = useRouteMatch();
 const dispatch = useDispatch();
 const classes = useStyles();

 const [exams, setExams] = useState([]);
 const [pagination, setPagination] = useState(1);
 const [activePage, setActivePage] = useState(1);
 const [totalExams, setTotalExams] = useState(0);
 const [customer, setCustomer] = useState({});
 const [appointments, setAppointments] = useState([]);
 const [newExam, setNewExam] = useState(false);
 const [exam, setExam] = useState({});

 useEffect(() => {
  async function loadCustomerExams() {
   dispatch(LoadingActions.setLoading());
   const res = await api.get(`/customers/${id_cliente}/exams`, {
    params: {
     page: activePage
    }
   });
   let qtyPages = 1;
   if (res.data.count / 10 > 0) {
    qtyPages = res.data.count / 10 + 1;
   }
   setTotalExams(res.data.count);
   setPagination(~~qtyPages);
   setExams(res.data.results);
   dispatch(LoadingActions.closeLoading());
  }
  loadCustomerExams();
 }, [id_cliente, activePage]);

 useEffect(() => {
  async function loadCustomer() {
   const res = await api.get(`/customers/${id_cliente}`);
   setCustomer(res.data);
  }
  loadCustomer();
 }, [id_cliente]);

 useEffect(() => {
  async function loadAppointments() {
   const res = await api.get(`/customers/${id_cliente}/appointments`);
   setAppointments(res.data.results);
  }
  if (newExam) {
   loadAppointments();
  }
 }, [id_cliente, newExam]);

 const handleChangePage = (event, value) => {
  window.scrollTo(0, 0);
  setActivePage(value);
 };

 const handleOpenFoto = useCallback((listImages, file) => {
  dispatch(HistoricoActions.setOpenFotoRequest(listImages, file));
 }, []);

 const handleSubmitNewExam = useCallback(
  async exam => {
   exam = {
    ...exam,
    company_id: customer.company_id,
    customer_id: customer.customer_id,
    appointment_id: exam?.appointment_id ? exam?.appointment_id?.appointment_id : null
   };

   if (exam?.exam_id) {
    dispatch(HistoricoActions.editExamRequest(exam));
    return;
   }

   dispatch(HistoricoActions.addExamRequest(exam));
  },
  [customer]
 );

 const handleEditExam = useCallback(exam => {
  setNewExam(!newExam);
  setExam(exam);
 }, []);

 const handleComeBackExam = useCallback(() => {
  setNewExam(!newExam);
  setExam({});
 }, [newExam]);

 const handleDeleteExam = useCallback(async exam => {
  setExam({});
  setNewExam(!newExam);
  dispatch(LoadingActions.setLoading());

  await api
   .delete(`/exams/${exam.exam_id}`)
   .then(res => {
    history.push(`/pacientes/detalhes/${exam.customer_id}`);
    dispatch(AlertActions.success('Exame deletado com sucesso!'));
   })
   .catch(err => {
    dispatch(AlertActions.error('Erro ao deletar exame.'));
   });

  dispatch(LoadingActions.closeLoading());
 }, []);

 return (
  <Grid container spacing={2} className={classes.root}>
   <Grid item xs={12}>
    <Cliente totalExams={totalExams} customer={customer} />
   </Grid>
   {!newExam ? (
    <Grid container justify="flex-end" item xs={12}>
     <Button onClick={() => setNewExam(!newExam)} variant="contained" color="primary">
      Adicionar Exame
     </Button>
    </Grid>
   ) : (
    <Grid container justify="flex-start" item xs={12}>
     <IconButton onClick={handleComeBackExam} variant="contained" color="primary">
      <ArrowBackIcon />
     </IconButton>
    </Grid>
   )}
   {newExam && (
    <Grid item xs={12} md={12}>
     <AddExam
      exam={exam}
      handleSubmit={handleSubmitNewExam}
      appointments={appointments}
      handleDeleteExam={handleDeleteExam}
     />
    </Grid>
   )}
   <Grid item container spacing={2}>
    {!newExam &&
     exams.map(exam => (
      <Grid item xs={12}>
       <ListExams key={exam.exam_id} exam={exam} handleOpenFoto={handleOpenFoto} handleEditExam={handleEditExam} />
      </Grid>
     ))}
   </Grid>
   {!newExam && (
    <Grid item xs={12} container justify="center">
     {totalExams !== 0 ? (
      <Pagination onChange={handleChangePage} count={pagination} color="primary" />
     ) : (
      <Typography variant="h2" color="textPrimary">
       Não há exames para esse paciente.
      </Typography>
     )}
    </Grid>
   )}
  </Grid>
 );
}

export default Exams;
