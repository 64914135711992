import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Card, CardHeader, CardContent, CardActions, Divider, Grid, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as UserActions from '~/store/modules/user/actions';
import clsx from 'clsx';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

const useStyles = makeStyles(() => ({
 root: {}
}));

const schema = yup.object().shape({
 first_name: yup.string().required('Required field.'),
 last_name: yup.string().required('Required field.'),
 email: yup
  .string()
  .email()
  .required('Required field.')
});

const AccountDetails = props => {
 const dispatch = useDispatch();
 const { profile } = useSelector(state => state.user);
 const { className, ...rest } = props;

 const classes = useStyles();

 function handleSubmit(editedProfile) {
  dispatch(UserActions.saveProfile(editedProfile));
 }

 return (
  <Card {...rest} className={clsx(classes.root, className)}>
   <Formik initialValues={profile} onSubmit={handleSubmit} validationSchema={schema}>
    {props => {
     const { values, errors, touched, handleBlur, handleChange, handleSubmit } = props;
     return (
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
       <CardHeader subheader="Informações do seu perfil" title="Perfil" />
       <Divider />
       <CardContent>
        <Grid container spacing={3}>
         <Grid item md={6} xs={12}>
          <TextField
           fullWidth
           label="Primeiro nome"
           margin="dense"
           name="first_name"
           onChange={handleChange}
           required
           value={values.first_name}
           variant="outlined"
           error={Boolean(errors.first_name && touched.first_name)}
           onBlur={handleBlur}
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <TextField
           fullWidth
           label="Último nome"
           margin="dense"
           name="last_name"
           onChange={handleChange}
           required
           value={values.last_name}
           variant="outlined"
           error={Boolean(errors.last_name && touched.last_name)}
           onBlur={handleBlur}
          />
         </Grid>
         <Grid item md={12} xs={12}>
          <TextField
           label="Email"
           margin="dense"
           name="email"
           onChange={handleChange}
           required={false}
           disabled
           value={values.email}
           variant="outlined"
           error={Boolean(errors.email && touched.email)}
           onBlur={handleBlur}
           helperText={errors.email && 'Insira um e-mail válido'}
          />
         </Grid>
         <Grid item md={4} xs={12}>
          <TextField
           fullWidth
           label="Título"
           margin="dense"
           name="title"
           onChange={handleChange}
           required={false}
           value={values.title}
           variant="outlined"
           error={Boolean(errors.title && touched.title)}
           onBlur={handleBlur}
           helperText="Insira seu título acadêmico"
           placeholder="Ex: Dra."
          />
         </Grid>
         <Grid item md={4} xs={12}>
          <TextField
           fullWidth
           label="Especialidade"
           margin="dense"
           name="specialty"
           onChange={handleChange}
           required={false}
           value={values.specialty}
           variant="outlined"
           error={Boolean(errors.specialty && touched.specialty)}
           onBlur={handleBlur}
           helperText="Insira o nome da sua especialidade"
           placeholder="Ex: Pediatra"
          />
         </Grid>
         <Grid item md={4} xs={12}>
          <TextField
           fullWidth
           type="number"
           label="CRM"
           margin="dense"
           name="professional_document"
           onChange={handleChange}
           required={false}
           value={values.professional_document}
           variant="outlined"
           error={Boolean(errors.professional_document && touched.professional_document)}
           onBlur={handleBlur}
           helperText="Insira o número do seu registro no CRM"
           placeholder="123456"
          />
         </Grid>
        </Grid>
       </CardContent>
       <Divider />
       <CardActions>
        <Button color="primary" variant="contained" type="submit">
         Salvar
        </Button>
       </CardActions>
      </form>
     );
    }}
   </Formik>
  </Card>
 );
};

AccountDetails.propTypes = {
 className: PropTypes.string
};

export default AccountDetails;
