import React from 'react';

import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
 root: {
  height: '100%',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText
 },
 content: {
  alignItems: 'center',
  display: 'flex'
 },
 title: {
  fontWeight: 700
 },
 avatar: {
  backgroundColor: theme.palette.white,
  color: theme.palette.primary.main,
  height: 56,
  width: 56
 },
 icon: {
  height: 32,
  width: 32
 }
}));

const TotalProfit = props => {
 const { className, ...rest } = props;

 const classes = useStyles();

 return (
  <Card {...rest} className={clsx(classes.root, className)}>
   <CardContent>
    <Grid container justify="space-between">
     <Grid item>
      <Typography className={classes.title} color="inherit" gutterBottom variant="body2">
       Em breve
      </Typography>
      <Typography color="inherit" variant="h3">
       <Skeleton animation="wave" variant="text" />
      </Typography>
     </Grid>
     <Grid item>
      <Avatar className={classes.avatar}>
       <AttachMoneyIcon className={classes.icon} />
      </Avatar>
     </Grid>
    </Grid>
   </CardContent>
  </Card>
 );
};

TotalProfit.propTypes = {
 className: PropTypes.string
};

export default TotalProfit;
