import React from 'react';

import { Tooltip, Chip, Avatar } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getInitials } from '~/helpers';

export default function ProfessionalChip({ name, completeName, avatar, selected }) {
 return (
  <>
   <Tooltip title={completeName} arrow placement="top-start">
    <Chip
     size="medium"
     avatar={<CustomAvatar completeName={completeName} avatar={avatar} />}
     label={name}
     variant={selected ? 'default' : 'outlined'}
     deleteIcon={<CheckCircleIcon />}
    />
   </Tooltip>
  </>
 );
}

function CustomAvatar({ avatar, completeName }) {
 return avatar ? <Avatar size="small" alt={completeName} src={avatar} /> : <Avatar>{getInitials(completeName)}</Avatar>;
}
