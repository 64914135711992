import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export default reducers => {
 const persistedReducer = persistReducer(
  {
   key: 'bydoctor-pedroimpulcetto',
   storage,
   whitelist: ['user', 'auth', 'agenda', 'clientes', 'historico', 'order']
  },
  reducers
 );

 return persistedReducer;
};
