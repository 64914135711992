import React from 'react';
import { useSelector } from 'react-redux';

import { Card, Grid, Typography, CardHeader, Divider, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import Avaliacao from './Avaliacao';

const useStyles = makeStyles(theme => ({
 img: {
  borderRadius: 5,
  '&:hover': {
   opacity: 0.5,
   transition: '0.5s'
  }
 }
}));

const HistoricoList = props => {
 const { historico, handleOpenFoto, handleEditarAvaliacao } = props;
 const { profile } = useSelector(state => state.user);

 const classes = useStyles();

 const { className, ...rest } = props;

 return (
  <>
   <Card>
    <Grid container spacing={2}>
     <Grid item container xs={12} md={4} alignItems="stretch">
      <Grid item container spacing={6}>
       <Grid item xs={12} md={12}>
        {historico?.cancel ? (
         <CardHeader title={`Consulta cancelada dia ${historico?.canceled_at}`} />
        ) : (
         <CardHeader title={`Data da Consulta: ${historico?.date}`} subheader={`às ${historico?.time}`} />
        )}
        <Divider />
        <CardContent>
         <Grid xs={12} item container direction="row" spacing={2}>
          <Grid item xs={6}>
           <Typography>Tipo da consulta: </Typography>
           <Typography gutterBottom variant="h5">
            {historico?.type?.name}
           </Typography>
          </Grid>
          <Grid item xs={6}>
           <Typography>Plano de Saúde: </Typography>
           <Typography gutterBottom variant="h5">
            {historico?.healthplan_id?.name}
           </Typography>
          </Grid>
          <Grid item xs={6}>
           <Typography>Status da consulta: </Typography>
           <Typography style={{ color: `${historico?.status?.color}` }} gutterBottom variant="h5">
            {historico?.status?.name}
           </Typography>
          </Grid>
          <Grid item xs={12}>
           <Typography variant="body1">Observações: </Typography>
           <Typography gutterBottom variant="body2">
            {historico?.note}
           </Typography>
          </Grid>
          {historico?.has_exam && (
           <Grid item container justify="center" alignContent="flex-start" alignItems="center" xs={12}>
            <AttachFileIcon color="secondary" />
            <Typography variant="body1">Exame Anexado</Typography>
           </Grid>
          )}
         </Grid>
        </CardContent>
       </Grid>
      </Grid>
     </Grid>
     <Grid container item xs={12} md={8} lg={8}>
      <Avaliacao
       handleEditarAvaliacao={handleEditarAvaliacao}
       historico={historico?.historic}
       handleOpenFoto={handleOpenFoto}
       cancel={Boolean(historico?.cancel)}
      />
     </Grid>
    </Grid>
   </Card>
  </>
 );
};

export default HistoricoList;
