import React, { Component } from 'react';
import { Chart } from 'react-chartjs-2';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { PersistGate } from 'redux-persist/integration/react';
import validate from 'validate.js';

import { chartjs } from './helpers';
import { store, persistor } from './store';
import GlobalStyle from './styles/global';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-image-lightbox/style.css';
import validators from './common/validators';
import Routes from './Routes';

import history from '~/services/history';

import Loading from './components/Loading';
import Alert from './components/Alert';

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
 draw: chartjs.draw
});

validate.validators = {
 ...validate.validators,
 ...validators
};

export default class App extends Component {
 render() {
  return (
   <Provider store={store}>
    <PersistGate persistor={persistor}>
     <Router history={history}>
      <ThemeProvider theme={theme}>
       <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <GlobalStyle />
        <Alert />
        <Loading />
        <Routes />
       </MuiPickersUtilsProvider>
      </ThemeProvider>
     </Router>
    </PersistGate>
   </Provider>
  );
 }
}
