import api from '~/services/api';
import history from '~/services/history';
import * as AlertActions from '~/store/modules/alert/actions';
import * as LoadingActions from '~/store/modules/loading/actions';
import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import { saveProfileSuccess } from '../user/actions';
import { signInSuccess, signInToOrderSuccess } from './actions';

export function* signIn({ payload }) {
 yield put(LoadingActions.setLoading());

 const { email, password } = payload;
 const data = {
  email,
  password
 };

 try {
  const res = yield call(api.post, 'login', data);
  const token = res.data['token'];
  const refreshToken = res.data['refresh_token'];

  api.defaults.headers.Authorization = `Bearer ${token}`;

  const resUser = yield call(api.get, 'users/data');

  yield put(signInSuccess(token, refreshToken));
  yield put(saveProfileSuccess(resUser.data));
  yield put(LoadingActions.closeLoading());
  history.push('/agenda');
 } catch (error) {
  if (error?.response?.status === 401) {
   yield put(AlertActions.info(error?.response?.data?.message));
  } else {
   yield put(AlertActions.error('Erro ao tentar fazer login, verifique suas credenciais.'));
  }
  yield put(LoadingActions.closeLoading());
 }
 yield put(LoadingActions.closeLoading());
}

export function* signInSocial({ payload }) {
 const STATUS_CODE_WHEN_USAR_ALREADY_EXIST = 451;
 yield put(LoadingActions.setLoading());

 const data = { access_token: payload.accessToken };

 try {
  const res = yield call(api.post, 'login-facebook', data);
  const token = res.data['token'];
  const refreshToken = res.data['refresh_token'];

  api.defaults.headers.Authorization = `Bearer ${token}`;

  const resUser = yield call(api.get, 'users/data');

  yield put(signInSuccess(token, refreshToken));
  yield put(saveProfileSuccess(resUser.data));
  yield put(LoadingActions.closeLoading());
  history.push('/agenda');
 } catch (error) {
  if (error.response.status == STATUS_CODE_WHEN_USAR_ALREADY_EXIST) {
   yield put(AlertActions.info(error.response.data.message));
  } else {
   yield put(AlertActions.error('Erro ao tentar fazer login, verifique suas credenciais.'));
  }
  yield put(LoadingActions.closeLoading());
 }
}

export function setToken({ payload }) {
 if (!payload) return;

 const { token } = payload.auth;

 if (token) {
  api.defaults.headers.Authorization = `Bearer ${token}`;
 }
}

export function signOut() {
 history.push('/entrar');
}

export function* signToOrder({ payload }) {
 yield put(LoadingActions.setLoading());
 const { email } = payload;

 try {
  const res = yield call(api.get, 'login-to-order/user', {
   params: {
    email
   }
  });
  yield put(signInToOrderSuccess(res.data));
 } catch (error) {
  history.push('/registrar');
  yield put(LoadingActions.closeLoading());
 }
 yield put(LoadingActions.closeLoading());
}

export function* renewTokenWithRefreshToken() {
 const { auth } = yield select();

 const data = {
  refresh_token: auth?.refreshToken
 };

 try {
  const res = yield call(api.post, 'refresh-token', data);
  const token = res?.data?.token;
  const refreshToken = res?.data?.refresh_token;

  api.defaults.headers.Authorization = `Bearer ${token}`;
  yield put(signInSuccess(token, refreshToken));
  // yield put(setToken({ payload: { auth: { token } } }))
  // yield put(signInToOrderSuccess(res.data));
 } catch (error) {
  history.push('/entrar');
  // yield put(LoadingActions.closeLoading());
 }
 // yield put(LoadingActions.closeLoading());
}

export default all([
 // takeLatest('persist/REHYDRATE', setToken),
 // takeLatest('@auth/REFRESH_TOKEN', renewTokenWithRefreshToken),
 takeLatest('@auth/SIGN_IN_REQUEST', signIn),
 takeLatest('@auth/SIGN_OUT', signOut),
 takeLatest('@auth/SIGN_IN_TO_ORDER_REQUEST', signToOrder),
 takeLatest('@auth/SIGN_IN_SOCIAL_REQUEST', signInSocial)
]);
