export function registerRequest(new_register) {
 return {
  type: '@register/REGISTER_REQUEST',
  payload: {
   new_register
  }
 };
}

export function setLoading(status) {
 return {
  type: '@register/LOADING',
  payload: {
   status
  }
 };
}
