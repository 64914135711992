import React from 'react';

import { Box, Card, Grid, Typography, CardContent, CardActions, Button } from '@material-ui/core';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';

export default function PlanDetails({ plan, handleCancelPlan }) {
 return (
  <>
   <Box sx={{ minWidth: 275 }}>
    <Card variant="outlined">
     <CardContent>
      <Grid container spacing={2} justify="center" alignContent="center" alignItems="center">
       <Grid item md={3} xs={12}>
        <CardPlan title="Seu plano:" value={plan?.name} />
       </Grid>
       <Grid item md={3} xs={12}>
        <CardPlan title="Código da assinatura:" value={plan?.subscription_id} />
       </Grid>
       <Grid item md={3} xs={12}>
        <CardPlan
         title="Assinado em:"
         value={format(new Date(plan?.created_at), "dd'/'MM'/'y", {
          locale: pt
         })}
        />
       </Grid>
       <Grid item md={3} xs={12}>
        <CardPlan title="Assinatura de valor:" value={`R$${plan?.value}/mês`} />
       </Grid>
      </Grid>
     </CardContent>
     <CardActions>
      <Button size="small" onClick={handleCancelPlan}>
       Cancelar
      </Button>
     </CardActions>
    </Card>
   </Box>
  </>
 );
}

function CardPlan({ title, value }) {
 return (
  <Card>
   <CardContent>
    <Typography sx={{ fontSize: 14 }} color="text.secondary">
     {title}
    </Typography>
    <Typography variant="h5" component="div">
     {value}
    </Typography>
   </CardContent>
  </Card>
 );
}
