import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';

import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { makeStyles } from '@material-ui/styles';
import * as AlertActions from '~/store/modules/alert/actions';
import * as LoadingActions from '~/store/modules/loading/actions';
import api from 'services/api';

const useStyles = makeStyles(() => ({
 container: {
  border: '1px dashed gray'
 },

 thumbsContainer: {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
 },
 thumb: {
  display: 'inline-flex',
  borderRadius: 5,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',

  '&:hover': {
   boxShadow: '0 0 5px rgba(220,220,220,1)',
   WebkitBoxShadow: '0 0 5px rgba(220,220,220,1)',
   MoxBoxShadow: '0 0 5px rgba(220,220,220,1)',

   '& $cancelIcon': {
    visibility: 'visible',
    opacity: 1
   }
  }
 },
 thumbInner: {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
 },
 img: {
  display: 'block',
  width: 'auto',
  height: '100%'
 },
 cancelIcon: {
  visibility: 'hidden',
  position: 'absolute',
  marginLeft: -18,
  marginTop: -18,
  opacity: 0,
  WebkitTransition: 'opacity 200ms, visibility 200ms',
  transition: 'opacity 200ms, visibility 200ms'
 }
}));

function DropImage(props) {
 let { handleChange, images, urlAPI } = props;
 const dispatch = useDispatch();
 const [files, setFiles] = useState(images ? images : []);
 const classes = useStyles();
 const { getRootProps, getInputProps } = useDropzone({
  onDrop: acceptedFiles => {
   setFiles([
    ...files,
    ...acceptedFiles.map(file =>
     Object.assign(file, {
      preview: URL.createObjectURL(file),
      temporary_id: Math.random()
     })
    )
   ]);
  }
 });

 const thumbs = files.map((file, index) => (
  <div className={classes.thumb} key={index}>
   <CancelRoundedIcon onClick={() => handleDeleteImage(file)} color="error" className={classes.cancelIcon} />
   <div className={classes.thumbInner}>
    <img src={file.preview ? file.preview : file.image} className={classes.img} />
   </div>
  </div>
 ));

 useEffect(
  () => () => {
   // Make sure to revoke the data uris to avoid memory leaks
   files.forEach(file => URL.revokeObjectURL(file.preview));
  },
  [files]
 );

 const handleAddImage = useCallback(
  newFiles => {
   let newListFiles = [...files];
   Array.from(newFiles).map(newFile => {
    newListFiles = [...newListFiles, newFile];
   });
   handleChange('images', newListFiles);
  },
  [files]
 );

 const handleDeleteImage = useCallback(
  async image => {
   if (image.image_id) {
    await api
     .delete(`/images/${urlAPI}/${image.image_id}`)
     .then(res => {
      dispatch(LoadingActions.setLoading());

      setFiles(files.filter(file => image.image_id !== file.image_id));

      dispatch(AlertActions.success('Arquivo deletado com sucesso!'));
      dispatch(LoadingActions.closeLoading());
     })
     .catch(err => {
      dispatch(LoadingActions.closeLoading());
     });
   } else {
    setFiles(files.filter(file => image.temporary_id !== file.temporary_id));

    handleChange(
     'images',
     files.filter(file => image.temporary_id !== file.temporary_id)
    );

    dispatch(AlertActions.success('Arquivo deletado com sucesso!'));
   }
  },
  [files]
 );

 return (
  <section
   style={{
    border: '1px dashed gray',
    display: 'grid',
    justifyItems: 'center'
   }}>
   <div
    {...getRootProps({ className: 'dropzone' })}
    onChange={e => handleAddImage(e.target.files)}
    style={{
     display: 'grid',
     justifyContent: 'center',
     marginBottom: 10
    }}>
    <input {...getInputProps()} />
    <p style={{ fontSize: '12px', padding: 10 }}>
     Arraste e solte seu arquivo aqui
     <br />
     ou
    </p>

    <button type="button">clique para selecionar</button>
   </div>
   <aside className={classes.thumbsContainer}>{thumbs}</aside>
  </section>
 );
}

export default DropImage;
