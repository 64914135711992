import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ModalCancel from '~/components/ModalCancel';
import api from '~/services/api';
import history from '~/services/history';
import * as AlertActions from '~/store/modules/alert/actions';
import * as AuthActions from '~/store/modules/auth/actions';
import * as LoadingActions from '~/store/modules/loading/actions';
import * as OrderActions from '~/store/modules/order/actions';

import AppointmentTypes from './AppointmentTypes';
import CompanyDetails from './CompanyDetails';
import HealthPlan from './HealthPlan/index';
import ListUsers from './ListUsers';
import NewUser from './NewUser/index';
import PlanDetails from './PlanDetail';
import Plans from './Plans';

const useStyles = makeStyles(theme => ({
 root: {
  padding: theme.spacing(4)
 },
 paper: {
  padding: theme.spacing(2)
 }
}));

const schemaNewUser = {
 email: '',
 first_name: '',
 last_name: '',
 phone: '',
 password: '',
 confirm_password: '',
 is_adm: false,
 is_professional: false,
 is_patient: false,
 is_collaborator: false,
 active: true
};

const individualDescription = ['Somente 2 usuários', 'Agenda completa', '* Muito mais logo em breve!'];

const clinicaDescription = [
 'Usuários Ilimitados',
 'Agenda compartilhada',
 'Fácil controle de agenda pela secretária(o)',
 'Muitas outras facilidades'
];

const Company = () => {
 const { profile } = useSelector(state => state.user);
 const classes = useStyles();
 const dispatch = useDispatch();

 const [users, setUsers] = useState([]);
 const [company, setCompany] = useState(null);
 const [plans, setPlans] = useState([]);
 const [modalIsOpen, setModalIsOpen] = useState(false);

 useEffect(() => {
  async function loadProfessionals() {
   dispatch(LoadingActions.setLoading());
   const users = await api.get('/companies/accounts');
   setUsers(users.data);
   dispatch(LoadingActions.closeLoading());
  }
  loadProfessionals();
 }, []);

 useEffect(() => {
  async function loadCompany() {
   dispatch(LoadingActions.setLoading());
   const { data } = await api.get(`/companies/${profile.company_id}`);
   setCompany(data);
   dispatch(LoadingActions.closeLoading());
  }
  loadCompany();
 }, []);

 useEffect(() => {
  async function loadEffect() {
   const res = await api.get('/selects/plans');
   let plans = res.data.results.map(p => {
    p.description = p.name === 'Clínica' ? clinicaDescription : individualDescription;
    return p;
   });
   setPlans(plans.reverse());
  }
  if (profile?.trial) {
   loadEffect();
  }
 }, []);

 const handleInativeUser = useCallback(
  async user => {
   dispatch(LoadingActions.setLoading());
   await api
    .patch(`/users/${user.id_user}`, { active: !user.active })
    .then(res => {
     let newListUsers = users.map(u => {
      if (u.id_user === user.id_user) {
       u.active = !u.active;
      }
      return u;
     });
     setUsers(newListUsers);
     dispatch(LoadingActions.closeLoading());
    })
    .catch(err => {
     dispatch(LoadingActions.closeLoading());
    });
  },
  [users]
 );

 const handleSubmitOrder = useCallback(plan => {
  dispatch(AuthActions.signOut());
  dispatch(AuthActions.signInToOrderSuccess(profile));
  dispatch(OrderActions.setPlan(plan));
  history.push('/planos/finalizar');
 }, []);

 const handleSubmitNewUser = useCallback(
  async (values, { resetForm }) => {
   dispatch(LoadingActions.setLoading());

   values = {
    ...values,
    company_id: profile.company_id
   };

   await api
    .post('/register', values)
    .then(res => {
     resetForm(schemaNewUser);
     dispatch(AlertActions.success('Cadastro feito com sucesso!'));
     setUsers([...users, res.data]);
     dispatch(LoadingActions.closeLoading());
    })
    .catch(err => {
     if (err.response.status === 402) {
      dispatch(AlertActions.info('Para criar mais usuários é necessário fazer um upgrade para o plano Clínica.'));
      dispatch(LoadingActions.closeLoading());
      return;
     }
     dispatch(AlertActions.error('Erro ao tentar criar novo usuário.'));
     dispatch(LoadingActions.closeLoading());
    });
  },
  [users]
 );

 const handleCancelPlan = useCallback(async plan => {
  dispatch(LoadingActions.setLoading());
  await api
   .put(`/orders/${plan.order_id}`, {
    subscription_id: plan.subscription_id
   })
   .then(res => {
    dispatch(AlertActions.success('Plano cancelado com sucesso!'));
    dispatch(AuthActions.signOut());
    dispatch(LoadingActions.closeLoading());
   })
   .catch(err => {
    dispatch(AlertActions.error('Erro ao cancelar plano. Por favor, tente novamente mais tarde.'));
    dispatch(LoadingActions.closeLoading());
   });
 }, []);

 const handleOpenCloseModal = () => setModalIsOpen(!modalIsOpen);

 const handleSubmitCompanyDetails = useCallback(
  async company => {
   dispatch(LoadingActions.setLoading());
   delete company.company_logo;
   if (company) {
    await api
     .patch(`/companies/${company?.company_id}`, company)
     .then(res => {
      dispatch(AlertActions.success('Dados atualizados com sucesso!'));
      setCompany(res.data);
      dispatch(LoadingActions.closeLoading());
     })
     .catch(err => {
      dispatch(AlertActions.error('Erro ao atualizar os dados. Por favor, tente novamente mais tarde.'));
      dispatch(LoadingActions.closeLoading());
     });
   }
  },
  [profile?.company_id, company]
 );

 const handleSubmitCompanyLogo = useCallback(
  async image => {
   dispatch(LoadingActions.setLoading());

   let data = new FormData();
   data.append('company_logo', image[0]);

   if (image) {
    await api
     .patch(`/companies/${profile?.company_id}`, data)
     .then(res => {
      dispatch(AlertActions.success('Image atualizada com sucesso.'));
      setCompany(res.data);
      dispatch(LoadingActions.closeLoading());
     })
     .catch(err => {
      dispatch(AlertActions.error('Erro ao atualizar a imagem. Por favor, tente novamente mais tarde.'));
      dispatch(LoadingActions.closeLoading());
     });
   }
  },
  [profile?.company_id, company]
 );

 return (
  <div className={classes.root}>
   <Grid container spacing={4}>
    {profile?.is_adm && (
     <>
      <Grid item md={12} xs={12}>
       {profile?.trial && <Plans plans={plans} handleSubmitOrder={handleSubmitOrder} />}
      </Grid>
      <Grid item md={12} xs={12}>
       {company && (
        <CompanyDetails
         handleSubmit={handleSubmitCompanyDetails}
         handleSubmitLogo={handleSubmitCompanyLogo}
         company={company}
        />
       )}
      </Grid>
      <Grid item md={12} xs={12}>
       <ListUsers users={users} handleInativeUser={handleInativeUser} profile={profile} />
      </Grid>
      <Grid item md={4} xs={12}>
       <Paper className={classes.paper}>
        <NewUser handleSubmit={handleSubmitNewUser} newUser={schemaNewUser} />
       </Paper>
      </Grid>
     </>
    )}
    <Grid item md={4} xs={12}>
     <Paper className={classes.paper}>
      <HealthPlan />
     </Paper>
    </Grid>
    <Grid item md={4} xs={12}>
     <Paper className={classes.paper}>
      <AppointmentTypes />
     </Paper>
    </Grid>
    <Grid item xs={12}>
     {!profile?.trial && profile?.is_adm && (
      <PlanDetails handleCancelPlan={handleOpenCloseModal} plan={profile?.order} />
     )}
    </Grid>
   </Grid>
   <ModalCancel
    itemId={profile?.order}
    isOpen={modalIsOpen}
    handleOpenClose={handleOpenCloseModal}
    handleConfirm={handleCancelPlan}
    title="Confirmar Cancelamento de Plano"
    textContent="Você tem certeza que deseja cancelar seu plano atual?"
    textContentSecondary="Lembre-se que você perderá imediatamente o acesso do sistema e não poderá mais gerenciar sua agenda até assinar um novo plano."
   />
  </div>
 );
};

export default Company;
