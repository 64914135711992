var cartoes = {
 visa: /^4\d{12}(\d{3})?$/,
 mastercard: /^(5[1-5]\d{4}|677189)\d{10}$/,
 diners: /^3(0[0-5]|[68]\d)\d{11}$/,
 discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
 elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
 amex: /^3[47]\d{13}$/,
 jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
 aura: /^(5078\d{2})(\d{2})(\d{11})$/,
 hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
 maestro: /^(?:5[0678]\d\d|6304|6390|67\d\d)\d{8,15}$/
};

function cardValidator(card_number) {
 if (card_number.lenght !== 16) card_number = card_number.replaceAll(' ', '');
 for (var cartao in cartoes) if (card_number.match(cartoes[cartao])) return cartao;
 return false;
}

export default cardValidator;
