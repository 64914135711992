import produce from 'immer';

const INITIAL_STATE = {
 agenAtivo: null,
 dataAgendamento: null,
 agendamentos: [],
 ultimosAtendimentos: null,
 ultimosDias: null,
 loading: false,
 idAgendamentoAtivo: null,
 professionalFilter: null
};

export default function agenda(state = INITIAL_STATE, action) {
 return produce(state, draft => {
  switch (action.type) {
   case '@agenda/SET_AGENDAMENTO_ATIVO': {
    draft.agenAtivo = action.payload.data;
    draft.dataAgendamento = action.payload.data.data_agendamento;
    break;
   }

   case '@agenda/SET_AGENDAMENTO': {
    draft.agendamentos = action.payload.data;
    break;
   }

   case '@agenda/SET_DATA_AGENDAMENTO': {
    draft.dataAgendamento = action.payload.data;
    break;
   }

   case '@agenda/SET_CHART_SUCCESS': {
    draft.ultimosAtendimentos = action.payload.ultimosAtendimentos;
    draft.ultimosDias = action.payload.ultimosDias;
    break;
   }

   case '@agenda/SET_LOADING': {
    draft.loading = action.payload.status;
    break;
   }

   case '@agenda/SET_AGENDA_SUCCESS': {
    draft.agendamentos = action.payload.agendamentos;
    break;
   }

   case '@agenda/SET_ID_AGENDAMENTO_ATIVO': {
    draft.idAgendamentoAtivo = action.payload.id.id_agendamento;
    break;
   }

   case '@agenda/CANCEL_AGENDAMENTO_SUCCESS': {
    draft.agendamentos = draft.agendamentos.filter(agen => agen.appointment_id !== action.payload.id);
    break;
   }

   case '@agenda/SET_APPOINTMENT_STATUS_SUCCESS': {
    draft.agendamentos = draft.agendamentos.map(appointment =>
     appointment.appointment_id === action.payload.appointment.appointment_id
      ? { ...appointment, status: action.payload.appointment.status }
      : appointment
    );
    break;
   }

   case '@agenda/SET_PROFESSIONAL_FILTER': {
    draft.professionalFilter = action.payload.professional;
    break;
   }

   case '@auth/SIGN_OUT': {
    draft.professionalFilter = null;
    break;
   }

   default:
    return state;
  }
 });
}
