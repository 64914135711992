import produce from 'immer';

const INITIAL_STATE = {
 clientes: [],
 clienteAtivo: {},
 planos: {},
 loading: false
};

export default function clientes(state = INITIAL_STATE, action) {
 switch (action.type) {
  case '@clientes/SET_CLIENTE_SUCCESS':
   return produce(state, draft => {
    draft.clientes = action.payload.clientes;
   });

  case '@clientes/SET_CLIENTE_ATIVO_SUCCESS':
   return produce(state, draft => {
    draft.clienteAtivo = action.payload.cliente;
   });

  case '@clientes/SET_LOADING':
   return produce(state, draft => {
    draft.loading = action.payload.status;
   });

  default:
   return state;
 }
}
