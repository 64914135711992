export function signInRequest(email, password) {
 return {
  type: '@auth/SIGN_IN_REQUEST',
  payload: {
   email,
   password
  }
 };
}

export function signInWithSocialRequest(accessToken) {
 return {
  type: '@auth/SIGN_IN_SOCIAL_REQUEST',
  payload: {
   accessToken
  }
 };
}

export function signInSuccess(token, refreshToken) {
 return {
  type: '@auth/SIGN_IN_SUCCESS',
  payload: {
   token,
   refreshToken
  }
 };
}

export function signFailure() {
 return {
  type: '@auth/SIGN_FAILURE'
 };
}

export function signOut() {
 return {
  type: '@auth/SIGN_OUT'
 };
}

export function signInToOrderRequest(email) {
 return {
  type: '@auth/SIGN_IN_TO_ORDER_REQUEST',
  payload: {
   email
  }
 };
}

export function signInToOrderSuccess(user) {
 return {
  type: '@auth/SIGN_IN_TO_ORDER_SUCCESS',
  payload: {
   user
  }
 };
}

export function renewToken() {
 return {
  type: '@auth/REFRESH_TOKEN'
 };
}
