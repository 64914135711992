import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
 root: {
  '&:hover': {
   '& $icons': {
    visibility: 'visible',
    opacity: 1
   }
  }
 },
 icons: {
  opacity: 0,
  visibility: 'hidden',
  WebkitTransition: 'opacity 1500ms, visibility 1500ms',
  transition: 'opacity 1500ms, visibility 1500ms'
 },
 iconButton: {
  position: 'absolute'
 },
 imageContainer: {
  height: 64,
  width: 64,
  margin: '0 auto',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '5px',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
 },
 image: {
  width: '100%'
 },
 statsItem: {
  display: 'flex',
  alignItems: 'center'
 },
 statsIcon: {
  color: theme.palette.icon,
  marginRight: theme.spacing(1)
 },
 iconButtonDetails: {
  position: 'absolute',
  right: 10,
  top: 10
 },
 avatar: {
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  cursor: 'pointer'
 },
 horario: {
  fontSize: 22,
  fontWeight: 'bold'
 },
 container: {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
 },
 tipoConsulta: {
  marginTop: -5,
  fontSize: 14
  // fontWeight: 500
 },
 nomeClente: {
  fontSize: 18,
  marginLeft: 24
 },
 age: {
  marginTop: -5,
  fontSize: 12,
  marginLeft: 24
 },
 line: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%'
 },
 details: {
  display: 'flex',
  alignItems: 'center'
 },
 agendamento: {
  display: 'flex',
  alignItems: 'center'
 },
 accordionSummary: {
  flexDirection: 'row-reverse'
 }
}));

export default useStyles;
