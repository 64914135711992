import produce from 'immer';

const INITIAL_STATE = {
 title: 'Home'
};

export default function header(state = INITIAL_STATE, action) {
 return produce(state, draft => {
  switch (action.type) {
   case '@header/TITLE': {
    draft.title = action.payload.title;
    break;
   }

   default:
    return state;
  }
 });
}
