import * as AuthActions from '~/store/modules/auth/actions';
import * as LoadingActions from '~/store/modules/loading/actions';
import axios from 'axios';

const api = axios.create({ baseURL: `${process.env.REACT_APP_URL_API}/api` });

api.registerInterceptWithStore = store => {
 api.interceptors.request.use(request => {
  const state = store?.getState();
  const token = state?.auth?.token;
  const isLogged = state?.auth?.signed;

  if (isLogged && token) {
   request.headers.common.Authorization = `Bearer ${token}`;
  }
  return request;
 });

 api.interceptors.response.use(
  api.interceptors.response.use(
   response => response,
   async err => {
    const originalConfig = err.config;
    if (err.response) {
     if (
      (err.response.status === 403 || err.response.status === 401) &&
      !originalConfig._retry &&
      !originalConfig?.tokenAlreadyRefresh &&
      err.config.url !== 'login'
     ) {
      originalConfig._retry = true;
      originalConfig.tokenAlreadyRefresh = true;

      try {
       return api
        .post('refresh-token', {
         refresh_token: store.getState().auth.refreshToken
        })
        .then(res => {
         const { token, refresh_token } = res.data;
         store.dispatch(AuthActions.signInSuccess(token, refresh_token));
         err.config.headers.Authorization = `Bearer ${token}`;
         return api.request(originalConfig);
        });
      } catch (error) {
       store.dispatch(AuthActions.signOut());
       return Promise.reject(error);
      }
     }
     store.dispatch(LoadingActions.closeLoading());
     return Promise.reject(err);
    }
   }
  )
 );
};

export default api;
