import React from 'react';
import InputMask from 'react-input-mask';
import { useSelector, useDispatch } from 'react-redux';

import { Grid, Button, TextField, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { Formik } from 'formik';
import * as yup from 'yup';

import useStyles from './styles';

const schema = yup.object().shape({
 email: yup
  .string()
  .email()
  .required('Required field.'),
 first_name: yup
  .string()
  .min(2)
  .required('Required field.'),
 last_name: yup
  .string()
  .min(2)
  .required('Required field.'),
 phone: yup
  .string()
  .min(13)
  .required('Required field.'),
 password: yup
  .string()
  .min(6)
  .required('Required field.'),
 confirm_password: yup
  .string()
  .min(6)
  .required('Required field.')
});

export default function NewUser({ handleSubmit, newUser }) {
 const dispatch = useDispatch();
 const { profile } = useSelector(state => state.user);

 const classes = useStyles();

 return (
  <Grid item container direction="column" justify="center" alignItems="stretch">
   <Grid item>
    <Formik initialValues={newUser} onSubmit={handleSubmit} validationSchema={schema} enableReinitialize={true}>
     {props => {
      const { values, errors, touched, handleBlur, handleChange, handleSubmit, isValid } = props;
      return (
       <form onSubmit={handleSubmit}>
        <Typography className={classes.title} variant="h2">
         Novo usuário
        </Typography>
        <Typography color="textSecondary" gutterBottom>
         Crie um novo usuário para sua clínica
        </Typography>
        <TextField
         required
         className={classes.textField}
         fullWidth
         label="Primeiro Nome"
         name="first_name"
         onChange={handleChange}
         type="text"
         value={values.first_name}
         variant="outlined"
         error={errors.first_name && touched.first_name}
         onBlur={handleBlur}
        />
        <TextField
         required
         className={classes.textField}
         fullWidth
         label="Último Nome"
         name="last_name"
         onChange={handleChange}
         type="text"
         value={values.last_name}
         variant="outlined"
         error={errors.last_name && touched.last_name}
         onBlur={handleBlur}
        />
        <InputMask
         mask="(99)9-9999-9999"
         maskChar=" "
         onChange={handleChange}
         type="text"
         value={values.phone}
         onBlur={handleBlur}>
         {() => (
          <TextField
           required
           className={classes.textField}
           fullWidth
           label="Celular"
           name="phone"
           type="text"
           variant="outlined"
           error={errors.phone && touched.phone}
          />
         )}
        </InputMask>
        <TextField
         required
         className={classes.textField}
         fullWidth
         label="Email"
         name="email"
         onChange={handleChange}
         type="text"
         value={values.email}
         variant="outlined"
         error={errors.email && touched.email}
         onBlur={handleBlur}
        />
        <TextField
         required
         className={classes.textField}
         fullWidth
         label="Senha"
         name="password"
         onChange={handleChange}
         type="password"
         value={values.password}
         variant="outlined"
         error={errors.password && touched.password}
         helperText={errors.password && touched.password && 'A senha deve ter no mínimo 6 characteres.'}
         onBlur={handleBlur}
        />
        <TextField
         required
         className={classes.textField}
         fullWidth
         label="Confirmar Senha"
         name="confirm_password"
         onChange={handleChange}
         type="password"
         value={values.confirm_password}
         variant="outlined"
         error={errors.confirm_password && touched.confirm_password}
         helperText={
          values.confirm_password !== '' &&
          values.password !== '' &&
          values.password !== values.confirm_password &&
          'ATENÇÃO: Verifque a senha.'
         }
         onBlur={handleBlur}
        />
        <FormControlLabel
         control={
          <Checkbox
           name="is_professional"
           color="primary"
           onChange={handleChange}
           value={values.is_professional}
           defaultValue={false}
          />
         }
         label="Profissional"
        />
        <FormControlLabel
         control={
          <Checkbox
           name="is_collaborator"
           color="primary"
           onChange={handleChange}
           value={values.is_collaborator}
           defaultValue={false}
          />
         }
         label="Colaborador"
        />
        <Button
         className={classes.signUpButton}
         color="primary"
         fullWidth
         size="large"
         type="submit"
         variant="contained"
         disabled={!isValid || Object.keys(touched).length === 0 || values.password !== values.confirm_password}>
         Criar
         {/* {loading && (
                        <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                        color="secondary"
                        />
                    )} */}
        </Button>
       </form>
      );
     }}
    </Formik>
   </Grid>
  </Grid>
 );
}
