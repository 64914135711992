const sendEvent = (category, action, label) => {
 let dataLayer = window?.dataLayer || [];
 dataLayer.push({
  event: 'bydoctor',
  category,
  action,
  label
 });
};

export { sendEvent };
