import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
 Container,
 Typography,
 Grid,
 Box,
 CardContent,
 CardActions,
 CardHeader,
 Button,
 Card,
 Paper
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import FavoriteIcon from '@material-ui/icons/Favorite';
import logoByDoctor from '~/assets/logo-bydoctor-planos.png';
import { sendEvent } from '~/helpers';
import api from '~/services/api';
import history from '~/services/history';
import * as LoadingActions from '~/store/modules/loading/actions';
import * as OrderActions from '~/store/modules/order/actions';

const individualDescription = [
 '2 usuários',
 '1 perfil profissional e 1 perfil colaborador',
 'Agenda completa',
 'Histórico de pacientes',
 'Histórico de agendamentos',
 'Pronturário eletrônico',
 'Adição de imagens no pronturário',
 'Acesso simples pelo celular',
 'Anexo de exames com imagens',
 '* Muito mais logo em breve!'
];

const clinicaDescription = [
 'Usuários Ilimitados',
 'Agenda compartilhada',
 'Agenda completa',
 'Fácil controle de agenda pela secretária(o)',
 'Histórico de pacientes',
 'Histórico de agendamentos',
 'Pronturário eletrônico',
 'Adição de imagens no pronturário',
 'Acesso simples pelo celular',
 'Anexo de exames com imagens',
 '* Muito mais logo em breve!'
];

const tiers = [
 {
  id: 1,
  name: 'Individual',
  value: 47,
  from_value: 67,
  max_quantity_users: 2,
  description: [
   '2 usuários',
   '1 perfil profissional e 1 perfil colaborador',
   'Agenda completa',
   'Histórico de pacientes',
   'Histórico de agendamentos',
   'Pronturário eletrônico',
   'Adição de imagens no pronturário',
   'Acesso simples pelo celular'
  ],
  buttonText: 'ASSINAR AGORA',
  buttonVariant: 'outlined',
  trial_days: 15
 },
 {
  id: 2,
  name: 'Clínica',
  subtitle: 'Mais Querido dos Médicos',
  value: 77,
  from_value: 97,
  max_quantity_users: 0,
  description: [
   'Usuários Ilimitados',
   'Agenda compartilhada',
   'Agenda completa',
   'Fácil controle de agenda pela secretária(o)',
   'Histórico de pacientes',
   'Histórico de agendamentos',
   'Pronturário eletrônico',
   'Crie receitas, imprima e salve-as',
   'Adição de imagens no pronturário',
   'Acesso simples pelo celular'
  ],
  buttonText: 'COMEÇAR',
  buttonVariant: 'contained',
  trial_days: 30
 }
];

function Planos() {
 const dispatch = useDispatch();
 const [plans, setPlans] = useState([]);

 const handleSubmitOrder = useCallback(plan => {
  dispatch(OrderActions.setPlan(plan));
  sendEvent(
   'Pagamentos',
   `Click - Botão ${plan?.buttonText} - ${plan?.name}`,
   `Selecionou o plano: ${plan?.name} e foi para página de colocar os dados para pagamento`
  );
  history.push('/planos/finalizar');
 }, []);

 useEffect(() => {
  async function loadEffect() {
   dispatch(LoadingActions.setLoading());
   const res = await api.get('/selects/plans');
   let plans = res.data.results.map(p => {
    p.description = p.name === 'Clínica' ? clinicaDescription : individualDescription;
    p.buttonText = p.name === 'Clínica' ? 'ASSINAR AGORA' : 'ASSINAR';
    p.buttonVariant = p.name === 'Clínica' ? 'contained' : 'outlined';
    return p;
   });
   setPlans(plans);
   dispatch(LoadingActions.closeLoading());
  }
  loadEffect();
 }, []);

 return (
  <Grid
   container
   spacing={10}
   style={{
    marginTop: 20
   }}>
   {/* Hero unit */}
   <Grid item xs={12}>
    <Container maxWidth="md" component="main" sx={{ pt: 8, pb: 6 }}>
     <Grid item xs={12}>
      <Typography component="h1" variant="h1" align="center" color="text.primary" gutterBottom>
       <Typography align="center">
        <img alt="bydoctor" src={logoByDoctor} style={{ justifySelf: 'center' }} />
       </Typography>
       Planos
      </Typography>
     </Grid>
     <Grid item xs={12}>
      <Typography variant="h5" align="center" color="text.secondary" component="p">
       Estamos simplificando a vida dos nossos profissionais.
      </Typography>
      <Typography variant="h5" align="center" color="text.secondary" component="p">
       Um prontuário completo, detalhado e ao mesmo tempo simples e prático para que você não se preocupe com muitos
       detalhes e tenha mais foco em seu paciente.
      </Typography>
      <Typography variant="subtitle2" align="center" color="text.secondary" component="p">
       Gerando mais resultados para seus atendimentos ou para sua clínica por completo.
      </Typography>
     </Grid>
    </Container>
   </Grid>
   {/* End hero unit */}
   <Grid container item xs={12}>
    <Container maxWidth="md" component="main">
     <Grid container spacing={5} alignItems="flex-end">
      {plans.map(tier => (
       // Enterprise card is full width at sm breakpoint
       <Grid item key={tier.id} xs={12} md={6}>
        <Paper elevation={tier.name === 'Clínica' ? 4 : 1}>
         <CardHeader
          subheader={`Use por + ${tier.trial_days} dias antes de realmente pagar`}
          // subheader={tier.subheader}
          titleTypographyProps={{ align: 'center' }}
          subheaderTypographyProps={{
           align: 'center'
          }}
          sx={{
           backgroundColor: theme =>
            theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]
          }}
          style={{
           backgroundColor: tier.name === 'Clínica' && '#f8c7ff'
          }}
         />
         <Card>
          <CardHeader
           title={tier.name}
           subheader={tier.subtitle}
           titleTypographyProps={{ align: 'center' }}
           action={tier.name === 'Clínica' ? <FavoriteIcon color="secondary" /> : null}
           subheaderTypographyProps={{
            align: 'center'
           }}
           sx={{
            backgroundColor: theme =>
             theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[700]
           }}
          />
          <CardContent>
           {tier?.from_value !== 0 && (
            <s>
             <Box
              style={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'baseline'
              }}>
              <Typography component="h5" variant="h5" color="textSecondary">
               R${tier.from_value}
              </Typography>
              <Typography variant="caption" color="textSecondary">
               /mês
              </Typography>
             </Box>
            </s>
           )}
           <Box
            style={{
             display: 'flex',
             justifyContent: 'center',
             alignItems: 'baseline',
             mb: 2,
             marginBottom: 16
            }}>
            <Typography component="h1" variant="h1" color="text.primary">
             R${tier.value}
            </Typography>
            <Typography variant="h6" color="text.secondary">
             /mês
            </Typography>
           </Box>
           <ul>
            {tier.description.map(line => (
             <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={1}>
               <DoneIcon fontSize="small" style={{ color: '#0abf53' }} />
              </Grid>
              <Grid item xs={11}>
               <Typography component="li" variant="subtitle1" align="left" key={line}>
                {line}
               </Typography>
              </Grid>
             </Grid>
            ))}
           </ul>
          </CardContent>
          <CardActions>
           <Button fullWidth variant={tier.buttonVariant} color="primary" onClick={() => handleSubmitOrder(tier)}>
            {tier.buttonText}
           </Button>
          </CardActions>
         </Card>
        </Paper>
       </Grid>
      ))}
      <Grid item xs={12}>
       <Typography variant="subtitle1" align="center" component="p">
        cancele quando quiser sem preocupações
       </Typography>
      </Grid>
     </Grid>
    </Container>
   </Grid>
  </Grid>
 );
}

export default Planos;
