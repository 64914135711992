import React from 'react';

import { Grid, Typography, Box } from '@material-ui/core';

import useStyles from './styles';

import logoByDoctor from '~/assets/logo-bydoctor-planos.png';

export const Header = ({ logo }) => {
 const classes = useStyles();

 return (
  <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
   <Grid item xs={3}>
    <Box className={classes.paper}>
     <img src={logo} alt="logo" width={150} height={100} />
    </Box>
   </Grid>
   <Grid item xs={6}>
    <Typography className={classes.paper} variant="h2" component="h2">
     RECEITUÁRIO DE CONTROLE ESPECIAL
    </Typography>
   </Grid>
   <Grid item xs={3}>
    <Grid container alignContent="center" justifyContent="center">
     <Grid item xs={12}>
      <Box style={{ textAlign: 'center' }}>
       <img src={logoByDoctor} width="180px" height="38px" alt="byDoctor - Software Medico" />
      </Box>
     </Grid>
     <Grid item xs={12} style={{ textAlign: 'center' }}>
      <Typography variant="body2">www.bydoctor.com.br</Typography>
     </Grid>
    </Grid>
   </Grid>
  </Grid>
 );
};
