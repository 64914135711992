import React from 'react';

import { AppBar, Toolbar, Button, Box } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/styles';
// import logo from '~/assets/logo-transparent.png';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
 root: {
  boxShadow: 'none'
 }
}));

const TopbarMobile = props => {
 const { className, handleOpen, ...rest } = props;

 const classes = useStyles();

 return (
  <Box sx={{ flexGrow: 1 }}>
   <AppBar {...rest} className={clsx(classes.root, className)} color="primary" position="fixed">
    <Toolbar>
     <Button color="inherit" startIcon={<MenuIcon />} onClick={handleOpen}>
      Menu
     </Button>
    </Toolbar>
   </AppBar>
  </Box>
 );
};

TopbarMobile.propTypes = {
 className: PropTypes.string
};

export default TopbarMobile;
