export default cpf => {
 if (!cpf) {
  return '';
 }

 if (cpf.length === 11 && /^\+?(0|[1-9]\d*)$/.test(cpf)) {
  cpf = `${cpf.substring(0, 3)}.${cpf.substring(3, 6)}.${cpf.substring(6, 9)}-${cpf.substring(9, cpf.length)}`;
 }

 return cpf;
};
