import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardHeader, CardContent, Divider } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
 root: {
  height: '100%'
 },
 chartContainer: {
  position: 'relative',
  height: '300px'
 },
 stats: {
  marginTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center'
 },
 device: {
  textAlign: 'center',
  padding: theme.spacing(1)
 },
 deviceIcon: {
  color: theme.palette.icon
 }
}));

const Planos = props => {
 const dispatch = useDispatch();
 const { planos } = useSelector(state => state.clientes);
 const { className, title, icon, ...rest } = props;
 const [totalPlano, setTotalPlano] = useState(0);

 const classes = useStyles();
 const theme = useTheme();

 // useEffect(() => {
 //   function loadEffect() {
 //     dispatch(ClienteActions.setPlanoRequest());
 //   }

 //   const total = planos.unimed + planos.particular + planos.outros;
 //   setTotalPlano(total);

 //   loadEffect();
 // }, [dispatch, planos.unimed, planos.particular, planos.outros]);

 const data = {
  datasets: [
   {
    data: [planos.unimed, planos.particular, planos.outros],
    backgroundColor: ['#148D35', theme.palette.error.main, theme.palette.warning.main],
    borderWidth: 8,
    borderColor: theme.palette.white,
    hoverBorderColor: theme.palette.white
   }
  ],
  labels: ['Unimed', 'Particular', 'Outros']
 };

 const options = {
  legend: {
   display: false
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  cutoutPercentage: 80,
  layout: { padding: 0 },
  tooltips: {
   enabled: true,
   mode: 'index',
   intersect: false,
   borderWidth: 1,
   borderColor: theme.palette.divider,
   backgroundColor: theme.palette.white,
   titleFontColor: theme.palette.text.primary,
   bodyFontColor: theme.palette.text.secondary,
   footerFontColor: theme.palette.text.secondary
  }
 };

 const listPlanos = [
  {
   title: 'Unimed',
   value: planos.unimed,
   color: '#148D35'
  },
  {
   title: 'Particular',
   value: planos.particular,
   color: theme.palette.error.main
  },
  {
   title: 'Outros',
   value: planos.outros,
   color: theme.palette.warning.main
  }
 ];

 return (
  <Card {...rest} className={clsx(classes.root, className)}>
   <CardHeader action={icon} title={title} />
   <Divider />
   <CardContent>
    {/* <div className={classes.chartContainer}>
          <Doughnut data={data} options={options} />
        </div> */}
    {/* <div className={classes.stats}>
          {listPlanos.map(plano => (
            <div className={classes.device} key={plano.title}>
              <span className={classes.deviceIcon}>{plano.icon}</span>
              <Typography variant="body1">{plano.title}</Typography>
              <Typography style={{ color: plano.color }} variant="h2">
                {((100 * plano.value) / totalPlano).toFixed(2)}%
              </Typography>
            </div>
          ))}
        </div> */}
   </CardContent>
  </Card>
 );
};

Planos.propTypes = {
 className: PropTypes.string
};

export default Planos;
