import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, withRouter, useLocation } from 'react-router-dom';

import { Grid, Button, IconButton, TextField, Link, Typography, CircularProgress } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as OrderActions from '~/store/modules/order/actions';
import * as RegisterActions from '~/store/modules/register/actions';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { schema } from './schema';
import { useStyles } from './styles';

const SignUp = props => {
 const location = useLocation();
 const query = new URLSearchParams(location.search);
 const dispatch = useDispatch();
 const { loading } = useSelector(state => state.register);
 const { affiliate } = useSelector(state => state.order);
 const { history } = props;

 const classes = useStyles();

 const handleBack = () => {
  history.goBack();
 };

 function handleSubmit(values) {
  dispatch(RegisterActions.registerRequest(values));
 }

 useEffect(() => {
  function loadEffect() {
   if (!affiliate) {
    const ref = query?.get('ref');
    dispatch(OrderActions.setAffiliate(ref));
   }
  }
  loadEffect();
 }, [query]);

 return (
  <div className={classes.root}>
   <Grid className={classes.grid} container>
    <Grid className={classes.quoteContainer} item lg={5}>
     <div className={classes.quote}>
      <div className={classes.quoteInner}>
       {/* <Typography className={classes.quoteText} variant="h1">
                Hella narwhal Cosby sweater McSweeney's, salvia kitsch before
                they sold out High Life.
              </Typography> */}
       <div className={classes.person}>
        {/* <Typography className={classes.name} variant="body1">
                  Takamaru Ayako
                </Typography>
                <Typography className={classes.bio} variant="body2">
                  Manager at inVision
                </Typography> */}
       </div>
      </div>
     </div>
    </Grid>
    <Grid className={classes.content} item lg={7} xs={12}>
     <div className={classes.content}>
      <div className={classes.contentHeader}>
       <IconButton onClick={handleBack}>
        <ArrowBackIcon />
       </IconButton>
      </div>
      <div className={classes.contentBody}>
       <Formik
        initialValues={{
         email: null,
         first_name: null,
         last_name: null,
         phone: null,
         password: '',
         confirm_password: ''
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        {props => {
         const { values, errors, touched, handleBlur, handleChange, handleSubmit, isValid } = props;

         return (
          <form className={classes.form} onSubmit={handleSubmit}>
           <Typography className={classes.title} variant="h2">
            Criar uma conta
           </Typography>
           {/* <Typography color="textSecondary" gutterBottom>
                        Use um e-mail para criar uma conta
                      </Typography> */}
           <Typography color="textSecondary" gutterBottom>
            Utilize totalmente de graça por 30 dias sem precisar colocar nenhuma informação de pagamento.
           </Typography>
           <TextField
            required
            className={classes.textField}
            fullWidth
            label="Primeiro Nome"
            name="first_name"
            onChange={handleChange}
            type="text"
            value={values.first_name}
            variant="outlined"
            error={errors.last_name && touched.last_name}
            onBlur={handleBlur}
           />
           <TextField
            required
            className={classes.textField}
            fullWidth
            label="Último Nome"
            name="last_name"
            onChange={handleChange}
            type="text"
            value={values.last_name}
            variant="outlined"
            error={errors.last_name && touched.last_name}
            onBlur={handleBlur}
           />
           <InputMask
            mask="(99)9-9999-9999"
            maskChar=" "
            onChange={handleChange}
            type="text"
            value={values.phone}
            onBlur={handleBlur}>
            {() => (
             <TextField
              required
              className={classes.textField}
              fullWidth
              label="Celular"
              name="phone"
              type="text"
              variant="outlined"
              error={errors.phone && touched.phone}
             />
            )}
           </InputMask>
           <TextField
            required
            className={classes.textField}
            fullWidth
            label="Email"
            name="email"
            onChange={handleChange}
            type="text"
            value={values.email?.toLowerCase()}
            variant="outlined"
            error={errors.email && touched.email}
            onBlur={handleBlur}
           />
           <TextField
            required
            className={classes.textField}
            fullWidth
            label="Senha"
            name="password"
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            error={errors.password && touched.password}
            helperText={errors.password && touched.password && 'A senha deve ter no mínimo 6 characteres.'}
            onBlur={handleBlur}
           />
           <TextField
            required
            className={classes.textField}
            fullWidth
            label="Confirmar Senha"
            name="confirm_password"
            onChange={handleChange}
            type="password"
            value={values.confirm_password}
            variant="outlined"
            error={errors.confirm_password && touched.confirm_password}
            onBlur={handleBlur}
            helperText={
             values.confirm_password !== '' &&
             values.password !== '' &&
             values.password !== values.confirm_password &&
             'ATENÇÃO: Verifque a senha.'
            }
           />
           {/* <FormControl margin="normal" component="fieldset">
                        <FormLabel component="legend">Eu sou: </FormLabel>
                        <RadioGroup name="paciente" onChange={handleChange}>
                          <FormControlLabel
                            value="profissional"
                            control={<Radio />}
                            label="Profissional"
                            color="primary"
                          />
                          <FormControlLabel
                            value="paciente"
                            control={<Radio />}
                            label="Paciente"
                            color="primary"
                          />
                        </RadioGroup>
                      </FormControl> */}
           <div className={classes.policy}>
            {/* <Checkbox
                          className={classes.policyCheckbox}
                          color="primary"
                          name="policy"
                          onChange={handleChange}
                        /> */}
            {/* <Typography
                          className={classes.policyText}
                          color="textSecondary"
                          variant="body1">
                          I have read the{' '}
                          <Link
                            color="primary"
                            component={RouterLink}
                            to="#"
                            underline="always"
                            variant="h6">
                            Terms and Conditions
                          </Link>
                        </Typography> */}
           </div>
           <Button
            className={classes.signUpButton}
            color="primary"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={
             loading || !isValid || Object.keys(touched).length === 0 || values.password !== values.confirm_password
            }>
            Cadastrar
            {loading && <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />}
           </Button>
           <Typography color="textSecondary" variant="body1">
            Já tem uma conta?{' '}
            <Link component={RouterLink} to="/entrar" variant="h6">
             Entrar
            </Link>
           </Typography>
          </form>
         );
        }}
       </Formik>
      </div>
     </div>
    </Grid>
   </Grid>
  </div>
 );
};

SignUp.propTypes = {
 history: PropTypes.object
};

export default withRouter(SignUp);
