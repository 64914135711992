import React from 'react';

import { Container, NotificationList, Scroll, Notification } from './styles';

function Notificacoes({ visible, notifications, handleFinishedHistoric }) {
 const lido = false;
 return (
  <Container>
   <NotificationList visible={visible}>
    <Scroll>
     {notifications.map((notification, index) => (
      <Notification key={index} lido={lido}>
       <p>Prontuário pendente de finalização</p>
       <p>
        <strong>{notification.customer_name}</strong>
       </p>
       <time>{notification.datetime}</time>
       <button
        onClick={() => handleFinishedHistoric(notification.customer_id, notification.appointment_id)}
        type="button">
        Finalizar prontuário
       </button>
      </Notification>
     ))}
    </Scroll>
   </NotificationList>
  </Container>
 );
}

export default Notificacoes;
