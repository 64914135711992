import React from 'react';

import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
 root: {
  height: '100%'
 },
 content: {
  alignItems: 'center',
  display: 'flex'
 },
 title: {
  fontWeight: 700
 },
 avatar: {
  backgroundColor: theme.palette.error.main,
  height: 56,
  width: 56
 },
 icon: {
  height: 32,
  width: 32
 },
 difference: {
  marginTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'center'
 },
 differenceIcon: {
  color: theme.palette.error.dark
 },
 differenceValue: {
  color: theme.palette.error.dark,
  marginRight: theme.spacing(1)
 }
}));

const Budget = props => {
 const { className, ...rest } = props;

 const classes = useStyles();

 return (
  <Card {...rest} className={clsx(classes.root, className)}>
   <CardContent>
    <Grid container justify="space-between">
     <Grid item>
      <Typography className={classes.title} color="textSecondary" gutterBottom variant="body2">
       Em breve
      </Typography>
      <Typography variant="h3">
       <Skeleton animation="wave" variant="text" />
      </Typography>
     </Grid>
     <Grid item>
      <Avatar className={classes.avatar}>
       <MoneyIcon className={classes.icon} />
      </Avatar>
     </Grid>
    </Grid>
    <div className={classes.difference}>
     <ArrowDownwardIcon className={classes.differenceIcon} />
     <Typography className={classes.differenceValue} variant="body2">
      <Skeleton animation="wave" variant="text" width={20} />
     </Typography>
     <Typography className={classes.caption} variant="caption">
      <Skeleton animation="wave" variant="text" width={80} />
     </Typography>
    </div>
   </CardContent>
  </Card>
 );
};

Budget.propTypes = {
 className: PropTypes.string
};

export default Budget;
