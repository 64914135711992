import * as yup from 'yup';

export const schema = yup.object().shape({
 email: yup
  .string()
  .email()
  .required('Required field.'),
 first_name: yup
  .string()
  .min(2)
  .required('Required field.'),
 last_name: yup
  .string()
  .min(2)
  .required('Required field.'),
 phone: yup
  .string()
  .min(13)
  .required('Required field.'),
 password: yup
  .string()
  .min(6)
  .required('Required field.'),
 confirm_password: yup
  .string()
  .min(6)
  .required('Required field.')
});
