import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Grid, IconButton, Typography, Container } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import logoByDoctor from '~/assets/logo-bydoctor-planos.png';
import { sendEvent } from '~/helpers';
import * as AuthActions from '~/store/modules/auth/actions';
import * as OrderActions from '~/store/modules/order/actions';
import cep from 'cep-promise';
import PropTypes from 'prop-types';

import { LoginOrder } from './LoginOrder/loginOrder';
import ModalConfirmed from './ModalConfirmed';
import { OrderPlan } from './PlanOrder/planOrder';
import useStyles from './styles';

const Order = props => {
 const dispatch = useDispatch();
 const { plan, modal, newRegister } = useSelector(state => state.order);
 const { history } = props;

 const [activeOrder, setActiveOrder] = useState({
  email: newRegister?.email,
  first_name: newRegister?.first_name,
  last_name: newRegister?.last_name
 });
 const [postalCodeCEP, setPostalCodeCEP] = useState('');

 const classes = useStyles();

 const handleBack = () => {
  history.goBack();
 };

 function handleSubmit(values) {
  sendEvent(
   'Pagamentos',
   'Click - Assinar Agora',
   `Clicou no botão Assinar Agora para finalizar o pagamento com o e-mail: ${activeOrder?.email}`
  );
  dispatch(OrderActions.setOrder(values));
 }

 useEffect(() => {
  async function loadCEP() {
   try {
    const data = await cep(postalCodeCEP);
    setActiveOrder({
     ...activeOrder,
     address: data.street,
     city: data.city,
     neighborhood: data.neighborhood,
     postal_code: postalCodeCEP
    });
   } catch (error) {}
  }
  if (postalCodeCEP.length === 8) {
   loadCEP();
  } else {
   setActiveOrder({
    ...activeOrder,
    address: '',
    city: '',
    neighborhood: '',
    postal_code: postalCodeCEP
   });
  }
 }, [postalCodeCEP]);

 function handleSubmitLoginToOrder({ email }) {
  sendEvent(
   'Pagamentos',
   'Click - Validar e-mail',
   `Clicou no botão Validar com e-mail: ${email} para validar e-mail para ir para o pagamento.`
  );
  dispatch(AuthActions.signInToOrderRequest(email));
 }

 const handleModal = useCallback(() => {
  dispatch(OrderActions.setModal(!modal));
  history.push('/entrar');
 }, [modal]);

 return (
  <>
   <Grid container style={{ marginTop: 20 }}>
    <Container disableGutters maxWidth="md" component="main" sx={{ pt: 8, pb: 6 }}>
     <Grid item xs={12}>
      <Typography component="h1" variant="h1" align="center" color="text.primary" gutterBottom>
       <Typography align="center">
        <img alt="bydoctor" src={logoByDoctor} style={{ justifySelf: 'center' }} />
       </Typography>
       {plan?.name}
      </Typography>
     </Grid>
     <Grid item xs={12}>
      {plan?.max_quantity_users === 0 ? (
       <Typography variant="h5" align="center" color="text.secondary" component="p">
        Com esse plano você terá usuários <span style={{ fontWeight: 'bold' }}>ILITIMATOS</span> para sua clínica.
       </Typography>
      ) : (
       <Typography variant="h5" align="center" color="text.secondary" component="p">
        Com esse plano você terá <span style={{ fontWeight: 'bold' }}>{plan?.max_quantity_users} usuários</span>
       </Typography>
      )}
      <Typography variant="subtitle2" align="center" color="text.secondary" component="p">
       Um prontuário completo, detalhado e ao mesmo tempo simples e prático para que você não se preocupe com muitos
       detalhes e tenha mais foco em seu paciente.
      </Typography>
      {/* <Typography variant="subtitle2" align="center" color="text.secondary" component="p">
                            Você pode cancelar a <span style={{ fontWeight: 'bold' }}>qualquer</span> momento.
                        </Typography> */}
     </Grid>
    </Container>
    <Container maxWidth="md" component="main">
     <div className={classes.contentHeader}>
      <IconButton onClick={handleBack}>
       <ArrowBackIcon />
      </IconButton>
     </div>
     {newRegister?.id_user > 0 ? (
      <OrderPlan
       handleSubmit={handleSubmit}
       activeOrder={activeOrder}
       setActiveOrder={setActiveOrder}
       setPostalCodeCEP={setPostalCodeCEP}
      />
     ) : (
      <LoginOrder handleSubmit={handleSubmitLoginToOrder} />
     )}
    </Container>
   </Grid>
   <ModalConfirmed handleModal={handleModal} />
  </>
 );
};

Order.propTypes = {
 history: PropTypes.object
};

export default withRouter(Order);
