import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Card, CardActions, CardContent, Avatar, Typography, Divider, Button, LinearProgress } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';
import * as UserActions from '~/store/modules/user/actions';
import clsx from 'clsx';
import { format } from 'date-fns';
import { getInitials } from 'helpers';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
 root: {},
 details: {
  display: 'flex'
 },
 avatar: {
  marginLeft: 'auto',
  height: 110,
  width: 100,
  flexShrink: 0,
  flexGrow: 0
 },
 progress: {
  marginTop: theme.spacing(2)
 },
 uploadButton: {
  marginRight: theme.spacing(2)
 },
 input: {
  display: 'none'
 }
}));

const AccountProfile = props => {
 const dispatch = useDispatch();
 const { profile, loading } = useSelector(state => state.user);
 const { className, ...rest } = props;

 const classes = useStyles();

 return (
  <Card {...rest} className={clsx(classes.root, className)}>
   <CardContent>
    <div className={classes.details}>
     <div>
      <Typography gutterBottom variant="h2">
       {profile?.first_name} {profile?.last_name}
      </Typography>
      {/* <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body1">
              {user.city}, {user.country}
            </Typography> */}
      <Typography className={classes.dateText} color="textSecondary" variant="body1">
       {format(Date.parse(profile.date_joined), "HH':'mm")} ({format(Date.parse(profile.date_joined), "dd'/'MM'/'yyyy")}
       )
      </Typography>
     </div>

     {loading ? (
      <Skeleton className={classes.avatar} variant="circle" animation="wave" />
     ) : (
      <Avatar className={classes.avatar} src={profile.avatar}>
       {getInitials(profile?.first_name)}
      </Avatar>
     )}
    </div>
    <div className={classes.progress}>
     <Typography variant="body1">Perfil Comlpleto: 100%</Typography>
     <LinearProgress value={100} variant="determinate" />
    </div>
   </CardContent>
   <Divider />
   <CardActions>
    <input
     accept="image/*"
     type="file"
     id="profile-image"
     className={classes.input}
     onChange={e => dispatch(UserActions.imageRequest(e.target.files))}
    />
    <label htmlFor="profile-image">
     <Button className={classes.uploadButton} color="primary" variant="text" component="span">
      Adicionar Imagem
     </Button>
    </label>
    {/* <Button variant="text">Remove picture</Button> */}
   </CardActions>
  </Card>
 );
};

AccountProfile.propTypes = {
 className: PropTypes.string
};

export default AccountProfile;
