import styled from 'styled-components';

export const AvatarContainer = styled.div`
 height: 150;
 width: 150;
 border-radius: 50%;
 cursor: pointer;
`;

export const Avatar = styled.img`
 height: 150;
 width: 150;
 border-radius: 50%;
 cursor: pointer;

 &:hover {
  opacity: 0.5;
  transition: 0.5s;
 }
`;

export const AddAvatar = styled.div`
 cursor: pointer;
`;
