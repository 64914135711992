import React from 'react';

import { Avatar } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';

function createData(name, calories, fat, carbs, protein) {
 return { name, calories, fat, carbs, protein };
}

function descendingComparator(a, b, orderBy) {
 if (b[orderBy] < a[orderBy]) {
  return -1;
 }
 if (b[orderBy] > a[orderBy]) {
  return 1;
 }
 return 0;
}

function getComparator(order, orderBy) {
 return order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
 const stabilizedThis = array.map((el, index) => [el, index]);
 stabilizedThis.sort((a, b) => {
  const order = comparator(a[0], b[0]);
  if (order !== 0) return order;
  return a[1] - b[1];
 });
 return stabilizedThis.map(el => el[0]);
}

const headCells = [
 { id: 'active', numeric: false, disablePadding: false, label: 'Ativo' },
 { id: 'avatar', numeric: true, disablePadding: true, label: '' },
 { id: 'fisrt_name', numeric: false, disablePadding: false, label: 'Nome' },
 { id: 'email', numeric: false, disablePadding: false, label: 'E-mail' },
 { id: 'type', numeric: false, disablePadding: false, label: 'Tipo' }
];

function EnhancedTableHead(props) {
 const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
 const createSortHandler = property => event => {
  onRequestSort(event, property);
 };

 return (
  <TableHead>
   <TableRow>
    {headCells.map(headCell => (
     <TableCell
      key={headCell.id}
      align={headCell.numeric ? 'right' : 'left'}
      padding={headCell.disablePadding ? 'none' : 'normal'}
      sortDirection={orderBy === headCell.id ? order : false}>
      <TableSortLabel
       active={orderBy === headCell.id}
       direction={orderBy === headCell.id ? order : 'asc'}
       onClick={createSortHandler(headCell.id)}>
       {headCell.label}
       {orderBy === headCell.id ? (
        <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
       ) : null}
      </TableSortLabel>
     </TableCell>
    ))}
   </TableRow>
  </TableHead>
 );
}

EnhancedTableHead.propTypes = {
 classes: PropTypes.object.isRequired,
 numSelected: PropTypes.number.isRequired,
 onRequestSort: PropTypes.func.isRequired,
 onSelectAllClick: PropTypes.func.isRequired,
 order: PropTypes.oneOf(['asc', 'desc']).isRequired,
 orderBy: PropTypes.string.isRequired,
 rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
 root: {
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1)
 },
 highlight:
  theme.palette.type === 'light'
   ? {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85)
     }
   : {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.dark
     },
 title: {
  flex: '1 1 100%'
 }
}));

const EnhancedTableToolbar = props => {
 const classes = useToolbarStyles();
 const { numSelected, total } = props;

 return (
  <Toolbar
   className={clsx(classes.root, {
    [classes.highlight]: numSelected > 0
   })}>
   <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
    Usuários
   </Typography>
  </Toolbar>
 );
};

EnhancedTableToolbar.propTypes = {
 numSelected: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
 root: {
  width: '100%'
 },
 paper: {
  width: '100%',
  marginBottom: theme.spacing(2)
 },
 table: {
  minWidth: 750
 },
 visuallyHidden: {
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1
 }
}));

export default function({ users, handleInativeUser, profile }) {
 const classes = useStyles();
 const [order, setOrder] = React.useState('asc');
 const [orderBy, setOrderBy] = React.useState('calories');
 const [selected, setSelected] = React.useState([]);
 const [page, setPage] = React.useState(0);
 const [rowsPerPage, setRowsPerPage] = React.useState(5);

 const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
 };

 const handleSelectAllClick = event => {
  if (event.target.checked) {
   const newSelecteds = users.map(n => n.name);
   setSelected(newSelecteds);
   return;
  }
  setSelected([]);
 };

 const handleClick = (event, name) => {
  const selectedIndex = selected.indexOf(name);
  let newSelected = [];

  if (selectedIndex === -1) {
   newSelected = newSelected.concat(selected, name);
  } else if (selectedIndex === 0) {
   newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
   newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
   newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
  }

  setSelected(newSelected);
 };

 const handleChangePage = (event, newPage) => {
  setPage(newPage);
 };

 const handleChangeRowsPerPage = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
 };

 const isSelected = name => selected.indexOf(name) !== -1;

 const emptyRows = rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

 return (
  <div className={classes.root}>
   <Paper className={classes.paper}>
    <EnhancedTableToolbar total={users} numSelected={selected.length} />
    <TableContainer>
     <Table className={classes.table} aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table">
      <EnhancedTableHead
       classes={classes}
       numSelected={selected.length}
       order={order}
       orderBy={orderBy}
       onSelectAllClick={handleSelectAllClick}
       onRequestSort={handleRequestSort}
       rowCount={users.length}
      />
      <TableBody>
       {stableSort(users, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
         const isItemSelected = isSelected(row.appointment_id);
         const labelId = `enhanced-table-checkbox-${index}`;

         return (
          <TableRow hover tabIndex={-1} key={row.id_user}>
           <TableCell align="left">
            <Switch
             disabled={row.id_user === profile.id_user}
             checked={row.active}
             onChange={() => handleInativeUser(row)}
             inputProps={{ 'aria-label': 'controlled' }}
            />
           </TableCell>
           <TableCell align="right">
            <Avatar alt={row.first_name} src={row.avatar} />
           </TableCell>
           <TableCell align="left">{row.first_name}</TableCell>
           <TableCell align="left" style={{ color: `${row.status?.color}` }}>
            {row.email}
           </TableCell>
           <TableCell align="left" style={{ color: `${row.status?.color}` }}>
            {userType(row)}
           </TableCell>
          </TableRow>
         );
        })}
       {emptyRows > 0 && (
        <TableRow style={{ height: 33 * emptyRows }}>
         <TableCell colSpan={6} />
        </TableRow>
       )}
      </TableBody>
     </Table>
    </TableContainer>
    <TablePagination
     labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : to}`}
     labelRowsPerPage="Linhas por página"
     rowsPerPageOptions={[5, 10, 25]}
     component="div"
     count={users.length}
     rowsPerPage={rowsPerPage}
     page={page}
     onPageChange={handleChangePage}
     onRowsPerPageChange={handleChangeRowsPerPage}
    />
   </Paper>
  </div>
 );
}

function userType(user) {
 if (user?.is_professional) return 'Profissional';
 if (user?.is_collaborator) return 'Colaborador';
 return '';
}
