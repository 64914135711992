import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { AppBar, Toolbar, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// import logo from '~/assets/logo-transparent.png';
import { sendEvent } from '~/helpers';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
 root: {
  boxShadow: 'none'
 }
}));

const Topbar = props => {
 const { className, ...rest } = props;

 const classes = useStyles();

 return (
  <AppBar {...rest} className={clsx(classes.root, className)} color="primary" position="fixed">
   <Toolbar>
    <Grid container spacing={4} justify="flex-end" alignItems="center">
     <Grid item>
      <RouterLink to="/planos">
       <Button
        onClick={() =>
         sendEvent('Pagamentos', 'Click - Planos no header', 'Clicou no botão Planos no header quando deslogado')
        }
        variant="text">
        Planos
       </Button>
      </RouterLink>
     </Grid>
     <Grid item>
      <Button variant="text" disabled>
       Depoimentos
      </Button>
     </Grid>
     <Grid item>
      <Button href="https://blog.bydoctor.com.br/" variant="text">
       Blog
      </Button>
     </Grid>
     <Grid item>
      <RouterLink to="/">
       <Button
        onClick={() =>
         sendEvent('Login', 'Click - Entrar no header', 'Clicou no botão Entrar no header quando deslogado')
        }
        variant="text">
        Entrar
       </Button>
      </RouterLink>
     </Grid>
    </Grid>
    {/* <RouterLink to="/"> */}
    {/* <img alt="Logo" src={logo} width={100} /> */}
    {/* </RouterLink> */}
   </Toolbar>
  </AppBar>
 );
};

Topbar.propTypes = {
 className: PropTypes.string
};

export default Topbar;
