import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IconButton, Grid, Typography, TextField, Card, CardContent } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import { ProfessionalChip } from '~/components';
import GoogleAdsense from '~/components/GoogleAdsense';
import api from '~/services/api';
import history from '~/services/history';
import * as AgendActions from '~/store/modules/agenda/actions';
import { format, subDays, addDays } from 'date-fns';
import pt from 'date-fns/locale/pt';

import { AgendaToolbar, ListAgenda } from './components';

const useStyles = makeStyles(theme => ({
 root: {
  padding: theme.spacing(4)
  // display: 'grid',
  // justifyContent: 'center'
 },
 content: {
  marginTop: theme.spacing(2),
  maxWidth: '80%',
  justifySelf: 'center'
 },
 pagination: {
  marginTop: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  justifyItems: 'flex-end'
 },
 header: {
  display: 'grid',
  alignSelf: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 10
 },
 changeDate: {
  display: 'flex',
  alignSelf: 'center',
  alignItems: 'center',
  justifyContent: 'center'
 },
 date: {
  marginLeft: 10,
  marginRight: 10
 },
 datePicker: {
  width: '100%',
  marginRight: 10
 },
 selectProfessional: {
  marginLeft: 10,
  minWidth: 300
 },
 divFilterAgenda: {
  display: 'flex',
  justifyContent: 'between'
 }
}));

const Agenda = () => {
 const { profile } = useSelector(state => state.user);
 const { loading } = useSelector(state => state);
 const { agendamentos, professionalFilter } = useSelector(state => state.agenda);
 const [professionals, setProfessionals] = useState([]);
 const [appointmentStatus, setAppointmentStatus] = useState([]);
 const [appointmentTotal, setAppointmentTotal] = useState(undefined);
 // const [professionalFilter, setProfessionalFilter] = useState(null)
 const dispatch = useDispatch();
 const classes = useStyles();

 const [date, setDate] = useState(new Date());

 const dateFormatted = useMemo(() => {
  if (date instanceof Date && !isNaN(date)) {
   return format(date, "d 'de' MMMM", { locale: pt });
  }
  return '';
 }, [date]);

 const dayWeekFormatted = useMemo(() => {
  if (date instanceof Date && !isNaN(date)) {
   return format(date, 'eeee', { locale: pt });
  }
  return '';
 }, [date]);

 function handlePrevDay() {
  setDate(subDays(date, 1));
 }

 function handleNextDay() {
  setDate(addDays(date, 1));
 }

 useEffect(() => {
  async function loadEffect() {
   dispatch(AgendActions.setAgendaRequest(date, professionalFilter?.id_user));
  }

  if (date instanceof Date && !isNaN(date)) {
   loadEffect();
  }
 }, [professionalFilter, date, dispatch]);

 useEffect(() => {
  async function loadProfessionals() {
   const professionals = await api.get('/companies/professionals');
   setProfessionals(professionals.data);
  }
  loadProfessionals();
 }, []);

 useEffect(() => {
  async function loadStatus() {
   const { data } = await api.get('/selects/status');
   setAppointmentStatus(data.results);
  }
  loadStatus();
 }, []);

 function handleCriarAgendamento(agendamento) {
  history.push('/agenda/editar');
 }

 function handleFilter(date) {
  setDate(date);
 }

 const openInNewTab = url => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
 };

 function handleViewCliente(id_cliente) {
  // openInNewTab(`/pacientes/detalhes/${id_cliente}`);
  history.push(`/pacientes/detalhes/${id_cliente}`);
 }

 const hadleAppointmentStatus = useCallback((value, appointment_id) => {
  dispatch(AgendActions.setAppointmentStatusRequest(value, appointment_id));
 }, []);

 return (
  <Grid container spacing={2} className={classes.root}>
   <GoogleAdsense />
   <Grid item container justify="flex-end" xs={12}>
    <AgendaToolbar date={date} filter={handleFilter} />
   </Grid>
   <Grid item container spacing={1} xs={12}>
    <Grid item container justify="center" alignItems="center" alignContent="center" xs={12}>
     <Grid item container justify="center" alignItems="center" alignContent="center" xs={12}>
      <IconButton onClick={handlePrevDay}>
       <ArrowBackIosIcon size={42} />
      </IconButton>
      <Typography className={classes.date} variant="h2" color="textPrimary">
       {dateFormatted}
      </Typography>
      <IconButton onClick={handleNextDay}>
       <ArrowForwardIosIcon size={42} />
      </IconButton>
     </Grid>
     <Grid item container justify="center" alignItems="center" alignContent="center" xs={12} style={{ marginTop: -10 }}>
      <Typography className={classes.date} variant="h4" color="textSecondary">
       {dayWeekFormatted}
      </Typography>
     </Grid>
    </Grid>
    <Grid item container spacing={2} justify="center" xs={12}>
     <Grid item>
      <KeyboardDatePicker
       style={{ width: 300 }}
       margin="dense"
       inputVariant="outlined"
       label="Calendário"
       format="dd/MM/yyyy"
       value={date}
       onChange={date => handleFilter(date)}
       KeyboardButtonProps={{
        'aria-label': 'change date'
       }}
       clearable
      />
     </Grid>
     <Grid item>
      <Autocomplete
       style={{ width: 300 }}
       options={professionals}
       getOptionLabel={professional => `${professional.first_name}`}
       id="id_user"
       margin="dense"
       name="id_user"
       defaultValue={professionalFilter}
       onChange={(e, v) => {
        dispatch(AgendActions.setProfessionalFilter(v));
       }}
       renderInput={params => (
        <TextField
         autoComplete="on"
         {...params}
         label="Agenda de"
         margin="dense"
         variant="outlined"
         id="id_user"
         name="id_user"
        />
       )}
      />
     </Grid>
    </Grid>
    <Grid item container justify="center" alignItems="center" spacing={2} xs={12} md={12}>
     <Grid item>
      <Card style={{ width: 300 }}>
       <CardContent>
        <Grid container alignItems="center" alignContent="center" spacing={1}>
         <Grid item>
          <Typography variant="h5" color="textSecondary">
           Total de atendimentos no dia:{' '}
          </Typography>
         </Grid>
         <Grid item>
          <Typography variant="h5">{agendamentos ? agendamentos.length : ''}</Typography>
         </Grid>
        </Grid>
       </CardContent>
      </Card>
     </Grid>
     <Grid item>
      <Card style={{ width: 300 }}>
       <CardContent>
        <Grid container lignItems="center" alignContent="center" spacing={1}>
         <Grid item>
          <Typography variant="h5" color="textSecondary">
           Agenda de:{' '}
          </Typography>
         </Grid>
         <Grid item>
          <Typography variant="h5">{professionalFilter ? professionalFilter?.first_name : 'Todos'}</Typography>
         </Grid>
        </Grid>
       </CardContent>
      </Card>
     </Grid>
    </Grid>
   </Grid>
   {professionals.length > 1 && (
    <Grid item container justify="center" alignItems="center" alignContent="center" spacing={2} xs={12}>
     <Grid item container justify="center" alignItems="center" alignContent="center" xs={12}>
      <Typography className={classes.date} variant="h4" color="textPrimary">
       Profissionais
      </Typography>
     </Grid>
     {professionals?.map(prof => (
      <Grid item key={prof?.id_user}>
       <ProfessionalChip
        name={prof?.first_name}
        selected={professionalFilter?.id_user}
        completeName={`${prof?.first_name} ${prof?.last_name}`}
        avatar={prof?.avatar}
       />
      </Grid>
     ))}
    </Grid>
   )}
   <Grid container spacing={1} justify="center" alignItems="center" style={{ marginTop: 10 }}>
    {agendamentos.length === 0 && !loading ? (
     <h1>Não há atendimentos para essa data.</h1>
    ) : (
     agendamentos.map(agendamento => (
      <Grid item key={agendamento.appointment_id} lg={10} md={10} xs={10}>
       <ListAgenda
        agendamento={agendamento}
        onSubmit={handleCriarAgendamento}
        viewCliente={handleViewCliente}
        appointmentStatus={appointmentStatus}
        hadleAppointmentStatus={hadleAppointmentStatus}
       />
      </Grid>
     ))
    )}
   </Grid>
  </Grid>
 );
};

export default Agenda;
