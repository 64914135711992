import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
 Grid,
 Button,
 TextField,
 Typography,
 FormControl,
 InputLabel,
 OutlinedInput,
 InputAdornment,
 Divider
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import api from '~/services/api';
import * as AlertActions from '~/store/modules/alert/actions';
import * as LoadingActions from '~/store/modules/loading/actions';
import { Formik } from 'formik';
import * as yup from 'yup';

import useStyles from './styles';

const schema = yup.object().shape({
 name: yup.string().required('Required field.'),
 id_user: yup.string().required('Required field.')
});

const schemaHealthPlan = {
 name: '',
 id_user: '',
 value: ''
};

export default function HealthPlan() {
 const dispatch = useDispatch();
 const { profile } = useSelector(state => state.user);
 const [newHealthPlan, setNewHealthPlan] = useState(schemaHealthPlan);
 const [professionals, setProfessionals] = useState([]);
 const [healthPlans, setHealthPlans] = useState([]);

 const classes = useStyles();

 useEffect(() => {
  async function loadProfessionals() {
   const res = await api.get('/companies/professionals');
   setProfessionals(res.data);
  }
  loadProfessionals();
 }, []);

 useEffect(() => {
  async function loadHealthPlans() {
   const res = await api.get('/health-plans');
   setHealthPlans(res.data.results);
  }
  loadHealthPlans();
 }, []);

 const handleSubmit = useCallback(
  async (values, { resetForm }) => {
   dispatch(LoadingActions.setLoading());
   if (values?.healthplan_id) {
    await api
     .put(`/health-plans/${values?.healthplan_id}`, values)
     .then(res => {
      resetForm(newHealthPlan);
      dispatch(AlertActions.success('Cadastro atualizado com sucesso!'));
      let newListHealthPlans = healthPlans.map(hp => {
       if (hp.healthplan_id === values.healthplan_id) {
        hp = values;
       }
       return hp;
      });
      setHealthPlans(newListHealthPlans);
      setNewHealthPlan(schemaHealthPlan);
      dispatch(LoadingActions.closeLoading());
     })
     .catch(err => {
      dispatch(AlertActions.error('Erro ao tentar atualizar plano de saúde aceito.'));
      dispatch(LoadingActions.closeLoading());
     });
    return;
   }

   let editedValues = {
    ...values,
    id_user: values?.id_user?.id_user
   };

   await api
    .post('/health-plans', editedValues)
    .then(res => {
     resetForm(newHealthPlan);
     dispatch(AlertActions.success('Cadastro feito com sucesso!'));
     let newPlan = res.data;
     newPlan.id_user = {
      email: values.id_user.email,
      first_name: values.id_user.first_name,
      id_user: values.id_user.id_user
     };
     setHealthPlans([...healthPlans, newPlan]);
     dispatch(LoadingActions.closeLoading());
    })
    .catch(err => {
     dispatch(AlertActions.error('Erro ao tentar cadastrar novo plano de saúde aceito.'));
     dispatch(LoadingActions.closeLoading());
    });
  },
  [healthPlans]
 );

 const handleHealthPlan = useCallback(
  healthPlanSelected => {
   if (!healthPlanSelected) {
    setNewHealthPlan(schemaHealthPlan);
    return;
   }
   setNewHealthPlan(healthPlanSelected);
  },
  [healthPlans]
 );

 const handleDeleteHealthPlan = useCallback(async () => {
  dispatch(LoadingActions.setLoading());
  await api
   .delete(`/health-plans/${newHealthPlan?.healthplan_id}`)
   .then(res => {
    dispatch(AlertActions.success('Deleteado com sucesso!'));
    let newListHealthPlans = healthPlans.filter(hp => hp.healthplan_id !== newHealthPlan?.healthplan_id);
    setHealthPlans(newListHealthPlans);
    setNewHealthPlan(schemaHealthPlan);
    dispatch(LoadingActions.closeLoading());
   })
   .catch(err => {
    dispatch(AlertActions.error('Erro ao tentar deletar plano de saúde aceito.'));
    dispatch(LoadingActions.closeLoading());
   });
 }, [newHealthPlan, healthPlans]);

 return (
  <Grid item container direction="column" justify="center" alignItems="stretch">
   <Grid item>
    <Typography className={classes.title} variant="h2">
     Planos de Saúde
    </Typography>
    <Typography color="textSecondary">Crie ou edite</Typography>
    <Autocomplete
     options={healthPlans}
     getOptionLabel={healthPlan => healthPlan.name && `${healthPlan.name} - ${healthPlan?.id_user?.first_name}`}
     value={newHealthPlan}
     id="healtplan_id"
     name="healtplan_id"
     onChange={(e, v) => handleHealthPlan(v)}
     blurOnSelect={true}
     renderInput={params => (
      <TextField
       margin="dense"
       className={classes.textField}
       autoComplete="on"
       {...params}
       label="Planos"
       variant="outlined"
       id="healtplan_id"
       name="healtplan_id"
       // error={Boolean(errors.healtplan_id)}
       // onBlur={handleBlur}
      />
     )}
    />
    <Divider />
    <Typography color="textSecondary">Adicionar</Typography>
    <Formik initialValues={newHealthPlan} onSubmit={handleSubmit} validationSchema={schema} enableReinitialize={true}>
     {props => {
      const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = props;
      return (
       <form onSubmit={handleSubmit}>
        <Autocomplete
         options={professionals}
         getOptionLabel={professional => professional?.first_name}
         getOptionSelected={(option, value) => option?.id_user === value?.id_user}
         value={values?.id_user}
         id="id_user"
         name="id_user"
         onChange={(e, v) => setFieldValue('id_user', v)}
         blurOnSelect={true}
         renderInput={params => (
          <TextField
           required
           className={classes.textField}
           autoComplete="on"
           {...params}
           label="Profissional"
           variant="outlined"
           id="id_user"
           name="id_user"
           error={Boolean(errors?.id_user)}
           onBlur={handleBlur}
          />
         )}
        />
        <TextField
         required
         className={classes.textField}
         fullWidth
         label="Nome do plano"
         name="name"
         onChange={handleChange}
         type="text"
         value={values?.name}
         variant="outlined"
         error={errors?.name && touched?.name}
        />
        {/* <TextField
                    required
                    className={classes.textField}
                    fullWidth
                    label="Cor de identificação"
                    name="color"
                    onChange={handleChange}
                    type="text"
                    value={values.color}
                    variant="outlined"
                    error={errors.color && touched.color}
                    /> */}
        <FormControl fullWidth className={classes.textField} variant="outlined">
         <InputLabel htmlFor="value">Valor</InputLabel>
         <OutlinedInput
          type="number"
          id="value"
          value={values?.value}
          onChange={handleChange('value')}
          startAdornment={<InputAdornment position="start">R$</InputAdornment>}
          labelWidth={40}
         />
        </FormControl>
        {/* <TextField
                    required
                    className={classes.textField}
                    fullWidth
                    label="Prioridade na listagem"
                    name="priority"
                    onChange={handleChange}
                    type="text"
                    value={values.priority}
                    variant="outlined"
                    error={errors.priority && touched.priority}
                    /> */}
        {newHealthPlan.id_user === '' ? (
         <Button
          className={classes.signUpButton}
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained">
          Adicionar
         </Button>
        ) : (
         // <Grid container spacing={1}>
         //     <Grid item xs={4}>
         //         <Tooltip title="Apagar" arrow placement="top">
         //             <Button
         //                 className={classes.signUpButton}
         //                 variant="text"
         //                 onClick={handleDeleteHealthPlan}
         //             >
         //                 <DeleteForeverIcon color='error' />
         //             </Button>
         //         </Tooltip>
         //     </Grid>
         //         </Grid>
         <Grid item>
          <Button
           className={classes.signUpButton}
           fullWidth
           variant="contained"
           size="large"
           color="primary"
           type="submit">
           Editar
          </Button>
         </Grid>
        )}
       </form>
      );
     }}
    </Formik>
   </Grid>
  </Grid>
 );
}
