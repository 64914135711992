import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import App from './App';
import * as serviceWorker from './serviceWorker';

const tagManagerArgs = {
 gtmId: 'GTM-PPFFMMX'
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
