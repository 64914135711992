import React from 'react';

import { Grid, Paper, Typography, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import getInitials from '~/helpers/getInitials';

const useStyles = makeStyles(theme => ({
 root: {
  padding: theme.spacing(3),
  flexGrow: 1
 },
 content: {
  marginTop: theme.spacing(2)
 },
 large: {
  width: theme.spacing(7),
  height: theme.spacing(7)
 }
}));

function Cliente({ total, customer, labelText }) {
 const classes = useStyles();

 return (
  <Paper elevation={6} className={classes.root}>
   {customer && (
    <Grid container spacing={2} alignItems="center">
     <Grid item>
      <Avatar className={classes.large} src={customer.avatar}>
       {getInitials(customer.name)}
      </Avatar>
     </Grid>
     <Grid container item spacing={0} alignItems="center" direction="colunm" xs={12} sm>
      <Grid item xs={12} sm={12}>
       <Typography variant="h2" color="textSecondary">
        {customer.name}
       </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
       <Typography variant="h4" color="textSecondary">
        {customer.age}
       </Typography>
      </Grid>
     </Grid>
     <Grid item>
      {total !== 0 && (
       <Typography variant="h4" color="textSecondary">
        {total} {labelText}
       </Typography>
      )}
     </Grid>
    </Grid>
   )}
  </Paper>
 );
}

export default Cliente;
