import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import useStyles from './styles';

export const Dates = ({ today, thirtyDaysFromNow }) => {
 const classes = useStyles();
 return (
  <>
   <Grid item container spacing={1}>
    <Grid item xs={12}>
     <Typography className={classes.via} variant="body1" component="p">
      1ª VIA FARMÁCIA
     </Typography>
    </Grid>
    <Grid item xs={12}>
     <Typography className={classes.via} variant="body1" component="p">
      2ª VIA PACIENTE
     </Typography>
    </Grid>
    <Grid item container xs={12}>
     <Grid item>
      <Typography variant="body1" component="p">
       Data de Emissão:
      </Typography>
     </Grid>
     <Grid item xs>
      <Typography className={classes.dates} variant="body1" component="p">
       {today}
      </Typography>
     </Grid>
    </Grid>
   </Grid>
   <Grid item container xs={12}>
    <Grid item>
     <Typography variant="body1" component="p">
      Data de Validade:
     </Typography>
    </Grid>
    <Grid item xs>
     <Typography className={classes.dates} variant="body1" component="p">
      {thirtyDaysFromNow}
     </Typography>
    </Grid>
   </Grid>
  </>
 );
};
