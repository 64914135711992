import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Card, CardHeader, CardContent, CardActions, Divider, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as UserActions from '~/store/modules/user/actions';
import clsx from 'clsx';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

const useStyles = makeStyles(() => ({
 root: {}
}));

const schema = yup.object().shape({
 old_password: yup
  .string()
  .min(6)
  .required('Required field.'),
 new_password: yup
  .string()
  .min(6)
  .required('Required field.'),
 confirm_new_password: yup
  .string()
  .min(6)
  .required('Required field.')
});

const Password = props => {
 const dispatch = useDispatch();
 const { loading } = useSelector(state => state.register);
 const { className, ...rest } = props;

 const classes = useStyles();

 function handleSubmit(values, { resetForm }) {
  dispatch(UserActions.changePassword(values));
  resetForm({
   old_password: '',
   new_password: '',
   confirm_new_password: ''
  });
 }

 return (
  <Card {...rest} className={clsx(classes.root, className)}>
   <Formik
    initialValues={{
     old_password: '',
     new_password: '',
     confirm_new_password: ''
    }}
    validationSchema={schema}
    onSubmit={handleSubmit}>
    {props => {
     const { values, errors, touched, handleBlur, handleChange, handleSubmit, isValid } = props;
     return (
      <form className={classes.form} onSubmit={handleSubmit}>
       <CardHeader subheader="Atualizar senha" title="Senha" />
       <Divider />
       <CardContent>
        <TextField
         fullWidth
         label="Senha Atual"
         name="old_password"
         onChange={handleChange}
         type="password"
         value={values.old_password}
         variant="outlined"
        />
        <TextField
         fullWidth
         label="Nova Senha"
         name="new_password"
         onChange={handleChange}
         style={{ marginTop: '1rem' }}
         type="password"
         value={values.new_password}
         variant="outlined"
         helperText={errors.new_password && touched.new_password && 'A senha deve ter no mínimo 6 characteres.'}
         error={errors.new_password && touched.new_password}
         onBlur={handleBlur}
        />
        <TextField
         fullWidth
         label="Confirmar Nova Senha"
         name="confirm_new_password"
         onChange={handleChange}
         style={{ marginTop: '1rem' }}
         type="password"
         value={values.confirm_new_password}
         variant="outlined"
         helperText={
          values.confirm_new_password !== '' &&
          values.new_password !== '' &&
          values.new_password !== values.confirm_new_password &&
          'ATENÇÃO: Verifque a senha.'
         }
         error={errors.confirm_new_password && touched.confirm_new_password}
         onBlur={handleBlur}
        />
       </CardContent>
       <Divider />
       <CardActions>
        <Button
         type="submit"
         color="primary"
         variant="outlined"
         disabled={
          loading ||
          !isValid ||
          Object.keys(touched).length === 0 ||
          values.new_password !== values.confirm_new_password
         }>
         Atualizar
        </Button>
       </CardActions>
      </form>
     );
    }}
   </Formik>
  </Card>
 );
};

Password.propTypes = {
 className: PropTypes.string
};

export default Password;
