import React from 'react';

import { Card, CardContent, CardActions, Grid, Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import DropImage from '~/components/DropImage';
import { Formik } from 'formik';

export default function AddExam(props) {
 const { exam, handleSubmit, appointments, handleDeleteExam } = props;
 return (
  <Card>
   <Formik initialValues={exam} onSubmit={handleSubmit}>
    {props => {
     const { values, errors, handleBlur, handleSubmit, setFieldValue, handleChange } = props;

     return (
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
       <CardContent>
        <Grid container spacing={3}>
         <Grid item md={6} xs={12}>
          <TextField
           fullWidth
           multiline
           rows={12}
           value={values?.result}
           autoComplete="on"
           label="Resultado do Exame"
           margin="dense"
           onChange={handleChange}
           variant="outlined"
           id="result"
           name="result"
           error={Boolean(errors?.result)}
           onBlur={handleBlur}
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <TextField
           fullWidth
           multiline
           rows={12}
           value={values?.conduct}
           autoComplete="on"
           label="Conduta"
           margin="dense"
           onChange={handleChange}
           variant="outlined"
           id="conduct"
           name="conduct"
           error={Boolean(errors?.conduct)}
           onBlur={handleBlur}
          />
         </Grid>
         <Grid item md={12} xs={12}>
          <DropImage
           images={values?.images}
           handleChange={setFieldValue}
           handleDeleteExam={handleDeleteExam}
           urlAPI="exams"
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <Autocomplete
           options={appointments && appointments}
           getOptionLabel={appointment => `${appointment?.date} ${appointment?.time}`}
           getOptionSelected={(option, value) => option?.appointment_id === value?.appointment_id}
           value={values?.appointment_id}
           id="appointment_id"
           margin="dense"
           name="appointment_id"
           onChange={(e, v) => setFieldValue('appointment_id', v)}
           renderInput={params => (
            <TextField
             autoComplete="on"
             {...params}
             label="Vincular Atendimento"
             margin="dense"
             variant="outlined"
             id="appointment_id"
             name="appointment_id"
            />
           )}
          />
         </Grid>
         {/* <Grid item md={6} xs={12}>
                                        {exam?.exam_id && (
                                            <Button
                                                style={{ backgroundColor: '#fc636b' }}
                                                startIcon={<DeleteIcon />}
                                                color="secondary"
                                                variant="contained"
                                                fullWidth
                                                onClick={() => handleDeleteExam(exam)}
                                            >
                                                Deletar Exame
                                            </Button>
                                        )}
                                    </Grid> */}
        </Grid>
       </CardContent>
       <CardActions>
        <Button type="submit" color="primary" variant="contained">
         Salvar
        </Button>
       </CardActions>
      </form>
     );
    }}
   </Formik>
  </Card>
 );
}
