import React from 'react';

import Paper from '@material-ui/core/Paper';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import RemoveIcon from '@material-ui/icons/Remove';
import PropTypes from 'prop-types';

function descendingComparator(a, b, orderBy) {
 if (b[orderBy] < a[orderBy]) {
  return -1;
 }
 if (b[orderBy] > a[orderBy]) {
  return 1;
 }
 return 0;
}

function getComparator(order, orderBy) {
 return order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
 const stabilizedThis = array.map((el, index) => [el, index]);
 stabilizedThis.sort((a, b) => {
  const order = comparator(a[0], b[0]);
  if (order !== 0) return order;
  return a[1] - b[1];
 });
 return stabilizedThis.map(el => el[0]);
}

const headCells = [
 { id: 'date', numeric: false, disablePadding: false, label: 'Data' },
 { id: 'time', numeric: true, disablePadding: false, label: 'Hora' },
 { id: 'type', numeric: true, disablePadding: false, label: 'Tipo' },
 { id: 'healthplan', numeric: true, disablePadding: false, label: 'Plano de Saúde' },
 { id: 'status', numeric: true, disablePadding: false, label: 'Status' },
 { id: 'professional', numeric: true, disablePadding: false, label: 'Profissional' },
 { id: 'read', numeric: true, disablePadding: false, label: 'Encerrado' }
];

function EnhancedTableHead(props) {
 const { classes, onSelectAllClick, order, orderBy, rowCount, onRequestSort } = props;
 const createSortHandler = property => event => {
  onRequestSort(event, property);
 };

 return (
  <TableHead>
   <TableRow>
    {headCells.map(headCell => (
     <TableCell
      key={headCell.id}
      align={headCell.numeric ? 'right' : 'left'}
      padding={headCell.disablePadding ? 'none' : 'normal'}
      sortDirection={orderBy === headCell.id ? order : false}>
      <TableSortLabel
       active={orderBy === headCell.id}
       direction={orderBy === headCell.id ? order : 'asc'}
       onClick={createSortHandler(headCell.id)}>
       {headCell.label}
       {orderBy === headCell.id ? (
        <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
       ) : null}
      </TableSortLabel>
     </TableCell>
    ))}
   </TableRow>
  </TableHead>
 );
}

EnhancedTableHead.propTypes = {
 classes: PropTypes.object.isRequired,
 numSelected: PropTypes.number.isRequired,
 onRequestSort: PropTypes.func.isRequired,
 onSelectAllClick: PropTypes.func.isRequired,
 order: PropTypes.oneOf(['asc', 'desc']).isRequired,
 orderBy: PropTypes.string.isRequired,
 rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
 root: {
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1)
 },
 highlight:
  theme.palette.type === 'light'
   ? {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85)
     }
   : {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.dark
     },
 title: {
  flex: '1 1 100%'
 }
}));

const EnhancedTableToolbar = props => {
 const classes = useToolbarStyles();
 const { numSelected, total } = props;

 return (
  <Toolbar>
   <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
    Agendamentos
   </Typography>

   {/* <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip> */}
  </Toolbar>
 );
};

EnhancedTableToolbar.propTypes = {
 numSelected: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
 root: {
  width: '100%'
 },
 paper: {
  width: '100%',
  marginBottom: theme.spacing(2)
 },
 table: {
  minWidth: 750
 },
 visuallyHidden: {
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1
 }
}));

export default function AppointmentsTable({ appointments, handleViewAppointment, user_id }) {
 const classes = useStyles();
 const [order, setOrder] = React.useState('asc');
 const [orderBy, setOrderBy] = React.useState('calories');
 const [selected, setSelected] = React.useState(null);
 const [page, setPage] = React.useState(0);
 const [rowsPerPage, setRowsPerPage] = React.useState(5);

 const handleRequestSort = (event, property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc');
  setOrderBy(property);
 };

 const handleSelectAllClick = event => {
  if (event.target.checked) {
   const newSelecteds = appointments.map(n => n.name);
   setSelected(newSelecteds);
   return;
  }
  setSelected([]);
 };

 const handleClick = (event, appointment) => {
  handleViewAppointment(appointment);
  // const selectedIndex = selected.indexOf(appointment.appointment_id);
  // console.log(selectedIndex)
  // let newSelected = [];
  // newSelected.concat(selected, appointment.appointment_id);
  if (selected === appointment.appointment_id) {
   setSelected(null);
   return;
  }
  setSelected(appointment.appointment_id);

  // if (selectedIndex === -1) {
  //   newSelected = newSelected.concat(selected, appointment.appointment_id);
  // } else if (selectedIndex === 0) {
  //   newSelected = newSelected.concat(selected.slice(1));
  // } else if (selectedIndex === selected.length - 1) {
  //   newSelected = newSelected.concat(selected.slice(0, -1));
  // } else if (selectedIndex > 0) {
  //   newSelected = newSelected.concat(
  //     selected.slice(0, selectedIndex),
  //     selected.slice(selectedIndex + 1),
  //   );
  // }
 };

 const handleChangePage = (event, newPage) => {
  setPage(newPage);
 };

 const handleChangeRowsPerPage = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
 };

 const isSelected = appointment_id => selected === appointment_id;

 const emptyRows = rowsPerPage - Math.min(rowsPerPage, appointments.length - page * rowsPerPage);

 return (
  <div className={classes.root}>
   <Paper className={classes.paper}>
    <EnhancedTableToolbar total={appointments} />
    <TableContainer>
     <Table className={classes.table} aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table">
      <EnhancedTableHead
       classes={classes}
       order={order}
       orderBy={orderBy}
       onSelectAllClick={handleSelectAllClick}
       onRequestSort={handleRequestSort}
       rowCount={appointments.length}
      />
      <TableBody>
       {stableSort(appointments, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row, index) => {
         const isItemSelected = isSelected(row.appointment_id);
         const labelId = `enhanced-table-checkbox-${index}`;
         const appointmentPast = Date.parse(row.datetime) < Date.now();
         return (
          <TableRow
           hover
           onClick={event => (user_id === row.id_user ? handleClick(event, row) : {})}
           //   role="checkbox"
           aria-checked={isItemSelected}
           tabIndex={-1}
           key={row.appointment_id}
           selected={isItemSelected}
           style={{ opacity: `${appointmentPast ? 0.6 : 1}` }}>
           <TableCell component="th" id={labelId} scope="row">
            {row.date}
           </TableCell>
           <TableCell align="right">{row.time}</TableCell>
           <TableCell align="right">{row.type?.name}</TableCell>
           <TableCell align="right">{row?.healthplan_id?.name}</TableCell>
           <TableCell align="right" style={{ color: `${row.status?.color}` }}>
            {row.status?.name}
           </TableCell>
           <TableCell align="right">{row.id_user?.first_name}</TableCell>
           {!appointmentPast ? (
            <TableCell align="right">
             <RemoveIcon />
            </TableCell>
           ) : (
            <TableCell
             align="right"
             style={{
              color: `${row.historic[0]?.read && '#03989E'}`
             }}>
             {row.historic[0]?.read ? (
              <Tooltip title="Prontuário finalizado">
               <DoneAllIcon />
              </Tooltip>
             ) : (
              <Tooltip title="Prontuário não finalizado">
               <DoneIcon />
              </Tooltip>
             )}
            </TableCell>
           )}
          </TableRow>
         );
        })}
       {emptyRows > 0 && (
        <TableRow style={{ height: 33 * emptyRows }}>
         <TableCell colSpan={6} />
        </TableRow>
       )}
      </TableBody>
     </Table>
    </TableContainer>
    <TablePagination
     labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : to}`}
     labelRowsPerPage="Linhas por página"
     rowsPerPageOptions={[5, 10, 25]}
     component="div"
     count={appointments.length}
     rowsPerPage={rowsPerPage}
     page={page}
     onPageChange={handleChangePage}
     onRowsPerPageChange={handleChangeRowsPerPage}
    />
   </Paper>
  </div>
 );
}
