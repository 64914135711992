import React from 'react';

import { Grid, Typography, Divider } from '@material-ui/core';

import useStyles from './styles';

export const Emiter = ({
 firstName,
 lastName,
 document,
 specialty,
 title,
 companyAddress,
 companyNumber,
 companyNeighborhood,
 companyCity,
 companyPhone
}) => {
 const classes = useStyles();
 return (
  <Grid item container xs={12} className={classes.container}>
   <Grid item xs={12}>
    <Typography className={classes.title} color="textPrimary" variant="body1" component="p">
     IDENTIFICAÇÃO DO EMITENTE
    </Typography>
    <Divider />
   </Grid>
   <Grid item xs={8}>
    <Typography className={classes.info} variant="subtitle1" component="p">
     {title} {firstName} {lastName}
    </Typography>
   </Grid>
   {specialty && (
    <Grid item xs={4}>
     <Typography className={classes.info} variant="body2" component="p" style={{ textAlign: 'end' }}>
      {specialty}
     </Typography>
    </Grid>
   )}
   <Grid item xs={12}>
    <Typography className={classes.info} variant="subtitle1" component="p">
     CRM: {document}
    </Typography>
   </Grid>
   <Grid item xs={8}>
    <Typography className={classes.info} variant="body2" component="p">
     {companyAddress}, {companyNumber} - {companyNeighborhood}
    </Typography>
   </Grid>
   <Grid item xs={4}>
    <Typography className={classes.info} variant="body2" component="p" style={{ textAlign: 'end' }}>
     {companyCity}
    </Typography>
   </Grid>
   {companyPhone && (
    <Grid item xs={12}>
     <Typography className={classes.info} variant="body2" component="p">
      Contato: {companyPhone}
     </Typography>
    </Grid>
   )}
  </Grid>
 );
};
