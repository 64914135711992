import React from 'react';

import { Tooltip } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import Rating from '@material-ui/lab/Rating';

export default function CustomerRating({ rating, label, size = 'medium' }) {
 return (
  <Tooltip title={label} arrow placement="bottom-start">
   <span style={{ marginLeft: -5 }}>
    <Rating
     size={size}
     defaultValue={rating}
     precision={0.2}
     readOnly
     emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
    />
   </span>
  </Tooltip>
 );
}
