import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Grid, Button, TextField, Typography, Divider } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import api from '~/services/api';
import * as AlertActions from '~/store/modules/alert/actions';
import * as LoadingActions from '~/store/modules/loading/actions';
import { Formik } from 'formik';
import * as yup from 'yup';

import useStyles from './styles';

const schema = yup.object().shape({
 name: yup.string().required('Required field.')
});

const schemaAppointmentType = {
 name: '',
 appointment_type_id: null
};

export default function AppointmentTypes() {
 const dispatch = useDispatch();
 const [newAppointmentType, setnewAppointmentType] = useState(schemaAppointmentType);
 const [appointmentTypes, setAppointmentTypes] = useState([]);

 const classes = useStyles();

 useEffect(() => {
  async function loadappointmentTypes() {
   const res = await api.get('/selects/types');
   setAppointmentTypes(res.data);
  }
  loadappointmentTypes();
 }, []);

 const handleSubmit = useCallback(
  async (values, { resetForm }) => {
   dispatch(LoadingActions.setLoading());
   if (values?.appointment_type_id) {
    await api
     .patch(`/selects/types/${values?.appointment_type_id}`, values)
     .then(res => {
      resetForm(newAppointmentType);
      dispatch(AlertActions.success('Cadastro atualizado com sucesso!'));
      let newListappointmentTypes = appointmentTypes.map(hp => {
       if (hp.appointment_type_id === values.appointment_type_id) {
        hp = values;
       }
       return hp;
      });
      setAppointmentTypes(newListappointmentTypes);
      setnewAppointmentType(schemaAppointmentType);
      dispatch(LoadingActions.closeLoading());
     })
     .catch(err => {
      dispatch(AlertActions.error('Erro ao tentar atualizar tipo de atendimento aceito.'));
      dispatch(LoadingActions.closeLoading());
     });
    return;
   }

   await api
    .post('/selects/types', values)
    .then(res => {
     resetForm(newAppointmentType);
     dispatch(AlertActions.success('Cadastro feito com sucesso!'));
     setAppointmentTypes([...appointmentTypes, res.data]);
     dispatch(LoadingActions.closeLoading());
    })
    .catch(err => {
     if (err.response.status === 400) {
      dispatch(AlertActions.error(err?.response?.data?.message));
      dispatch(LoadingActions.closeLoading());
     } else {
      dispatch(AlertActions.error('Erro ao tentar cadastrar novo tipo de atendimento aceito.'));
      dispatch(LoadingActions.closeLoading());
     }
    });
  },
  [appointmentTypes]
 );

 const handleAppointmentType = useCallback(
  appointmentTypeSelected => {
   if (!appointmentTypeSelected) {
    setnewAppointmentType(schemaAppointmentType);
    return;
   }
   setnewAppointmentType(appointmentTypeSelected);
  },
  [appointmentTypes]
 );

 return (
  <Grid item container direction="column" justify="center" alignItems="stretch">
   <Grid item>
    <Typography className={classes.title} variant="h2">
     Tipos de Atendimento
    </Typography>
    <Typography color="textSecondary">Crie ou edite</Typography>
    <Autocomplete
     options={appointmentTypes}
     getOptionLabel={appointmentType => appointmentType.name && appointmentType.name}
     value={newAppointmentType}
     id="appointment_type_id"
     name="appointment_type_id"
     onChange={(e, v) => handleAppointmentType(v)}
     blurOnSelect={true}
     renderInput={params => (
      <TextField
       margin="dense"
       className={classes.textField}
       autoComplete="on"
       {...params}
       label="Tipos de Atendimento"
       variant="outlined"
       id="appointment_type_id"
       name="appointment_type_id"
      />
     )}
    />
    <Divider />
    <Typography color="textSecondary">Adicionar</Typography>
    <Formik
     initialValues={newAppointmentType}
     onSubmit={handleSubmit}
     validationSchema={schema}
     enableReinitialize={true}>
     {props => {
      const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = props;
      return (
       <form onSubmit={handleSubmit}>
        <TextField
         required
         className={classes.textField}
         fullWidth
         label="Nome"
         name="name"
         onChange={handleChange}
         type="text"
         value={values?.name}
         variant="outlined"
         error={errors?.name && touched?.name}
        />
        {!newAppointmentType.appointment_type_id ? (
         <Button
          className={classes.signUpButton}
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained">
          Adicionar
         </Button>
        ) : (
         <Grid item>
          <Button
           className={classes.signUpButton}
           fullWidth
           variant="contained"
           size="large"
           color="primary"
           type="submit">
           Editar
          </Button>
         </Grid>
        )}
       </form>
      );
     }}
    </Formik>
   </Grid>
  </Grid>
 );
}
