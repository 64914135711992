import React, { useMemo } from 'react';

import { Card, CardActions, CardContent, Grid, Button, CardHeader, Typography } from '@material-ui/core';
import BoxAvaliacao from '~/components/BoxAvaliacao';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';

export default function List({ prescription, handleEditPrescription }) {
 const dateFormatted = useMemo(() => {
  const date = new Date(prescription.created_at);
  if (date instanceof Date && !isNaN(date)) {
   return format(date, "d 'de' MMMM 'de' y", { locale: pt });
  }
  return '';
 }, [prescription]);

 const TYPES = {
  SC: 'Receita de Controle Especial',
  CO: 'Receita Comum'
 };

 return (
  <Card>
   <CardHeader
    title={dateFormatted}
    subheader={TYPES[prescription?.type]}
    subheaderTypographyProps={{ color: prescription?.type === 'SC' ? 'error' : 'textPrimary' }}
    action={
     <Typography variant="body2" color="textPrimary">
      Receita nº {prescription?.prescription_id}
     </Typography>
    }
   />

   <CardContent>
    <Grid container spacing={2} item xs={12}>
     <Grid item xs={12} md={12}>
      <BoxAvaliacao title="Receita" value={prescription?.text} />
     </Grid>
     {prescription?.appointment_id && (
      <Grid item xs={6} md={6}>
       <Card>
        <CardContent>
         <Typography variant="h6" color="textPrimary">
          Vinculado ao atendimento do dia:
         </Typography>
         <Typography variant="h6" color="textSecondary">
          {prescription?.appointment_id?.date} {prescription?.appointment_id?.time}
         </Typography>
        </CardContent>
       </Card>
      </Grid>
     )}
     {prescription?.exam_id && (
      <Grid item xs={6} md={6}>
       <Card>
        <CardContent>
         <Typography variant="h6" color="textPrimary">
          Vinculado ao exame de número:
         </Typography>
         <Typography variant="h6" color="textSecondary">
          {prescription?.exam_id?.exam_id}
         </Typography>
        </CardContent>
       </Card>
      </Grid>
     )}
    </Grid>
   </CardContent>
   <Grid item container justify="flex-end">
    <CardActions>
     <Button onClick={() => handleEditPrescription(prescription)} variant="outlined" color="primary">
      Editar
     </Button>
    </CardActions>
   </Grid>
  </Card>
 );
}
