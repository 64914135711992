import React from 'react';
import { useSelector } from 'react-redux';

import { Typography, Box, Modal, Button, Grid } from '@material-ui/core';
import history from '~/services/history';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';

export default function ModalConfirmed({ handleModal }) {
 const { modal, activeOrder } = useSelector(state => state.order);

 return (
  <Modal
   open={modal}
   onClose={handleModal}
   aria-labelledby="modal-modal-title"
   aria-describedby="modal-modal-description">
   <Box
    style={{
     position: 'absolute',
     top: '50%',
     left: '50%',
     transform: 'translate(-50%, -50%)',
     width: 600,
     backgroundColor: 'white',
     border: '2px solid gray',
     boxShadow: 24,
     p: 4,
     padding: 20,
     borderRadius: 5
    }}>
    <Grid container spacing={4} justify="center">
     <Grid item>
      <Typography variant="h2" component="h2">
       Parabéns!
      </Typography>
      <Typography sx={{ mt: 2 }}>Agora você terá acesso total ao seu prontuário. Aproveite!</Typography>
     </Grid>
     <Grid item>
      <Typography variant="h4" component="h2">
       Fique de olho no seu e-mail que lá você receberá todas as informações sobre sua assinatura.
      </Typography>
     </Grid>
     <Grid item container spacing={4}>
      <Grid item container alignContent="center" alignItems="center" spacing={2} xs={12}>
       <Typography variant="h5" sx={{ mt: 4 }}>
        Código da assinatura:
       </Typography>
       <Typography variant="h4" color="textSecondary" sx={{ mt: 4 }}>
        {activeOrder?.order_id && `${activeOrder?.order_id}/${activeOrder?.subscription_id}`}
       </Typography>
      </Grid>
      <Grid item container alignContent="center" alignItems="center" spacing={2} xs={12}>
       <Typography variant="h5" sx={{ mt: 4 }}>
        Data da assinatura:
       </Typography>
       <Typography variant="h4" color="textSecondary" sx={{ mt: 4 }}>
        {activeOrder?.created_at && format(new Date(activeOrder?.created_at), "d 'de' MMMM 'de' y", { locale: pt })}
       </Typography>
      </Grid>
      <Grid item container alignContent="center" alignItems="center" spacing={2} xs={12}>
       <Typography variant="h5" sx={{ mt: 4 }}>
        Valor à pagar:
       </Typography>
       <Typography variant="h4" color="textSecondary" sx={{ mt: 4 }}>
        {activeOrder?.value && `R$ ${activeOrder?.value}`}
       </Typography>
      </Grid>
     </Grid>
     <Grid item xs={12} style={{ marginTop: 20 }}>
      <Button fullWidth variant="contained" color="secondary" onClick={() => history.push('/entrar')}>
       Acessar
      </Button>
     </Grid>
    </Grid>
   </Box>
  </Modal>
 );
}
