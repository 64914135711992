import React from 'react';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import history from '~/services/history';
import clsx from 'clsx';
import { SearchInput } from 'components';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
 root: {},
 row: {
  height: '42px',
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(1)
 },
 spacer: {
  flexGrow: 1
 },
 importButton: {
  marginRight: theme.spacing(1)
 },
 exportButton: {
  marginRight: theme.spacing(1)
 },
 searchInput: {
  marginRight: theme.spacing(1)
 }
}));

const ClientesToolbar = props => {
 const { className, onChange, filter, onClearFilter, ...rest } = props;

 const classes = useStyles();

 return (
  <div {...rest} className={clsx(classes.root, className)}>
   <div className={classes.row}>
    <span className={classes.spacer} />
    {/* <Button className={classes.importButton} disabled>
          Import
        </Button>
        <Button className={classes.exportButton} disabled>
          Export
        </Button> */}
    <Button color="primary" variant="contained" onClick={() => history.push('/pacientes/adicionar')}>
     Cadastrar Paciente
    </Button>
   </div>
   <div className={classes.row}>
    <SearchInput
     className={classes.searchInput}
     placeholder="Buscar paciente"
     onChange={onChange}
     filter={filter}
     onClearFilter={onClearFilter}
    />
   </div>
  </div>
 );
};

ClientesToolbar.propTypes = {
 className: PropTypes.string
};

export default ClientesToolbar;
