import React from 'react';

import { Typography, Grid } from '@material-ui/core';

export const contentGenerator = paragraph => {
 if (paragraph === '')
  return (
   <>
    <br /> <br />
   </>
  );

 return (
  <Grid item xs={12}>
   <Typography variant="body1">{paragraph}</Typography>
  </Grid>
 );
};
