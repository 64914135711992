import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
 Card,
 CardActions,
 CardContent,
 Avatar,
 Table,
 TableBody,
 TableCell,
 TableHead,
 TableRow,
 Typography,
 TablePagination,
 IconButton,
 Grid
} from '@material-ui/core';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import { makeStyles } from '@material-ui/styles';
import history from '~/services/history';
import clsx from 'clsx';
import { getInitials, maskDate } from 'helpers';
import moment from 'moment';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
 root: {},
 content: {
  padding: 0
 },
 inner: {
  minWidth: 1050
 },
 nameContainer: {
  display: 'flex',
  alignItems: 'center'
 },
 avatar: {
  marginRight: theme.spacing(2)
 },
 actions: {
  justifyContent: 'flex-end'
 },
 tableRow: {
  WebkitTransition: 'box-shadow 100ms',
  transition: 'box-shadow 100ms',
  boxShadow: '5px 5px 10px rgba(0,0,0,0)',
  '&:hover': {
   boxShadow: '0 0 5px rgba(220,220,220,1)',
   WebkitBoxShadow: '0 0 5px rgba(220,220,220,1)',
   MoxBoxShadow: '0 0 5px rgba(220,220,220,1)',

   '& $icons': {
    visibility: 'visible',
    opacity: 1
   }
  }
 },
 icons: {
  opacity: 0,
  visibility: 'hidden',
  WebkitTransition: 'opacity 200ms, visibility 200ms',
  transition: 'opacity 200ms, visibility 200ms'
 }
}));

const ClientesTable = props => {
 const {
  className,
  users,
  filter,
  rowsPerPage,
  page,
  handlePageChange,
  handleRowsPerPageChange,
  countCustomers,
  ...rest
 } = props;

 const classes = useStyles();

 function handleEdit(cliente) {
  history.push(`/pacientes/detalhes/${cliente.customer_id}`);
 }

 return (
  <Card {...rest} className={clsx(classes.root, className)}>
   <CardContent className={classes.content}>
    <PerfectScrollbar>
     <div className={classes.inner}>
      <Table size="small">
       <TableHead>
        <TableRow>
         <TableCell padding="checkbox" style={{ width: 60 }}></TableCell>
         <TableCell>Nome</TableCell>
         <TableCell>Idade</TableCell>
         <TableCell>Telefone do Responsável</TableCell>
         <TableCell>Última consulta</TableCell>
        </TableRow>
       </TableHead>
       <TableBody>
        {users
         .filter(cliente => {
          return cliente['name'].toLowerCase().indexOf(filter) !== -1;
         })
         .map(customer => (
          <TableRow
           className={classes.tableRow}
           hover
           key={customer.customer_id}
           // selected={selectedUsers.indexOf(user.id_cliente) !== -1}
          >
           <TableCell padding="checkbox">
            <IconButton onClick={() => handleEdit(customer)} className={classes.icons}>
             <VisibilityRoundedIcon />
            </IconButton>
           </TableCell>
           <TableCell>
            <div className={classes.nameContainer}>
             <Avatar className={classes.avatar} src={customer.avatar}>
              {getInitials(customer.name)}
             </Avatar>
             <Typography variant="body1">{customer.name}</Typography>
            </div>
           </TableCell>
           <TableCell>{customer.age}</TableCell>
           <TableCell>
            {customer?.legal_guardians ? (
             <Grid container>
              <Grid item xs={12}>
               <Typography variant="body2">
                {customer?.legal_guardians && customer?.legal_guardians?.kinship}
               </Typography>
              </Grid>
              <Grid item xs={12}>
               {customer?.legal_guardians && maskDate(customer?.legal_guardians?.phone)}
              </Grid>
             </Grid>
            ) : (
             customer.phone
            )}
           </TableCell>
           <TableCell>{customer.last_appointment && moment(customer.last_appointment).format('DD/MM/YYYY')}</TableCell>
          </TableRow>
         ))}
       </TableBody>
      </Table>
     </div>
    </PerfectScrollbar>
   </CardContent>
   <CardActions className={classes.actions}>
    <TablePagination
     labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : to}`}
     labelRowsPerPage="Linhas por página"
     component="div"
     count={countCustomers}
     onChangePage={handlePageChange}
     onChangeRowsPerPage={handleRowsPerPageChange}
     page={page}
     rowsPerPage={rowsPerPage}
     rowsPerPageOptions={[10, 50, 100]}
    />
   </CardActions>
  </Card>
 );
};

ClientesTable.propTypes = {
 className: PropTypes.string,
 users: PropTypes.array.isRequired
};

export default ClientesTable;
