import React from 'react';

import { Typography, Grid } from '@material-ui/core';

export const Patient = ({
 patientName,
 patientAge,
 patientAddress,
 patientAddressNumber,
 patientNeighborhood,
 patientCity,
 patientPostalCode
}) => {
 return (
  <Grid item>
   <Typography variant="h4" color="textPrimary">
    {patientName}
   </Typography>
   <Typography variant="h5" color="textPrimary">
    {patientAge}
   </Typography>
   <Typography variant="body1" color="textPrimary">
    {patientAddress && patientAddress} {patientAddressNumber && `, ${patientAddressNumber}`}{' '}
    {patientNeighborhood && ` - ${patientNeighborhood}`}
   </Typography>
   <Typography variant="body1" color="textPrimary" gutterBottom>
    {patientCity && patientCity} {patientPostalCode && `- CEP: ${patientPostalCode}`}
   </Typography>
  </Grid>
 );
};
