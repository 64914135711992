import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Button, TextField, Typography, Container, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import api from '~/services/api';
import history from '~/services/history';
import * as AlertActions from '~/store/modules/alert/actions';
import * as LoadingActions from '~/store/modules/loading/actions';
import { Formik } from 'formik';
import * as yup from 'yup';

const schema = yup.object().shape({
 email: yup
  .string()
  .email()
  .required('Required field.')
});

const useStyles = makeStyles(theme => ({
 root: {
  paddingTop: theme.spacing(6)
 },
 form: {
  paddingLeft: 100,
  paddingRight: 100,
  paddingBottom: 125,
  flexBasis: 700,
  [theme.breakpoints.down('sm')]: {
   paddingLeft: theme.spacing(2),
   paddingRight: theme.spacing(2)
  }
 },
 title: {
  marginTop: theme.spacing(3)
 },
 textField: {
  marginTop: theme.spacing(2)
 },
 signInButton: {
  margin: theme.spacing(2, 0)
 }
}));

export default function RecoverPassword() {
 const classes = useStyles();
 const dispatch = useDispatch();
 const [passwordRecovered, setPasswordRecovered] = useState(false);

 const handleRecoverPassword = useCallback(email => {
  dispatch(LoadingActions.setLoading());
  api
   .post('/recover-password', { email })
   .then(res => {
    setPasswordRecovered(true);
   })
   .catch(err => {
    dispatch(
     AlertActions.error(
      'Infelizmente não conseguimos enviar o e-mail para finalizar a recuperação de senha. Tenta novamente mais tarde.'
     )
    );
   });
  dispatch(LoadingActions.closeLoading());
 }, []);

 const handleBack = () => {
  history.goBack();
 };

 return (
  <Container className={classes.root} maxWidth="md" component="main">
   <div className={classes.contentHeader}>
    <IconButton onClick={handleBack}>
     <ArrowBackIcon />
    </IconButton>
   </div>
   <Formik
    initialValues={{
     email: ''
    }}
    onSubmit={handleRecoverPassword}
    validationSchema={schema}>
    {props => {
     const {
      values: { email },
      errors,
      touched,
      handleBlur,
      handleChange
     } = props;
     return (
      <>
       <Typography className={classes.title} variant="h3">
        Recuperação de senha
       </Typography>
       {passwordRecovered ? (
        <>
         <Typography className={classes.title} color="textSecondary" variant="h4">
          Pronto!
         </Typography>
         <Typography className={classes.title} color="textSecondary" variant="h5">
          Acabamos de enviar para você um <span>e-mail</span> para finalizar a recuperação de senha.
         </Typography>
         <Button
          className={classes.signInButton}
          color="primary"
          fullWidth
          size="large"
          type="submit"
          onClick={() => history.push('/entrar')}
          variant="outlined">
          Entrar
         </Button>
        </>
       ) : (
        <>
         <Typography className={classes.title} color="textSecondary" variant="h5">
          Digite o e-mail para o qual deseja recuperar a senha.
         </Typography>
         <TextField
          className={classes.textField}
          onChange={handleChange}
          onBlur={handleBlur}
          value={email}
          error={errors.email && touched.email}
          helperText={touched.email && errors.email && 'Digite um e-mail válido'}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="E-mail"
          name="email"
          autoComplete="email"
          autoFocus
         />
         <Button
          className={classes.signInButton}
          onClick={() => handleRecoverPassword(email)}
          color="primary"
          fullWidth
          size="large"
          type="submit"
          variant="contained">
          Enviar
         </Button>
        </>
       )}
      </>
     );
    }}
   </Formik>
  </Container>
 );
}
