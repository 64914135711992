export function getNotifications() {
 return {
  type: '@global/GET_NOTIFICATIONS'
 };
}

export function getNotificationsSuccess(notifications, len) {
 return {
  type: '@global/GET_NOTIFICATIONS_SUCCESS',
  payload: {
   notifications,
   len
  }
 };
}
