import React from 'react';
import InputMask from 'react-input-mask';

import { Card, CardHeader, CardContent, CardActions, Divider, Grid, Button, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

const CompanyDetails = props => {
 const { company, handleSubmit, handleSubmitLogo, ...rest } = props;

 return (
  <Card {...rest}>
   <Formik initialValues={company} onSubmit={handleSubmit} enableReinitialize={true}>
    {props => {
     const { values, errors, touched, handleBlur, handleChange, handleSubmit } = props;
     return (
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
       <CardHeader subheader="Informações gerais" title="Clínica" content="asjaoiasijosai" />
       <Divider />
       <CardContent>
        <Grid container spacing={3} alignItems="center" justify="center">
         <Grid item>
          <img src={values?.company_logo} width="200px" height="50px" />
          <CardActions style={{ justifyContent: 'center' }}>
           <input
            accept="image/*"
            type="file"
            id="company-image"
            style={{ display: 'none' }}
            onChange={e => handleSubmitLogo(e.target.files)}
           />
           <label htmlFor="company-image">
            <Button color="primary" variant="text" component="span">
             Adicionar Imagem
            </Button>
           </label>
          </CardActions>
         </Grid>
         <Grid item container sm xs={12} spacing={1}>
          <Grid item md={6} xs={12}>
           <TextField
            fullWidth
            label="Nome da sua Clínica"
            margin="dense"
            name="fantasy_name"
            onChange={handleChange}
            value={values?.fantasy_name}
            variant="outlined"
            error={Boolean(errors.fantasy_name && touched.fantasy_name)}
            onBlur={handleBlur}
           />
          </Grid>
          <Grid item md={6} xs={12}>
           <TextField
            fullWidth
            label="Rua / Avenida"
            margin="dense"
            name="address"
            onChange={handleChange}
            value={values?.address}
            variant="outlined"
            error={Boolean(errors.address && touched.address)}
            onBlur={handleBlur}
           />
          </Grid>
          <Grid item md={6} xs={12}>
           <TextField
            fullWidth
            label="Número"
            margin="dense"
            name="number_address"
            onChange={handleChange}
            type="number"
            value={values?.number_address}
            variant="outlined"
            helperText={typeof values?.number_address !== 'number' && 'Insira um número válido'}
            error={typeof values?.number_address !== 'number'}
           />
          </Grid>
          <Grid item md={6} xs={12}>
           <TextField
            fullWidth
            label="Bairro"
            margin="dense"
            name="neighborhood"
            onChange={handleChange}
            value={values?.neighborhood}
            variant="outlined"
            error={Boolean(errors.neighborhood && touched.neighborhood)}
            onBlur={handleBlur}
           />
          </Grid>
          <Grid item md={6} xs={12}>
           <TextField
            fullWidth
            label="Cidade"
            margin="dense"
            name="city"
            onChange={handleChange}
            value={values?.city}
            variant="outlined"
            error={Boolean(errors.city && touched.city)}
            onBlur={handleBlur}
           />
          </Grid>
          <Grid item md={6} xs={12}>
           <InputMask
            mask="(99)9-9999-9999"
            maskChar=" "
            onChange={handleChange}
            type="text"
            value={values?.phone}
            onBlur={handleBlur}>
            {() => (
             <TextField
              fullWidth
              label="Telefone da Clínica"
              margin="dense"
              name="phone"
              type="text"
              variant="outlined"
              error={errors.phone && touched.phone}
             />
            )}
           </InputMask>
          </Grid>
         </Grid>
        </Grid>
       </CardContent>
       <Divider />
       <CardActions>
        <Button color="primary" variant="contained" type="submit">
         Salvar
        </Button>
       </CardActions>
      </form>
     );
    }}
   </Formik>
  </Card>
 );
};

CompanyDetails.propTypes = {
 className: PropTypes.string
};

export default CompanyDetails;
