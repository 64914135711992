import moment from 'moment';
import uuid from 'uuid/v1';

export default [
 {
  id: uuid(),
  name: 'Dropbox',
  imageUrl: '/images/products/product_1.png',
  updatedAt: moment().subtract(2, 'hours')
 },
 {
  id: uuid(),
  name: 'Medium Corporation',
  imageUrl: '/images/products/product_2.png',
  updatedAt: moment().subtract(2, 'hours')
 },
 {
  id: uuid(),
  name: 'Slack',
  imageUrl: '/images/products/product_3.png',
  updatedAt: moment().subtract(3, 'hours')
 },
 {
  id: uuid(),
  name: 'Lyft',
  imageUrl: '/images/products/product_4.png',
  updatedAt: moment().subtract(5, 'hours')
 },
 {
  id: uuid(),
  name: 'GitHub',
  imageUrl: '/images/products/product_5.png',
  updatedAt: moment().subtract(9, 'hours')
 }
];
