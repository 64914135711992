import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
 Card,
 CardHeader,
 CardContent,
 CardActions,
 Divider,
 Grid,
 Button,
 TextField,
 CircularProgress
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import LegalGuardian from '~/components/LegalGuardian';
import * as AlertActions from '~/store/modules/alert/actions';
import * as ClientesActions from '~/store/modules/clientes/actions';
import * as LoadingActions from '~/store/modules/loading/actions';
import cep from 'cep-promise';
import clsx from 'clsx';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import api from 'services/api';
import history from 'services/history';
import * as yup from 'yup';

const useStyles = makeStyles(() => ({
 root: {},
 buttonProgress: {
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12
 }
}));

const schema = yup.object().shape({
 name: yup.string().required('Campo obrigatório'),
 birthday: yup.date().required('Campo obrigatório'),
 endereco_cliente: yup.string(),
 cpf: yup.string(),
 rg: yup.string(),
 number_address: yup.string(),
 neighborhood: yup.string(),
 email: yup.string().email(),
 phone: yup.string()
});

const AddCliente = props => {
 const dispatch = useDispatch();
 const { id_cliente } = useParams();
 const { loading } = useSelector(state => state.clientes);
 const { className, ...rest } = props;
 const [clienteAtivo, setClienteAtivo] = useState({});
 const [postalCodeCEP, setPostalCodeCEP] = useState('');

 const classes = useStyles();

 function handleSubmit(cliente) {
  handleSubmitLegalGuardian(cliente.legal_guardians);

  dispatch(ClientesActions.editClienteRequest(cliente));
 }

 async function handleSubmitLegalGuardian(legalGuardians) {
  await legalGuardians.map(guardian => {
   if (guardian.legal_guardian_id > 0) {
    api
     .put(`/legal-guardians/${guardian.legal_guardian_id}`, guardian)
     .then(res => {})
     .catch(err => {});
   } else {
    guardian = {
     ...guardian,
     customer_id: clienteAtivo.customer_id
    };
    api
     .post(`/legal-guardians`, guardian)
     .then(res => {})
     .catch(err => {});
   }
  });
 }

 useEffect(() => {
  async function loadEffect() {
   dispatch(LoadingActions.setLoading());
   try {
    const { data } = await api.get(`/customers/${id_cliente}`);

    setClienteAtivo(data);
    dispatch(LoadingActions.closeLoading());
   } catch (error) {
    console.log('Não foi possível carregar os dados do cliente.');
   }
  }
  loadEffect();
 }, []);

 const handleDeleteCustomer = useCallback(async customer_id => {
  dispatch(LoadingActions.setLoading());
  await api
   .delete(`/customers/${customer_id}`)
   .then(resp => {
    history.push('/pacientes');
    dispatch(AlertActions.success('Paciente deletado com sucesso.'));
   })
   .catch(err => {
    dispatch(AlertActions.error('Erro ao tentar deletar paciente.'));
   });
  dispatch(LoadingActions.closeLoading());
 }, []);

 useEffect(() => {
  async function loadCEP() {
   try {
    const data = await cep(postalCodeCEP);
    setClienteAtivo({
     ...clienteAtivo,
     address: data.street,
     city: data.city,
     neighborhood: data.neighborhood,
     postal_code: postalCodeCEP
    });
   } catch (error) {}
  }
  if (postalCodeCEP.length === 8) {
   loadCEP();
  } else {
   setClienteAtivo({
    ...clienteAtivo,
    address: '',
    city: '',
    neighborhood: '',
    postal_code: postalCodeCEP
   });
  }
 }, [postalCodeCEP]);

 const handleGuardian = useCallback(() => {
  setClienteAtivo({
   ...clienteAtivo,
   legal_guardians: [
    ...clienteAtivo.legal_guardians,
    {
     legal_guardian_id: clienteAtivo.legal_guardians.length ? -clienteAtivo.legal_guardians.length : 0
    }
   ]
  });
 }, [clienteAtivo]);

 const removeGuardian = useCallback(
  async guardian => {
   if (guardian.legal_guardian_id <= 0) {
    setClienteAtivo({
     ...clienteAtivo,
     legal_guardians: clienteAtivo.legal_guardians.filter(g => g?.legal_guardian_id !== guardian.legal_guardian_id)
    });
    return;
   }

   dispatch(LoadingActions.setLoading());

   await api
    .delete(`/legal-guardians/${guardian.legal_guardian_id}`)
    .then(res => {
     let newListLegalGuardians = clienteAtivo.legal_guardians.filter(
      g => g.legal_guardian_id !== guardian.legal_guardian_id
     );
     setClienteAtivo({
      ...clienteAtivo,
      legal_guardians: newListLegalGuardians
     });
     dispatch(AlertActions.success('Responsável apagado com sucesso!'));
     dispatch(LoadingActions.closeLoading());
    })
    .catch(err => {
     dispatch(AlertActions.error('Erro ao tentar apagar responsável.'));
     dispatch(LoadingActions.closeLoading());
    });
  },
  [clienteAtivo]
 );

 function handleChangeGuardian(e, id) {
  let { name, value } = e.target;
  if (!e.target.value) {
   value = e.target.checked;
  }

  let newListLegalGuardianEdited = clienteAtivo.legal_guardians.map(guardian => {
   if (guardian?.legal_guardian_id === id) {
    guardian[name] = value;
   }
   return guardian;
  });

  setClienteAtivo({
   ...clienteAtivo,
   legal_guardians: newListLegalGuardianEdited
  });
 }

 return (
  <Card {...rest} className={clsx(classes.root, className)}>
   {clienteAtivo.name && (
    <Formik initialValues={clienteAtivo} onSubmit={handleSubmit} validationSchema={schema} enableReinitialize={true}>
     {props => {
      const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = props;

      function handleChangeCep(postal_code) {
       setClienteAtivo(clienteAtivo);
       handleChange(postal_code);
       setPostalCodeCEP(postal_code);
      }

      return (
       <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <CardHeader subheader="Editar cadastro de cliente" title="Cliente" />
        <Divider />
        <CardContent>
         <Grid container spacing={3} alignContent="center">
          <Grid item md={6} xs={12}>
           <TextField
            fullWidth
            helperText="Nome completo do cliente"
            label="Nome"
            margin="dense"
            name="name"
            onChange={handleChange}
            required
            value={values.name}
            variant="outlined"
            error={Boolean(errors.name && touched.name)}
            onBlur={handleBlur}
           />
          </Grid>
          <Grid item md={6} xs={12}>
           <KeyboardDatePicker
            margin="dense"
            label="Data de nascimento"
            name="birthday"
            format="dd/MM/yyyy"
            value={values.birthday}
            inputVariant="outlined"
            onChange={date => setFieldValue('birthday', date)}
            KeyboardButtonProps={{
             'aria-label': 'change date'
            }}
            InputLabelProps={{
             shrink: true
            }}
            error={Boolean(errors.birthday && touched.birthday)}
            onBlur={handleBlur}
           />
          </Grid>
          <Grid item md={6} xs={12}>
           <TextField
            fullWidth
            label="RG"
            margin="dense"
            name="rg"
            onChange={handleChange}
            required
            value={values.rg}
            variant="outlined"
            error={Boolean(errors.rg && touched.rg)}
            onBlur={handleBlur}
            helperText="Somente números"
           />
          </Grid>
          <Grid item md={6} xs={12}>
           <TextField
            fullWidth
            label="CPF"
            margin="dense"
            name="cpf"
            onChange={handleChange}
            required
            value={values.cpf}
            variant="outlined"
            error={Boolean(errors.cpf && touched.cpf)}
            onBlur={handleBlur}
            helperText="Somente números"
           />
          </Grid>
          <Grid item md={6} xs={12}>
           <TextField
            fullWidth
            label="CEP"
            margin="dense"
            name="postal_code"
            onChange={e => handleChangeCep(e.target.value)}
            value={values.postal_code}
            variant="outlined"
            type="number"
            error={Boolean(errors.postal_code && touched.postal_code)}
            onBlur={handleBlur}
            helperText={touched.postal_code && errors.postal_code && 'Insira um número válido'}
           />
          </Grid>
          <Grid item md={6} xs={12}>
           <TextField
            fullWidth
            label="Rua / Avenida"
            margin="dense"
            name="address"
            onChange={handleChange}
            required
            value={values.address}
            variant="outlined"
            error={Boolean(errors.address && touched.address)}
            onBlur={handleBlur}
           />
          </Grid>
          <Grid item md={6} xs={12}>
           <TextField
            fullWidth
            label="Num"
            margin="dense"
            name="number_address"
            onChange={handleChange}
            type="number"
            value={values.number_address}
            variant="outlined"
           />
          </Grid>
          <Grid item md={6} xs={12}>
           <TextField
            fullWidth
            label="Bairro"
            margin="dense"
            name="neighborhood"
            onChange={handleChange}
            value={values.neighborhood}
            variant="outlined"
            required
            error={Boolean(errors.neighborhood && touched.neighborhood)}
            onBlur={handleBlur}
           />
          </Grid>
          <Grid item md={6} xs={12}>
           <TextField
            fullWidth
            label="Cidade"
            margin="dense"
            name="city"
            onChange={handleChange}
            value={values.city}
            variant="outlined"
            error={Boolean(errors.city && touched.city)}
            onBlur={handleBlur}
           />
          </Grid>
          <Grid item md={6} xs={12}>
           <TextField
            fullWidth
            label="E-mail"
            margin="dense"
            name="email"
            onChange={handleChange}
            required
            value={values.email}
            variant="outlined"
            error={Boolean(errors.email && touched.email)}
            onBlur={handleBlur}
           />
          </Grid>
          <Grid item md={6} xs={12}>
           <TextField
            fullWidth
            label="Celular"
            margin="dense"
            name="phone"
            onChange={handleChange}
            required
            value={values.phone}
            variant="outlined"
            error={Boolean(errors.phone && touched.phone)}
            onBlur={handleBlur}
           />
          </Grid>
          {/* <Grid item md={6} xs={12}>
           <Button
            style={{ backgroundColor: '#fc636b' }}
            startIcon={<DeleteIcon />}
            color="secondary"
            variant="contained"
            fullWidth
            onClick={() => handleDeleteCustomer(id_cliente)}>
            Deletar Paciente
           </Button>
          </Grid> */}
         </Grid>
        </CardContent>
        <Divider />
        <CardHeader
         subheader="Pessoa responsável pelo paciente"
         title="Responsável"
         action={
          <Button color="primary" variant="outlined" onClick={handleGuardian}>
           Novo Responsável
          </Button>
         }
        />
        {values?.legal_guardians?.map(guardian => (
         <LegalGuardian
          key={guardian?.legal_guardian_id}
          guardian={guardian}
          remove={removeGuardian}
          handleChange={handleChangeGuardian}
         />
        ))}
        <Divider />
        <CardContent>
         <Grid container>
          <TextField
           fullWidth
           multiline
           rows={6}
           label="Detalhes"
           margin="dense"
           onChange={handleChange}
           variant="outlined"
           value={values.details}
           id="details"
           name="details"
           error={Boolean(errors.details)}
           onBlur={handleBlur}
          />
         </Grid>
        </CardContent>
        <CardActions>
         <Button type="submit" color="primary" variant="contained" disabled={loading}>
          Salvar
          {loading && <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />}
         </Button>
        </CardActions>
       </form>
      );
     }}
    </Formik>
   )}
  </Card>
 );
};

AddCliente.propTypes = {
 className: PropTypes.string
};

export default AddCliente;
