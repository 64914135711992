import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';
import {
 Dashboard as DashboardView,
 Agenda,
 ClientesList as ClientesListView,
 Account as AccountView,
 Settings as SettingsView,
 SignUp as SignUpView,
 SignIn as SignInView,
 NotFound as NotFoundView,
 ViewCliente,
 EditCliente,
 ViewAgenda,
 DetalhesCliente,
 EditarAvaliacao,
 Avaliacoes,
 Company,
 Pricing,
 Order,
 Exams,
 TermosUso,
 PoliticasPrivacidade,
 RecoverPassword,
 Prescriptions
} from './views';

const Routes = () => {
 return (
  <Switch>
   <Redirect exact from="/" to="/agenda" />
   <RouteWithLayout component={DashboardView} exact layout={MainLayout} path="/dashboard" isPrivate />
   <RouteWithLayout component={ClientesListView} exact layout={MainLayout} path="/pacientes" isPrivate />
   <RouteWithLayout
    component={DetalhesCliente}
    exact
    layout={MainLayout}
    path="/pacientes/detalhes/:id_cliente"
    isPrivate
   />
   <RouteWithLayout component={ViewCliente} exact layout={MainLayout} path="/pacientes/adicionar" isPrivate />
   <RouteWithLayout
    component={EditCliente}
    exact
    layout={MainLayout}
    path="/pacientes/detalhes/:id_cliente/editar"
    isPrivate
   />
   <RouteWithLayout
    component={EditarAvaliacao}
    exact
    layout={MainLayout}
    path="/pacientes/detalhes/:id_cliente/prontuarios/:id_agendamento"
    isPrivate
   />
   <RouteWithLayout
    component={Avaliacoes}
    exact
    layout={MainLayout}
    path="/pacientes/detalhes/:id_cliente/prontuarios"
    isPrivate
   />
   <RouteWithLayout
    component={Exams}
    exact
    layout={MainLayout}
    path="/pacientes/detalhes/:id_cliente/exames"
    isPrivate
   />
   <RouteWithLayout
    component={Prescriptions}
    exact
    layout={MainLayout}
    path="/pacientes/detalhes/:id_cliente/receitas"
    isPrivate
   />
   <RouteWithLayout component={Agenda} exact layout={MainLayout} path="/agenda" isPrivate />
   <RouteWithLayout component={ViewAgenda} exact layout={MainLayout} path="/agenda/editar" isPrivate />
   <RouteWithLayout component={ViewAgenda} exact layout={MainLayout} path="/agenda/editar/:id_agendamento" isPrivate />
   <RouteWithLayout component={AccountView} exact layout={MainLayout} path="/perfil" isPrivate />
   <RouteWithLayout component={SettingsView} exact layout={MainLayout} path="/settings" isPrivate />
   <RouteWithLayout component={Company} exact layout={MainLayout} path="/clinica" isPrivate />
   <RouteWithLayout component={PoliticasPrivacidade} exact layout={MinimalLayout} path="/politicas-de-privacidade" />
   <RouteWithLayout component={TermosUso} exact layout={MinimalLayout} path="/termos-de-uso" />
   <RouteWithLayout component={SignUpView} exact layout={MinimalLayout} path="/registrar" />
   <RouteWithLayout component={SignInView} exact layout={MinimalLayout} path="/entrar" />
   <RouteWithLayout component={Pricing} exact layout={MinimalLayout} path="/planos" />
   <RouteWithLayout component={Order} exact layout={MinimalLayout} path="/planos/finalizar" />
   <RouteWithLayout component={RecoverPassword} exact layout={MinimalLayout} path="/recuperar-senha" />
   <RouteWithLayout component={NotFoundView} exact layout={MinimalLayout} path="/not-found" />
   <Redirect to="/not-found" />
  </Switch>
 );
};

export default Routes;
