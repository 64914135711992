import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Avatar, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { getInitials } from 'helpers';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
 root: {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: 'fit-content'
 },
 avatar: {
  width: 60,
  height: 60
 },
 name: {
  marginTop: theme.spacing(1)
 }
}));

const Profile = props => {
 const { profile, loading } = useSelector(state => state.user);
 const { className, ...rest } = props;
 const classes = useStyles();

 return (
  <div {...rest} className={clsx(classes.root, className)}>
   {loading ? (
    <Skeleton className={classes.avatar} variant="circle" animation="wave" />
   ) : (
    <Avatar alt="Profile" className={classes.avatar} component={RouterLink} src={profile?.avatar} to="/settings">
     {getInitials(`${profile?.first_name}${profile?.last_name}`)}
    </Avatar>
   )}
   <Typography className={classes.name} variant="h4">
    {profile?.first_name}
   </Typography>
   <Typography variant="body2">{profile?.email}</Typography>
  </div>
 );
};

Profile.propTypes = {
 className: PropTypes.string
};

export default Profile;
