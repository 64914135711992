import * as React from 'react';

import {
 Typography,
 Button,
 Dialog,
 DialogActions,
 DialogContent,
 DialogTitle,
 IconButton,
 Grid
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { styled } from '@material-ui/styles';
import PropTypes from 'prop-types';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
 '& .MuiDialogContent-root': {
  padding: theme.spacing(2)
 },
 '& .MuiDialogActions-root': {
  padding: theme.spacing(1)
 }
}));

const BootstrapDialogTitle = props => {
 const { children, onClose, ...other } = props;

 return (
  <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
   <Grid container alignItems="center" justify="space-between">
    <Grid item>{children}</Grid>
    <Grid item>
     {onClose ? (
      <IconButton
       aria-label="close"
       onClick={onClose}
       sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: theme => theme.palette.grey[500]
       }}>
       <CloseIcon />
      </IconButton>
     ) : null}
    </Grid>
   </Grid>
  </DialogTitle>
 );
};

BootstrapDialogTitle.propTypes = {
 children: PropTypes.node,
 onClose: PropTypes.func.isRequired
};

export default function ModalCancel({
 itemId,
 isOpen,
 handleOpenClose,
 handleConfirm,
 title,
 textContent,
 textContentSecondary
}) {
 return (
  <div>
   <Dialog onClose={handleOpenClose} aria-labelledby="customized-dialog-title" open={isOpen}>
    <BootstrapDialogTitle onClose={handleOpenClose}>
     <Typography variant="h3" color="textPrimary">
      {title}
     </Typography>
    </BootstrapDialogTitle>
    <DialogContent dividers>
     <Typography variant="h5" color="textPrimary" gutterBottom>
      {textContent}
     </Typography>
     <Typography variant="h6" color="textSecondary">
      {textContentSecondary}
     </Typography>
    </DialogContent>
    <DialogActions>
     <Button
      variant="contained"
      onClick={() => handleConfirm(itemId)}
      style={{ backgroundColor: '#F44336', color: 'white' }}>
      Confirmar
     </Button>
     <Button autoFocus variant="outlined" onClick={handleOpenClose} color="primary">
      Fechar
     </Button>
    </DialogActions>
   </Dialog>
  </div>
 );
}
