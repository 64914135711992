import React from 'react';

import { Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { whatsappUrl } from '~/common/whatsappUrl';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
 root: {
  padding: theme.spacing(4)
 }
}));

const Footer = props => {
 const { className, ...rest } = props;

 const classes = useStyles();

 return (
  <div {...rest} className={clsx(classes.root, className)}>
   <Typography variant="body1">
    &copy;{' '}
    <Link component="a" href={whatsappUrl} target="_blank">
     bydoctor
    </Link>
    . {new Date().getFullYear()}
   </Typography>
   <Typography variant="caption">---</Typography>
  </div>
 );
};

Footer.propTypes = {
 className: PropTypes.string
};

export default Footer;
