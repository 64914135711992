import React from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import history from '~/services/history';
import * as AgendActions from '~/store/modules/agenda/actions';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
 root: {},
 row: {
  height: '42px',
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(1)
 },
 spacer: {
  flexGrow: 1
 },
 importButton: {
  marginRight: theme.spacing(1)
 },
 exportButton: {
  marginRight: theme.spacing(1)
 },
 searchInput: {
  marginRight: theme.spacing(1)
 }
}));

const AgendaToolbar = props => {
 const dispatch = useDispatch();
 const { className, filter, date, ...rest } = props;

 const classes = useStyles();

 function handleCriarAgendamento() {
  const data = {
   date: date
  };

  dispatch(AgendActions.setAgendamentoAtivo(data));
  history.push('/agenda/editar');
 }

 return (
  <Button color="primary" variant="contained" onClick={handleCriarAgendamento}>
   Criar Agendamento
  </Button>
 );
};

AgendaToolbar.propTypes = {
 className: PropTypes.string
};

export default AgendaToolbar;
