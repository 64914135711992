import React from 'react';

import { makeStyles } from '@material-ui/styles';

import { AddCliente } from './components';

const useStyles = makeStyles(theme => ({
 root: {
  padding: theme.spacing(3)
 },
 content: {
  marginTop: theme.spacing(2)
 }
}));

const ViewCliente = () => {
 const classes = useStyles();

 return (
  <div className={classes.root}>
   <div className={classes.content}>
    <AddCliente />
   </div>
  </div>
 );
};

export default ViewCliente;
