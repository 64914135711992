import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
 Card,
 CardHeader,
 CardContent,
 CardActions,
 Divider,
 Grid,
 Button,
 TextField,
 CircularProgress,
 Tooltip,
 MenuItem,
 Menu,
 FormControl
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import { CustomerRating } from '~/components';
import api from '~/services/api';
import history from '~/services/history';
import * as AgenActions from '~/store/modules/agenda/actions';
import clsx from 'clsx';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

const useStyles = makeStyles(() => ({
 root: {},
 buttonProgress: {
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12
 }
}));

const schema = yup.object().shape({
 customer_id: yup.string().required('Required field.'),
 date: yup.string().required('Required field.'),
 time: yup.string().required('Required field.'),
 healthplan_id: yup.string().required('Required field.'),
 type: yup.string().required('Required field.'),
 id_user: yup.string().required('Required field.'),
 status: yup.string().required('Required field.')
});

const EditAgenda = props => {
 const dispatch = useDispatch();
 const { dataAgendamento, loading } = useSelector(state => state.agenda);
 const { appointmentEdited } = useLocation();
 const { className, ...rest } = props;
 const [clientes, setClientes] = useState([]);
 const [appointmentTypes, setAppointmentTypes] = useState([]);
 const [professionals, setProfessionals] = useState([]);
 const [healthPlans, setHealthPlans] = useState([]);
 const [status, setStatus] = useState([]);
 const [customerFilter, setCustomerFilter] = useState('');
 const [open, setOpen] = useState(false);
 const [loadingCustomer, setLoadingCustomer] = useState(false);
 const [professionalSelected, setProfessionalSelected] = useState(
  appointmentEdited ? appointmentEdited.id_user.id_user : undefined
 );

 const classes = useStyles();

 const handleOpen = useCallback(
  (bool, event) => {
   if (event && event.key === 'Enter') {
    let value = event.target.value;
    setCustomerFilter(value);
    if (value) {
     setOpen(bool);
     setLoadingCustomer(true);
    }
   }
  },
  [open, customerFilter]
 );

 useEffect(() => {
  async function loadTypes() {
   const res = await api.get('/selects/types');
   setAppointmentTypes(res.data);
  }
  loadTypes();
 }, []);

 useEffect(() => {
  async function loadStatus() {
   const res = await api.get('/selects/status');
   setStatus(res.data.results);
  }
  loadStatus();
 }, []);

 useEffect(() => {
  async function loadProfessionals() {
   const res = await api.get('/companies/professionals');
   setProfessionals(res.data);
  }
  loadProfessionals();
 }, []);

 useEffect(() => {
  async function loadHealthPlans() {
   const res = await api.get('/health-plans', {
    params: {
     id_user: professionalSelected
    }
   });
   setHealthPlans(res.data.results);
  }
  if (professionalSelected) {
   loadHealthPlans();
  }
 }, [professionalSelected]);

 function handleSubmit(data) {
  if (data.appointment_id) {
   return dispatch(AgenActions.editAgen(data));
  }
  dispatch(AgenActions.addAgen(data));
  return;
 }

 useEffect(() => {
  if (!open) {
   setClientes([]);
  }
 }, [open]);

 useEffect(() => {
  let active = true;

  if (!loadingCustomer) return;

  async function loadClientes() {
   const res = await api.get('/customers', {
    params: {
     page_size: 1000,
     name__icontains: customerFilter
    }
   });
   setClientes(res.data.results);
   setLoadingCustomer(false);
  }

  if (active) {
   loadClientes();
  }

  return () => {
   active = false;
  };
 }, [loadingCustomer]);

 const [anchorElCancel, setAnchorElCancel] = useState(null);

 const handleClickCancel = event => {
  setAnchorElCancel(event.currentTarget);
 };

 const handleCloseCancel = () => {
  setAnchorElCancel(null);
 };

 const cancelAppointment = useCallback(appointment_id => {
  handleCancel(appointment_id);
  handleCloseCancel();
  history.push('/agenda');
 });

 async function handleCancel(id) {
  dispatch(AgenActions.cancelAgendamentoRequest(id));
 }

 return (
  <Card {...rest} className={clsx(classes.root, className)}>
   <Formik initialValues={appointmentEdited ? appointmentEdited : {}} onSubmit={handleSubmit} validationSchema={schema}>
    {props => {
     const { values, errors, handleBlur, handleSubmit, setFieldValue, handleChange } = props;

     function handleProfessionalSelected(v) {
      setFieldValue('id_user', v);
      setProfessionalSelected(v?.id_user);
     }

     return (
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
       <CardHeader subheader="Novo/Editar agendamento" title="Agendamento" />
       <Divider />
       <CardContent>
        <Grid container spacing={3}>
         <Grid item md={3} xs={12}>
          <KeyboardDatePicker
           required
           fullWidth
           // minDate={new Date() - 2}
           margin="dense"
           inputVariant="outlined"
           name="date"
           label="Data da consulta"
           format="dd/MM/yyyy"
           value={typeof values?.date === 'string' ? values?.datetime : values?.date}
           onChange={date => setFieldValue('date', date)}
           KeyboardButtonProps={{
            'aria-label': 'change date'
           }}
           InputLabelProps={{
            shrink: true
           }}
           error={Boolean(errors?.date)}
           onBlur={handleBlur}
          />
         </Grid>
         <Grid item md={3} xs={12}>
          <KeyboardTimePicker
           required
           fullWidth
           ampm={false}
           value={typeof values?.time === 'string' ? values?.datetime : values?.time}
           variant="inline"
           margin="dense"
           inputVariant="outlined"
           label="Horário da consulta"
           onChange={date => setFieldValue('time', date)}
           KeyboardButtonProps={{
            'aria-label': 'change time'
           }}
           InputLabelProps={{
            shrink: true
           }}
           error={Boolean(errors?.time)}
           onBlur={handleBlur}
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <Autocomplete
           required
           options={professionals}
           getOptionLabel={professional => professional?.first_name || ''}
           getOptionSelected={(option, value) => option?.first_name === value?.first_name}
           value={values?.id_user}
           id="id_user"
           margin="dense"
           name="id_user"
           onChange={(e, v) => handleProfessionalSelected(v)}
           blurOnSelect={true}
           renderInput={params => (
            <TextField
             required
             autoComplete="on"
             {...params}
             label="Profissional"
             margin="dense"
             variant="outlined"
             id="id_user"
             name="id_user"
             error={Boolean(errors?.id_user)}
             onBlur={handleBlur}
            />
           )}
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <Grid container spacing={1} justify="space-between" alignItems="center" direction="row">
           <Grid item xs={12} sm={10}>
            <Autocomplete
             id="customer_id"
             margin="dense"
             name="customer_id"
             open={open}
             onClose={() => {
              setOpen(false);
             }}
             value={values?.customer_id}
             getOptionSelected={(option, value) => option?.name === value?.name}
             getOptionLabel={option => option?.name || ''}
             options={clientes}
             loading={loadingCustomer}
             onChange={(e, v) => setFieldValue('customer_id', v)}
             onKeyPress={e => handleOpen(true, e)}
             blurOnSelect={true}
             renderInput={params => (
              <TextField
               {...params}
               required
               autoComplete="on"
               {...params}
               label="Pacientes"
               margin="dense"
               variant="outlined"
               id="customer_id"
               name="customer_id"
               error={Boolean(errors?.customer_id)}
               onBlur={handleBlur}
               InputProps={{
                ...params.InputProps,
                endAdornment: (
                 <React.Fragment>
                  {loadingCustomer ? (
                   <CircularProgress color="inherit" size={20} />
                  ) : (
                   <Tooltip title="Pressione 'Enter' para filtrar" placement="top">
                    <SearchIcon fontSize="default" />
                   </Tooltip>
                  )}
                  {params.InputProps.endAdornment}
                 </React.Fragment>
                )
               }}
              />
             )}
            />
           </Grid>
           <Grid item xs={12} sm={2}>
            <Button fullWidth variant="contained" color="primary" onClick={() => history.push('/pacientes/adicionar')}>
             <AddIcon />
            </Button>
           </Grid>
          </Grid>
          {values?.customer_id?.rating?.number >= 0 && (
           <CustomerRating
            size="small"
            rating={values?.customer_id?.rating?.number}
            label={values?.customer_id?.rating?.label}
           />
          )}
         </Grid>
         <Grid item md={6} xs={12}>
          <Grid container spacing={1} justify="space-between" alignItems="center" direction="row">
           <Grid item xs={12} sm={10}>
            <Autocomplete
             required
             options={appointmentTypes}
             getOptionLabel={type => type?.name || ''}
             getOptionSelected={(option, value) => option?.name === value?.name}
             value={values?.type}
             id="type"
             margin="dense"
             name="type"
             onChange={(e, v) => setFieldValue('type', v)}
             blurOnSelect={true}
             renderInput={params => (
              <TextField
               required
               autoComplete="on"
               {...params}
               label="Tipo"
               margin="dense"
               variant="outlined"
               id="type"
               name="type"
               error={Boolean(errors?.type)}
               onBlur={handleBlur}
              />
             )}
            />
           </Grid>
           <Grid item xs={12} sm={2}>
            <Button fullWidth variant="contained" color="primary" onClick={() => history.push('/clinica')}>
             <AddIcon />
            </Button>
           </Grid>
          </Grid>
         </Grid>
         <Grid item md={6} xs={12}>
          <Grid container spacing={1} justify="space-between" alignItems="center" direction="row">
           <Grid item xs={12} sm={10}>
            <Autocomplete
             required
             options={healthPlans}
             getOptionLabel={healthPlan => healthPlan?.name || ''}
             getOptionSelected={(option, value) => option?.name === value?.name}
             value={values?.healthplan_id}
             id="healthplan_id"
             margin="dense"
             name="healthplan_id"
             onChange={(e, v) => setFieldValue('healthplan_id', v)}
             blurOnSelect={true}
             renderInput={params => (
              <TextField
               required
               autoComplete="on"
               {...params}
               label="Plano de Saúde"
               margin="dense"
               variant="outlined"
               id="healthplan_id"
               name="healthplan_id"
               error={Boolean(errors?.healthplan_id)}
               onBlur={handleBlur}
              />
             )}
            />
           </Grid>
           <Grid item xs={12} sm={2}>
            <Button fullWidth variant="contained" color="primary" onClick={() => history.push('/clinica')}>
             <AddIcon />
            </Button>
           </Grid>
          </Grid>
         </Grid>
         <Grid item md={6} xs={12}>
          <Autocomplete
           required
           options={status}
           getOptionLabel={status => status?.name || ''}
           getOptionSelected={(option, value) => option?.name === value?.name}
           value={values?.status}
           id="status"
           margin="dense"
           name="status"
           onChange={(e, v) => setFieldValue('status', v)}
           blurOnSelect={true}
           renderInput={params => (
            <TextField
             required
             autoComplete="on"
             {...params}
             label="Status"
             margin="dense"
             variant="outlined"
             id="status"
             name="status"
             error={Boolean(errors?.status)}
             onBlur={handleBlur}
            />
           )}
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <TextField
           multiline
           rows={4}
           fullWidth
           value={values?.note}
           onChange={handleChange}
           label="Observações Extras"
           margin="dense"
           variant="outlined"
           id="note"
           name="note"
           error={Boolean(errors?.note)}
           onBlur={handleBlur}
          />
         </Grid>
         {values.appointment_id && (
          <Grid item md={6} xs={12}>
           <FormControl
            fullWidth
            aria-label="Acknowledge"
            onClick={event => event.stopPropagation()}
            onFocus={event => event.stopPropagation()}>
            <Menu
             id="simple-menu-cancel"
             anchorEl={anchorElCancel}
             keepMounted
             open={Boolean(anchorElCancel)}
             onClose={handleCloseCancel}>
             <MenuItem onClick={() => cancelAppointment(values.appointment_id)}>Confimar</MenuItem>
            </Menu>
            <Button
             style={{ backgroundColor: '#fc636b' }}
             startIcon={<DeleteIcon />}
             color="secondary"
             variant="contained"
             fullWidth
             onClick={handleClickCancel}>
             Cancelar Agendamento
            </Button>
           </FormControl>
          </Grid>
         )}
         {/* <Grid item md={12} xs={12}>
                    <Historico
                      historico={historico}
                    />
                  </Grid> */}
        </Grid>
       </CardContent>
       <>
        <Divider />
        <CardActions>
         <Button onClick={handleSubmit} type="submit" color="primary" variant="contained" disabled={loading}>
          Salvar
          {loading && <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />}
         </Button>
        </CardActions>
       </>
      </form>
     );
    }}
   </Formik>
  </Card>
 );
};

EditAgenda.propTypes = {
 className: PropTypes.string
};

export default EditAgenda;
