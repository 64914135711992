export function saveProfile(editedProfile) {
 return {
  type: '@user/SAVE_PROFILE',
  payload: {
   editedProfile
  }
 };
}

export function saveProfileSuccess(profile) {
 return {
  type: '@user/SAVE_PROFILE_SUCCESS',
  payload: {
   profile
  }
 };
}

export function imageRequest(image) {
 return {
  type: '@user/IMAGE_REQUEST',
  payload: {
   image
  }
 };
}

export function imageSuccess(avatar) {
 return {
  type: '@user/IMAGE_SUCCESS',
  payload: {
   avatar
  }
 };
}

export function loading(load) {
 return {
  type: '@user/LOADING',
  payload: {
   load
  }
 };
}

export function changePassword(passwords) {
 return {
  type: '@user/CHANGE_PASSWORD',
  payload: {
   passwords
  }
 };
}
