import api from '~/services/api';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import { getNotificationsSuccess } from './actions';

export function* get() {
 try {
  const res = yield call(api.get, 'histories/unread');
  const data = res.data;

  yield put(getNotificationsSuccess(data, data.length));
 } catch (error) {}
}

export default all([takeLatest('@global/GET_NOTIFICATIONS', get)]);
