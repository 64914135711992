import React, { useState } from 'react';

import {
 Card,
 CardHeader,
 CardContent,
 Divider,
 List,
 ListItem,
 ListItemAvatar,
 ListItemText,
 IconButton
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import mockData from './data';

const useStyles = makeStyles(() => ({
 root: {
  height: '100%'
 },
 content: {
  padding: 0
 },
 image: {
  height: 48,
  width: 48
 },
 actions: {
  justifyContent: 'flex-end'
 }
}));

const LatestProducts = props => {
 const { className, ...rest } = props;

 const classes = useStyles();

 const [products] = useState(mockData);

 return (
  <Card {...rest} className={clsx(classes.root, className)}>
   <CardHeader subtitle={`${products.length} in total`} title="Em breve" />
   <Divider />
   <CardContent className={classes.content}>
    <List>
     {products.map((product, i) => (
      <ListItem divider={i < products.length - 1} key={product.id}>
       <ListItemAvatar>
        {/* <img
                  alt="Product"
                  className={classes.image}
                  src={product.imageUrl}
                /> */}
        <Skeleton variant="circle" width={48} height={48} animation="wave" />
       </ListItemAvatar>
       <ListItemText
        primary={<Skeleton variant="text" animation="wave" />}
        secondary={<Skeleton variant="text" animation="wave" />}
       />
       <IconButton edge="end" size="small">
        {/* <MoreVertIcon /> */}
       </IconButton>
      </ListItem>
     ))}
    </List>
   </CardContent>
   <Divider />
   {/* <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          View all <ArrowRightIcon />
        </Button>
      </CardActions> */}
  </Card>
 );
};

LatestProducts.propTypes = {
 className: PropTypes.string
};

export default LatestProducts;
