import React, { useState, useCallback } from 'react';

import { Paper, Input, Tooltip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
 root: {
  borderRadius: '4px',
  alignItems: 'center',
  padding: theme.spacing(1),
  display: 'flex',
  flexBasis: 420
 },
 icon: {
  marginRight: theme.spacing(1),
  color: theme.palette.text.secondary
 },
 input: {
  flexGrow: 1,
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '-0.05px'
 }
}));

const SearchInput = props => {
 const { className, onChange, style, filter, onClearFilter, ...rest } = props;
 const [searchFilter, setSearchFilter] = useState('');

 const classes = useStyles();

 const handleChangeFilter = useCallback(event => {
  if (event === 'Clear') {
   onClearFilter();
   setSearchFilter('');
   return;
  }
  setSearchFilter(event.target.value);
 }, []);

 return (
  <Tooltip title="Pressione 'Enter' para filtrar" placement="top">
   <Paper {...rest} className={clsx(classes.root, className)} style={style}>
    {filter.length < 2 ? (
     <SearchIcon className={classes.icon} />
    ) : (
     <ClearIcon className={classes.icon} onClick={() => handleChangeFilter('Clear')} />
    )}
    <Input
     {...rest}
     value={searchFilter}
     className={classes.input}
     disableUnderline
     onKeyPress={e => onChange(e)}
     onChange={e => handleChangeFilter(e)}
    />
   </Paper>
  </Tooltip>
 );
};

SearchInput.propTypes = {
 className: PropTypes.string,
 onChange: PropTypes.func,
 style: PropTypes.object
};

export default SearchInput;
