import React, { useMemo, forwardRef } from 'react';

import { Divider, Grid, Typography } from '@material-ui/core';
import { format, addDays } from 'date-fns';

import { Buyer } from './Buyer';
import { Dates } from './Dates';
import { Emiter } from './Emiter';
import { Header } from './Header';
import { Pharma } from './Pharma';
import { Patient } from './Patient';
import useStyles from './styles';

import { contentGenerator } from '../contentGenerator';

const C1ModelToPrint = forwardRef((props, ref) => {
 const classes = useStyles();

 const {
  companyLogo,
  professionalFirstName,
  professionalLastName,
  professionalDocument,
  professionalSpecialty,
  professionalTitle,
  companyAddress,
  companyNumber,
  companyNeighborhood,
  companyCity,
  companyPhone,
  patientName,
  patientAge,
  patientAddress,
  patientAddressNumber,
  patientNeighborhood,
  patientCity,
  patientPostalCode,
  content
 } = props;

 const { today, thirtyDaysFromNow } = useMemo(() => {
  const today = new Date();
  const thirtyDaysFromNow = addDays(today, 30);

  return {
   today: format(today, 'dd/MM/yyyy'),
   thirtyDaysFromNow: format(thirtyDaysFromNow, 'dd/MM/yyyy')
  };
 }, []);

 return (
  <Grid container ref={ref} spacing={4} className={classes.root} direction="column" justifyContent="space-between">
   <Grid item container spacing={4} direction="column">
    <Grid item container>
     <Header logo={companyLogo} />
    </Grid>
    <Grid item container spacing={3} justifyContent="flex-end" alignItems="center">
     <Grid item xs={6}>
      <Emiter
       firstName={professionalFirstName}
       lastName={professionalLastName}
       document={professionalDocument}
       specialty={professionalSpecialty}
       title={professionalTitle}
       companyAddress={companyAddress}
       companyNumber={companyNumber}
       companyNeighborhood={companyNeighborhood}
       companyCity={companyCity}
       companyPhone={companyPhone}
      />
     </Grid>
     <Grid item xs={6}>
      <Dates today={today} thirtyDaysFromNow={thirtyDaysFromNow} />
     </Grid>
    </Grid>
    <Grid container item direction="column">
     <>
      <Patient
       patientName={patientName}
       patientAge={patientAge}
       patientAddress={patientAddress}
       patientAddressNumber={patientAddressNumber}
       patientNeighborhood={patientNeighborhood}
       patientCity={patientCity}
       patientPostalCode={patientPostalCode}
      />
      <Divider />
     </>
    </Grid>
    <Grid item container>
     {content?.split('\n')?.map(paragraph => {
      return contentGenerator(paragraph);
     })}
    </Grid>
   </Grid>
   <Grid item container spacing={4} direction="row" justifyContent="flex-end">
    <Grid item xs={6}>
     <Buyer />
    </Grid>
    <Grid item xs={6}>
     <Pharma />
    </Grid>
   </Grid>
  </Grid>
 );
});

export default C1ModelToPrint;
