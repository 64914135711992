import React, { useMemo } from 'react';

import { Card, CardContent, CardHeader, CardActions, Grid, Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Formik } from 'formik';

export default function AddExam(props) {
 const { prescription, handleSubmit, appointments, exams, cancel, componentsToPrint } = props;

 //  componentsToPrint is a object

 const refToPrint = useMemo(() => {
  if (prescription.type === 'SC') {
   return componentsToPrint.PrescriptionC1;
  }
  return componentsToPrint.PrescriptionCommon;
 }, [prescription, componentsToPrint]);

 const prescriptionLabel = useMemo(() => {
  if (prescription.type === 'SC') {
   return 'Receita de Controle Especial';
  }
  return 'Receita Comum';
 }, [prescription]);

 return (
  <Card>
   <Formik initialValues={prescription}>
    {props => {
     const { values, errors, handleBlur, setFieldValue, handleChange } = props;
     return (
      <>
       <CardHeader title={prescriptionLabel} />
       <CardContent>
        <Grid container spacing={3}>
         <Grid item md={12} xs={12}>
          <TextField
           fullWidth
           multiline
           rows={20}
           value={values?.text}
           autoComplete="on"
           label="Receita"
           margin="dense"
           onChange={handleChange}
           variant="outlined"
           id="text"
           name="text"
           error={Boolean(errors?.text)}
           onBlur={handleBlur}
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <Autocomplete
           options={appointments && appointments}
           getOptionLabel={appointment => `${appointment?.date} ${appointment?.time}`}
           getOptionSelected={(option, value) => option?.appointment_id === value?.appointment_id}
           value={values?.appointment_id}
           id="appointment_id"
           margin="dense"
           name="appointment_id"
           onChange={(e, v) => setFieldValue('appointment_id', v)}
           renderInput={params => (
            <TextField
             autoComplete="on"
             {...params}
             label="Vincular Atendimento"
             margin="dense"
             variant="outlined"
             id="appointment_id"
             name="appointment_id"
            />
           )}
          />
         </Grid>
         <Grid item md={6} xs={12}>
          <Autocomplete
           options={exams && exams}
           getOptionLabel={exam => `${exam?.exam_id}`}
           getOptionSelected={(option, value) => option?.exam_id === value?.exam_id}
           value={values?.exam_id}
           id="exam_id"
           margin="dense"
           name="exam_id"
           onChange={(e, v) => setFieldValue('exam_id', v)}
           renderInput={params => (
            <TextField
             autoComplete="on"
             {...params}
             label="Vincular Exame"
             margin="dense"
             variant="outlined"
             id="exam_id"
             name="exam_id"
            />
           )}
          />
         </Grid>
        </Grid>
       </CardContent>
       <CardActions>
        <Grid item container spacing={1}>
         <Grid item>
          <Button
           type="submit"
           color="primary"
           variant="contained"
           disabled={!values?.text}
           onClick={() => handleSubmit(values, true, refToPrint)}>
           Salvar e Imprimir
          </Button>
         </Grid>
        </Grid>
        <Grid item>
         <Button type="submit" color="primary" variant="outlined" onClick={() => cancel()}>
          Cancelar
         </Button>
        </Grid>
       </CardActions>
      </>
     );
    }}
   </Formik>
  </Card>
 );
}
