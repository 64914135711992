import api from '~/services/api';
import history from '~/services/history';
import * as AlertActions from '~/store/modules/alert/actions';
import * as LoadingActions from '~/store/modules/loading/actions';
import { takeLatest, call, put, all } from 'redux-saga/effects';

import { setClientesSuccess, setLoading } from './actions';

export function* setClientes() {
 yield put(LoadingActions.setLoading());

 const res = yield call(api.get, `customers`);

 try {
  yield put(setClientesSuccess(res.data.results));
 } catch (error) {}
 yield put(LoadingActions.closeLoading());
}

export function* addClientes({ payload }) {
 yield put(setLoading(true));
 const { data } = payload;

 try {
  yield call(api.post, 'customers', data);
  yield put(AlertActions.success('Paciente cadastrado com sucesso!'));

  yield put(setLoading(false));

  yield history.push('/pacientes');
 } catch (error) {
  yield put(AlertActions.error('Erro ao cadastrar novo paciente!'));
  yield put(setLoading(false));
 }
}

export function* editCliente({ payload }) {
 yield put(setLoading(true));
 try {
  let { cliente } = payload;

  delete cliente.avatar;

  cliente = {
   ...cliente,
   number_address: cliente.number_address || 0
  };

  yield call(api.put, `customers/${cliente.customer_id}`, cliente);
  yield put(AlertActions.success('Paciente editado com sucesso!'));
  yield history.push('/pacientes');
  yield put(setLoading(false));
 } catch (error) {
  yield put(AlertActions.error('Erro ao editar paciente!'));
  yield put(setLoading(false));
 }
}

function* setAvatar({ payload }) {
 try {
  yield put(LoadingActions.setLoading());

  const { avatar, customer_id } = payload;

  const newAvatar = new FormData();
  newAvatar.append('avatar', avatar[0]);

  const res = yield call(api.patch, `customers/${customer_id}`, newAvatar);

  yield put(AlertActions.success('Imagem alterada com sucesso!'));
 } catch (error) {
  yield put(AlertActions.error('Erro ao alterar imagem.'));
 }

 yield put(LoadingActions.closeLoading());
}

export default all([
 takeLatest('@clientes/SET_CLIENTE_REQUEST', setClientes),
 takeLatest('@clientes/ADD_CLIENTES_REQUEST', addClientes),
 takeLatest('@clientes/EDIT_CLIENTE_REQUEST', editCliente),
 takeLatest('@clientes/SET_AVATAR_CLIENTE', setAvatar)
]);
