import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/styles';
import HistoricoList from '~/components/HistoricoList';
import history from '~/services/history';
import * as HistoricoActions from '~/store/modules/historico/actions';
import * as LoadingActions from '~/store/modules/loading/actions';

import api from '../../services/api';
import Cliente from './Cliente/index';

const useStyles = makeStyles(theme => ({
 root: {
  padding: theme.spacing(3)
 },
 content: {
  marginTop: theme.spacing(2)
 },
 pagination: {}
}));

function Avaliacoes() {
 const { id_cliente } = useParams();
 const match = useRouteMatch();
 const dispatch = useDispatch();
 const [appointments, setAppointments] = useState([]);
 const [pagination, setPagination] = useState(1);
 const [activePage, setActivePage] = useState(1);
 const [totalProntuarios, setTotalProntuarios] = useState(0);
 const [customer, setCustomer] = useState({});

 const classes = useStyles();

 useEffect(() => {
  async function loadCustomerAppointments() {
   dispatch(LoadingActions.setLoading());
   const res = await api.get(`/customers/${id_cliente}/appointments`, {
    params: {
     page: activePage
    }
   });
   let qtyPages = 1;
   if (res.data.count / 10 > 0) {
    qtyPages = res.data.count / 10 + 1;
   }
   setTotalProntuarios(res.data.count);
   setPagination(~~qtyPages);
   setAppointments(res.data.results);
   dispatch(LoadingActions.closeLoading());
  }
  loadCustomerAppointments();
 }, [id_cliente, activePage]);

 useEffect(() => {
  async function loadCustomer() {
   const res = await api.get(`/customers/${id_cliente}`);

   setCustomer(res.data);
  }
  loadCustomer();
 }, [id_cliente]);

 function handleEditarAvaliacao(id_agendamento) {
  history.push(`${match.url}/${id_agendamento}`);
 }

 const handleChangePage = (event, value) => {
  window.scrollTo(0, 0);
  setActivePage(value);
 };

 const handleOpenFoto = useCallback((listImages, file) => {
  dispatch(HistoricoActions.setOpenFotoRequest(listImages, file));
 }, []);

 return (
  <div className={classes.root}>
   <Cliente total={totalProntuarios} customer={customer} labelText="prontuários" />
   <div className={classes.content}>
    <Grid container spacing={2} justify="center">
     {appointments
      .filter(appointment => Date.parse(appointment.datetime) <= Date.now())
      .map(historico => {
       return (
        <Grid item key={historico.appointment_id}>
         <HistoricoList
          historico={historico}
          handleEditarAvaliacao={handleEditarAvaliacao}
          handleOpenFoto={handleOpenFoto}
         />
        </Grid>
       );
      })}
     <Grid item className={classes.pagination}>
      {totalProntuarios !== 0 ? (
       <Pagination onChange={handleChangePage} count={pagination} color="primary" />
      ) : (
       <Typography variant="h2" color="textPrimary">
        Não há prontuários para esse paciente.
       </Typography>
      )}
     </Grid>
    </Grid>
   </div>
  </div>
 );
}

export default Avaliacoes;
