import produce from 'immer';

const INITIAL_STATE = {
 historicoAtivo: [],
 historicoList: [],
 loading: false,
 openFoto: {
  open: false,
  index: null,
  caption: '',
  title: ''
 }
};

export default function historico(state = INITIAL_STATE, action) {
 return produce(state, draft => {
  switch (action.type) {
   case '@historico/SET_HISTORICO_ATIVO_SUCCESS': {
    draft.historicoAtivo = action.payload.historico;
    break;
   }

   case '@historico/ADD_HISTORICO_SUCCESS': {
    draft.historicoAtivo.unshift(action.payload.historico);
    break;
   }

   case '@historico/LOADING': {
    draft.loading = action.payload.status;
    break;
   }

   case '@historico/OPEN_FOTO_SUCCESS': {
    draft.openFoto = action.payload.openFoto;
    draft.historicoAtivo = action.payload.listImages;
    break;
   }

   case '@historico/CLOSE_FOTO': {
    draft.openFoto = { open: false };
    break;
   }

   case '@historico/SET_HISTORICO_LIST_SUCCESS': {
    draft.historicoList = action.payload.historicos;
    break;
   }

   default:
    return state;
  }
 });
}
