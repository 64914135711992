import React, { useState } from 'react';

import { Fab, Tooltip, useMediaQuery } from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { makeStyles, useTheme } from '@material-ui/styles';
import { whatsappUrl } from '~/common/whatsappUrl';
import PropTypes from 'prop-types';

import { MenuTopbarMobile, Topbar } from './components';
import TopbarMobile from './components/TopbarMobile/TopbarMobile';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(() => ({
 root: {
  paddingTop: 64,
  height: '100%'
 },
 content: {
  height: '100%'
 }
}));

const Minimal = props => {
 const { children } = props;
 const classes = useStyles();
 const theme = useTheme();

 const [anchorEl, setAnchorEl] = useState(null);

 const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
  defaultMatches: true
 });

 const open = Boolean(anchorEl);
 const handleOpenMenuMobile = event => {
  setAnchorEl(event.currentTarget);
 };
 const handleCloseMenuMobile = () => {
  setAnchorEl(null);
 };

 return (
  <div className={classes.root}>
   {process.env.REACT_APP_SANDBOX == 'true' && (
    <Alert severity="error">VOCÊ ESTÁ UTILIZANDO UMA VERSÃO DE TESTE, NÃO UTILIZE DADOS REAIS</Alert>
   )}
   {isDesktop ? (
    <Topbar />
   ) : (
    <>
     <TopbarMobile handleOpen={handleOpenMenuMobile} />
     <MenuTopbarMobile handleClose={handleCloseMenuMobile} anchorEl={anchorEl} open={open} />
    </>
   )}
   <main className={classes.content}>{children}</main>
   <Tooltip title="Entre em contato conosco">
    <Fab
     style={{
      position: 'fixed',
      bottom: 16,
      right: 16,
      background: '#25d366'
     }}
     color="primary"
     aria-label="add"
     href={whatsappUrl}
     target="_blank">
     <WhatsAppIcon />
    </Fab>
   </Tooltip>
  </div>
 );
};

Minimal.propTypes = {
 children: PropTypes.node,
 className: PropTypes.string
};

export default Minimal;
