import PerfectScrollBar from 'react-perfect-scrollbar';

import styled, { css } from 'styled-components';

export const Container = styled.div`
 position: relative;
`;

export const NotificationList = styled.div`
 position: absolute;
 width: 260px;
 left: calc(50% - 230px);
 top: calc(100% + 30px);
 background: rgba(0, 0, 0, 0.6);
 border-radius: 4px;
 padding: 15px 5px;
 display: ${props => (props.visible ? 'block' : 'none')};

 &::before {
  content: '';
  position: absolute;
  left: calc(80% - 22px);
  top: -20px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid rgba(0, 0, 0, 0.6);
 }
`;

export const Notification = styled.div`
 color: #fff;

 & + div {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
 }

 p {
  font-size: 13px;
  line-height: 18px;
 }

 time {
  font-size: 12px;
  opacity: 0.6;
 }

 button {
  font-size: 12px;
  border: 0;
  background: none;
  color: #c89ffc;
  padding: 0 5px;
  margin: 0 5px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
 }

 ${props =>
  !props.lido &&
  css`
   &::after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #ff892e;
    border-radius: 50%;
   }
  `}
`;

export const Scroll = styled(PerfectScrollBar)`
 max-height: 260px;
 padding: 5px 15px;
`;
