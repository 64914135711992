import React from 'react';

import { Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import logo from '~/assets/logo-clinica.png';
import { Formik } from 'formik';
import * as yup from 'yup';

const schema = yup.object().shape({
 email: yup
  .string()
  .email()
  .required('Required field.')
});

const useStyles = makeStyles(theme => ({
 root: {
  backgroundColor: theme.palette.background.default,
  height: '100%'
 },
 grid: {
  height: '100%'
 },
 quoteContainer: {
  [theme.breakpoints.down('md')]: {
   display: 'none'
  }
 },
 quote: {
  backgroundColor: theme.palette.neutral,
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: `url(${logo})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center'
 },
 quoteInner: {
  textAlign: 'center',
  flexBasis: '600px'
 },
 quoteText: {
  color: theme.palette.white,
  fontWeight: 300
 },
 name: {
  marginTop: theme.spacing(3),
  color: theme.palette.white
 },
 bio: {
  color: theme.palette.white
 },
 contentContainer: {},
 content: {
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
 },
 contentHeader: {
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(5),
  paddingBototm: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2)
 },
 logoImage: {
  marginLeft: theme.spacing(4)
 },
 contentBody: {
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
   justifyContent: 'center'
  }
 },
 form: {
  paddingLeft: 100,
  paddingRight: 100,
  paddingBottom: 125,
  flexBasis: 700,
  [theme.breakpoints.down('sm')]: {
   paddingLeft: theme.spacing(2),
   paddingRight: theme.spacing(2)
  }
 },
 title: {
  marginTop: theme.spacing(3)
 },
 socialButtons: {
  marginTop: theme.spacing(3)
 },
 socialIcon: {
  marginRight: theme.spacing(1)
 },
 sugestion: {
  marginTop: theme.spacing(2)
 },
 textField: {
  marginTop: theme.spacing(2)
 },
 signInButton: {
  margin: theme.spacing(2, 0)
 }
}));

export function LoginOrder({ handleSubmit }) {
 const classes = useStyles();
 return (
  <Formik
   initialValues={{
    email: ''
   }}
   onSubmit={handleSubmit}
   validationSchema={schema}>
   {props => {
    const {
     values: { email },
     errors,
     touched,
     handleBlur,
     handleChange,
     handleSubmit
    } = props;
    return (
     <form className={classes.form} onSubmit={handleSubmit}>
      <Typography className={classes.title} variant="h5">
       Validar e-mail
      </Typography>
      <TextField
       className={classes.textField}
       onChange={handleChange}
       onBlur={handleBlur}
       value={email}
       error={errors.email && touched.email}
       helperText={touched.email && errors.email && 'Digite um e-mail válido'}
       variant="outlined"
       margin="normal"
       required
       fullWidth
       id="email"
       label="E-mail"
       name="email"
       autoComplete="email"
       autoFocus
      />
      <Button className={classes.signInButton} color="primary" fullWidth size="large" type="submit" variant="contained">
       Validar
      </Button>
     </form>
    );
   }}
  </Formik>
 );
}
