export function setPlan(plan) {
 return {
  type: '@order/SET_PLAN',
  payload: {
   plan
  }
 };
}

export function setOrder(orderData) {
 return {
  type: '@order/SET_ORDER',
  payload: {
   orderData
  }
 };
}

export function setModal(bool) {
 return {
  type: '@order/SET_MODAL',
  payload: {
   bool
  }
 };
}

export function setActiveOrder(activeOrder) {
 return {
  type: '@order/SET_ACTIVE_ORDER',
  payload: {
   activeOrder
  }
 };
}

export function setAffiliate(affiliate) {
 return {
  type: '@register/SET_AFFILIATE',
  payload: {
   affiliate
  }
 };
}
