import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
 Typography,
 Grid,
 Divider,
 IconButton,
 Avatar,
 Accordion,
 AccordionSummary,
 AccordionDetails,
 Menu,
 MenuItem,
 FormControl,
 Tooltip
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UpdateAt from '~/components/UpdateAt';
import { maskDate } from '~/helpers';
import getInitials from '~/helpers/getInitials';
import history from '~/services/history';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './styles';

const ListAgenda = props => {
 const { className, agendamento, onSubmit, viewCliente, appointmentStatus, hadleAppointmentStatus, ...rest } = props;
 const { loading } = useSelector(state => state.agenda);

 const classes = useStyles();

 const [anchorEl, setAnchorEl] = useState(null);
 const [anchorElCancel, setAnchorElCancel] = useState(null);

 const handleClick = event => {
  setAnchorEl(event.currentTarget);
 };

 const handleClickCancel = event => {
  setAnchorElCancel(event.currentTarget);
 };

 const handleClose = () => {
  setAnchorEl(null);
 };

 const handleCloseCancel = () => {
  setAnchorElCancel(null);
 };

 const setAppointmentStatus = useCallback((e, appointment_id) => {
  hadleAppointmentStatus(e.target.value, appointment_id);
  handleClose();
 }, []);

 function handleEditarAgendamento(appointment) {
  history.push({
   pathname: `agenda/editar/${appointment.appointment_id}`,
   appointmentEdited: appointment
  });
 }

 return (
  <Accordion
   {...rest}
   className={clsx(classes.root, className)}
   style={{
    opacity: agendamento.appointment_id && agendamento.past ? 0.3 : 1,
    background: agendamento.appointment_id && '#F2F2F2'
   }}>
   <AccordionSummary
    className={classes.accordionSummary}
    classes={{
     expandIcon: classes.expandIcon,
     expanded: classes.expanded
    }}
    IconButtonProps={{
     disableRipple: true
    }}
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1a-content"
    id="panel1a-header">
    <Grid container direction="row" alignItems="center">
     <Grid item container xs={12} md={2} alignItems="center">
      <Grid item>
       <span className={classes.horario}>{agendamento.time}</span>
       <Divider orientation="vertical" flexItem light />
      </Grid>
      <Grid item>
       <Tooltip title="Acessar detalhes do paciente" arrow placement="top-start">
        <Avatar
         style={{ zIndex: 1000 }}
         onClick={() => viewCliente(agendamento.customer_id.customer_id)}
         className={classes.avatar}
         src={agendamento.customer_id.avatar}>
         {getInitials(agendamento.customer_id.name)}
        </Avatar>
       </Tooltip>
      </Grid>
     </Grid>
     <Grid item container direction="row" xs={12} md={10}>
      <Grid item container direction="column" justify="space-between" xs={6}>
       <Grid item>
        <Typography className={classes.nomeClente}>{agendamento.customer_id.name}</Typography>
       </Grid>
       <Grid item>
        <Typography className={classes.age}>{agendamento.customer_id.age}</Typography>
       </Grid>
       <Grid item container direction="row" alignItems="center" className={classes.tipoConsulta}>
        <Grid item>
         <FiberManualRecordIcon style={{ color: `${agendamento.status.color}` }} />
        </Grid>
        <Grid item>
         <Typography>
          {agendamento.status.name} - {agendamento.type.name}
         </Typography>
        </Grid>
       </Grid>
      </Grid>
      <Grid container item xs={12} md={2} direction="column" justify="center">
       {agendamento?.customer_id?.phone && (
        <>
         <Grid item>
          <Typography color="textPrimary" variant="p" style={{ fontSize: 10 }}>
           Contato
          </Typography>
         </Grid>
         <Grid item>
          <Typography color="textSecondary" variant="h5">
           {maskDate(agendamento?.customer_id?.phone)}
          </Typography>
         </Grid>
        </>
       )}
      </Grid>
      <Grid container item xs={12} md={2} direction="column" justify="center" alignItems="flex-start">
       <Grid item>
        <Typography color="textPrimary" variant="p" style={{ fontSize: 10 }}>
         Convênio
        </Typography>
       </Grid>
       <Grid item>
        <Typography color="textSecondary" variant="h5">
         {agendamento.healthplan_id.name}
        </Typography>
       </Grid>
      </Grid>
      <Grid item container direction="row" alignItems="center" justify="space-between" xs={2}>
       <Grid item>
        <Tooltip title="Alterar status" arrow placement="top-start">
         <FormControl
          aria-label="Acknowledge"
          onClick={event => event.stopPropagation()}
          onFocus={event => event.stopPropagation()}>
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
           {appointmentStatus.map(status => (
            <MenuItem
             key={status.appointment_status_id}
             value={status.appointment_status_id}
             style={{ color: `${status.color}` }}
             onClick={e => setAppointmentStatus(e, agendamento.appointment_id)}>
             {status.name}
            </MenuItem>
           ))}
          </Menu>
          <IconButton onClick={handleClick} size="small">
           <MoreVertIcon />
          </IconButton>
         </FormControl>
        </Tooltip>
       </Grid>
       <Grid item>
        <Tooltip title="Editar agendamento" arrow placement="top-start">
         <IconButton
          style={{ opacity: 1 }}
          onClick={() => handleEditarAgendamento(agendamento)}
          size="small"
          className={classes.icons}>
          <EventIcon color="primary" />
         </IconButton>
        </Tooltip>
       </Grid>
       <Grid item>
        {/* <Tooltip title="Cancelar agendamento" arrow placement="top-start">
              <FormControl
                  aria-label="Acknowledge"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                >
                  <Menu
                    id="simple-menu-cancel"
                    anchorEl={anchorElCancel}
                    keepMounted
                    open={Boolean(anchorElCancel)}
                    onClose={handleCloseCancel}
                    
                  >
                    <MenuItem 
                      onClick={() => cancelAppointment(agendamento.appointment_id)}
                    >
                      Confimar
                    </MenuItem>
                  </Menu>
                <IconButton
                  onClick={handleClickCancel}
                  disabled={agendamento.past ? true : false}
                  size="small"
                  className={classes.icons}>
                  <EventBusyIcon style={{ color: '#F44336' }} />
                </IconButton>
                </FormControl>
                </Tooltip> */}
       </Grid>
      </Grid>
     </Grid>
    </Grid>
   </AccordionSummary>
   <AccordionDetails>
    <Grid container>
     {agendamento?.customer_id?.legal_guardians.length !== 0 && (
      <Grid container item alignContent="center" alignItems="center">
       <Grid container item>
        <Typography variant="h6">Contato dos responsáveis:</Typography>
       </Grid>
       {agendamento?.customer_id?.legal_guardians?.map(guardian => (
        <>
         <PhoneLegalGuardian legal_guardian={guardian} />
        </>
       ))}
      </Grid>
     )}
     {agendamento.note && (
      <Grid item>
       <Grid container item>
        <Typography variant="h6">Observações:</Typography>
       </Grid>
       <Grid item style={{ paddingLeft: 20 }}>
        <Typography>{agendamento.note}</Typography>
       </Grid>
      </Grid>
     )}
    </Grid>
   </AccordionDetails>
   <Grid container justify="flex-end">
    <Grid item>
     <UpdateAt
      hasUpdateBy={true}
      firstName={agendamento?.updated_by?.first_name}
      lastName={agendamento?.updated_by?.last_name}
      updateAt={agendamento?.updated_at}
     />
    </Grid>
   </Grid>
  </Accordion>
 );
};

ListAgenda.propTypes = {
 className: PropTypes.string,
 agendamento: PropTypes.object.isRequired
};

export default ListAgenda;

function PhoneLegalGuardian({ legal_guardian }) {
 return (
  <>
   <Grid container item xs={8} style={{ paddingLeft: 20 }}>
    <Grid item xs={12}>
     <Typography variant="caption">{legal_guardian?.kinship}</Typography>
    </Grid>
    <Grid item xs={12}>
     <Typography gutterBottom variant="subtitle1">
      {legal_guardian?.name}
     </Typography>
    </Grid>
   </Grid>
   <Grid container item xs={4}>
    <Grid item xs={12}>
     <Typography variant="caption">Contato</Typography>
    </Grid>
    <Grid item xs={12}>
     <Typography gutterBottom variant="subtitle1">
      {maskDate(legal_guardian?.phone)}
     </Typography>
    </Grid>
   </Grid>
  </>
 );
}
