import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Menu, MenuItem, Typography } from '@material-ui/core';

export default function MenuTopbarMobile({ anchorEl, open, handleClose }) {
 const openNewPage = url => {
  handleClose();
  window.open(url, '_blank');
 };

 return (
  <Menu
   id="basic-menu"
   anchorEl={anchorEl}
   open={open}
   onClose={handleClose}
   MenuListProps={{
    'aria-labelledby': 'basic-button'
   }}>
   <RouterLink to="/">
    <MenuItem onClick={handleClose}>
     <Typography variant="button">Entrar</Typography>
    </MenuItem>
   </RouterLink>
   <RouterLink to="/planos">
    <MenuItem onClick={handleClose}>
     <Typography variant="button">Planos</Typography>
    </MenuItem>
   </RouterLink>
   <MenuItem onClick={() => openNewPage('https://blog.bydoctor.com.br/')}>
    <Typography variant="button">Blog</Typography>
   </MenuItem>
   <MenuItem disabled>
    <Typography variant="button">Depoimentos</Typography>
   </MenuItem>
  </Menu>
 );
}
