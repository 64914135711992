import React from 'react';

import { Divider, Drawer } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import BarChartIcon from '@material-ui/icons/BarChart';
import BusinessIcon from '@material-ui/icons/Business';
import EventIcon from '@material-ui/icons/Event';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Profile, SidebarNav } from './components';

const useStyles = makeStyles(theme => ({
 drawer: {
  width: 240,
  [theme.breakpoints.up('lg')]: {
   marginTop: 64,
   height: 'calc(100% - 64px)'
  }
 },
 root: {
  backgroundColor: theme.palette.white,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: theme.spacing(2)
 },
 divider: {
  margin: theme.spacing(2, 0)
 },
 nav: {
  marginBottom: theme.spacing(2)
 }
}));

const Sidebar = props => {
 const { open, variant, onClose, className, ...rest } = props;

 const classes = useStyles();

 const pages = [
  // {
  //   title: 'Dashboard',
  //   href: '/dashboard',
  //   icon: <DashboardIcon />
  // },
  {
   title: 'Pacientes',
   href: '/pacientes',
   icon: <PeopleIcon />
  },
  {
   title: 'Agenda',
   href: '/agenda',
   icon: <EventIcon />
  },
  {
   title: 'Clínica',
   href: '/clinica',
   icon: <BusinessIcon />
  },
  {
   title: 'Insights',
   href: '/insights',
   icon: <BarChartIcon />,
   disabled: true
  },
  {
   title: 'Perfil',
   href: '/perfil',
   icon: <AccountBoxIcon />
  },
  {
   title: 'Configurações',
   href: '/settings',
   icon: <SettingsIcon />
  }
 ];

 return (
  <Drawer anchor="left" classes={{ paper: classes.drawer }} onClose={onClose} open={open} variant={variant}>
   <div {...rest} className={clsx(classes.root, className)}>
    <Profile />
    <Divider className={classes.divider} />
    <SidebarNav className={classes.nav} pages={pages} />
   </div>
  </Drawer>
 );
};

Sidebar.propTypes = {
 className: PropTypes.string,
 onClose: PropTypes.func,
 open: PropTypes.bool.isRequired,
 variant: PropTypes.string.isRequired
};

export default Sidebar;
