import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import * as LoadingActions from '~/store/modules/loading/actions';

import api from '../../services/api';
import { ClientesToolbar, ClientesTable } from './components';

const useStyles = makeStyles(theme => ({
 root: {
  padding: theme.spacing(3)
 },
 content: {
  marginTop: theme.spacing(2)
 }
}));

const UserList = () => {
 const classes = useStyles();
 const dispatch = useDispatch();
 const [filter, setFilter] = useState('');
 const [clientes, setClientes] = useState([]);
 const [rowsPerPage, setRowsPerPage] = useState(10);
 const [page, setPage] = useState(0);
 const [countCustomers, setCountCustomers] = useState(0);

 useEffect(() => {
  async function loadClientes() {
   dispatch(LoadingActions.setLoading());
   const res = await api.get('/customers', {
    params: {
     page_size: rowsPerPage,
     page: page + 1
    }
   });

   setCountCustomers(res.data.count);
   setClientes(res.data.results);
   dispatch(LoadingActions.closeLoading());
  }

  async function loadFilter() {
   dispatch(LoadingActions.setLoading());
   const res = await api.get('/customers', {
    params: {
     name__icontains: filter
    }
   });
   setCountCustomers(res.data.count);
   setClientes(res.data.results);
   dispatch(LoadingActions.closeLoading());
  }

  if (filter.length >= 2) {
   loadFilter();
  } else if (filter.length === 0) {
   loadClientes();
  }
 }, [page, rowsPerPage, filter]);

 function handleChangeFilter(event) {
  if (event.key === 'Enter') {
   setFilter(event.target.value.toLowerCase());
  }
 }

 const handlePageChange = (event, page) => {
  setPage(page);
 };

 const handleRowsPerPageChange = event => {
  setRowsPerPage(event.target.value);
 };

 const handleClearFilter = useCallback(() => {
  setFilter('');
 }, []);

 return (
  <div className={classes.root}>
   <ClientesToolbar onChange={handleChangeFilter} filter={filter} onClearFilter={handleClearFilter} />
   <div className={classes.content}>
    <ClientesTable
     filter={filter}
     users={clientes}
     rowsPerPage={rowsPerPage}
     page={page}
     handlePageChange={handlePageChange}
     handleRowsPerPageChange={handleRowsPerPageChange}
     countCustomers={countCustomers}
    />
   </div>
  </div>
 );
};

export default UserList;
