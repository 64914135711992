/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink as RouterLink } from 'react-router-dom';

import { ListItem, Button, colors, Grid, Divider } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { makeStyles } from '@material-ui/styles';
import { whatsappUrl } from '~/common/whatsappUrl';
import * as AuthActions from '~/store/modules/auth/actions';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
 root: {
  height: '100%'
 },
 item: {
  display: 'flex',
  paddingTop: 0,
  paddingBottom: 0
 },
 button: {
  color: colors.blueGrey[800],
  padding: '10px 8px',
  justifyContent: 'flex-start',
  textTransform: 'none',
  letterSpacing: 0,
  width: '100%',
  fontWeight: theme.typography.fontWeightMedium
 },
 icon: {
  color: theme.palette.icon,
  width: 24,
  height: 24,
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1)
 },
 active: {
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightMedium,
  '& $icon': {
   color: theme.palette.primary.main
  }
 }
}));

const CustomRouterLink = forwardRef((props, ref) => (
 <div ref={ref} style={{ flexGrow: 1 }}>
  <RouterLink {...props} />
 </div>
));

const SidebarNav = props => {
 const { pages, className, ...rest } = props;
 const dispatch = useDispatch();

 const classes = useStyles();

 return (
  <Grid container justify="space-between" direction="column" {...rest} className={clsx(classes.root, className)}>
   <Grid item>
    {pages.map(page => (
     <ListItem className={classes.item} disableGutters key={page.title}>
      <Button
       disabled={page?.disabled}
       activeClassName={classes.active}
       className={classes.button}
       component={CustomRouterLink}
       to={page.href}>
       <div className={classes.icon}>{page.icon}</div>
       {page.title}
      </Button>
     </ListItem>
    ))}
   </Grid>
   <Grid item>
    <ListItem disableGutters>
     <Button className={classes.button} href={whatsappUrl} target="_blank">
      <div className={classes.icon}>
       <WhatsAppIcon />
      </div>
      Suporte
     </Button>
    </ListItem>
    <Divider />
    <ListItem disableGutters>
     <Button
      // activeClassName={classes.active}
      className={classes.button}
      onClick={() => dispatch(AuthActions.signOut())}>
      <div className={classes.icon}>
       <ExitToAppIcon />
      </div>
      Sair
     </Button>
    </ListItem>
   </Grid>
  </Grid>
 );
};

SidebarNav.propTypes = {
 className: PropTypes.string,
 pages: PropTypes.array.isRequired
};

export default SidebarNav;
