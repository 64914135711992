import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
 contentContainer: {},
 content: {
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
 },
 contentHeader: {
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(5),
  paddingBototm: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2)
 },
 contentBody: {
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
   justifyContent: 'center'
  }
 },
 form: {
  paddingLeft: 100,
  paddingRight: 100,
  paddingBottom: 125,
  flexBasis: 700,
  [theme.breakpoints.down('sm')]: {
   paddingLeft: theme.spacing(2),
   paddingRight: theme.spacing(2)
  }
 },
 title: {
  marginTop: theme.spacing(3)
 },
 textField: {
  marginTop: theme.spacing(2)
 },
 signUpButton: {
  margin: theme.spacing(2, 0)
 },
 buttonProgress: {
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12
 }
}));

export default useStyles;
