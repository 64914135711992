export function setAgendamentoAtivo(data) {
 return {
  type: '@agenda/SET_AGENDAMENTO_ATIVO',
  payload: {
   data
  }
 };
}

export function setDataAgendamento(data) {
 return {
  type: '@agenda/SET_DATA_AGENDAMENTO',
  payload: {
   data
  }
 };
}

export function cancelAgendamentoRequest(id) {
 return {
  type: '@agenda/CANCEL_AGENDAMENTO_REQUEST',
  payload: {
   id
  }
 };
}

export function cancelAgendamentoSuccess(id) {
 return {
  type: '@agenda/CANCEL_AGENDAMENTO_SUCCESS',
  payload: {
   id
  }
 };
}

export function addAgen(data) {
 return {
  type: '@agenda/ADD_AGENDAMENTO',
  payload: {
   data
  }
 };
}

export function setIdAgendamentoAtivo(id) {
 return {
  type: '@agenda/SET_ID_AGENDAMENTO_ATIVO',
  payload: {
   id
  }
 };
}

export function editAgen(data) {
 return {
  type: '@agenda/EDIT_AGENDAMENTO',
  payload: {
   data
  }
 };
}

export function setChartRequest(filter) {
 return {
  type: '@agenda/SET_CHART_REQUEST',
  payload: {
   filter
  }
 };
}

export function setChartSuccess(ultimosAtendimentos, ultimosDias) {
 return {
  type: '@agenda/SET_CHART_SUCCESS',
  payload: {
   ultimosAtendimentos,
   ultimosDias
  }
 };
}

export function setLoading(status) {
 return {
  type: '@agenda/SET_LOADING',
  payload: {
   status
  }
 };
}

export function setAgendaRequest(date, id_user) {
 return {
  type: '@agenda/SET_AGENDA_REQUEST',
  payload: {
   date,
   id_user
  }
 };
}

export function setAgendaSuccess(agendamentos) {
 return {
  type: '@agenda/SET_AGENDA_SUCCESS',
  payload: {
   agendamentos
  }
 };
}

export function setHoraDisponivelRequest(date) {
 return {
  type: '@agenda/SET_HORADISPONIVEL_REQUEST',
  payload: {
   date
  }
 };
}

export function setHoraDisponivelSuccess(date) {
 return {
  type: '@agenda/SET_HORADISPONIVEL_SUCCESS',
  payload: {
   date
  }
 };
}

export function setAppointmentStatusRequest(status_id, appointment_id) {
 return {
  type: '@agenda/SET_APPOINTMENT_STATUS_REQUEST',
  payload: {
   status_id,
   appointment_id
  }
 };
}

export function setAppointmentStatusSuccess(appointment) {
 return {
  type: '@agenda/SET_APPOINTMENT_STATUS_SUCCESS',
  payload: {
   appointment
  }
 };
}

export function setProfessionalFilter(professional) {
 return {
  type: '@agenda/SET_PROFESSIONAL_FILTER',
  payload: {
   professional
  }
 };
}
