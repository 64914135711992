import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import { makeStyles } from '@material-ui/styles';
import logo from '~/assets/clinica.png';
import Notificacoes from '~/components/Notificacoes';
import * as AuthActions from '~/store/modules/auth/actions';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
 root: {
  boxShadow: 'none'
 },
 flexGrow: {
  flexGrow: 1
 },
 signOutButton: {
  marginLeft: theme.spacing(1)
 }
}));

const Topbar = props => {
 const dispatch = useDispatch();
 const { className, onSidebarOpen, notifications, lenNotifications, handleFinishedHistoric, ...rest } = props;
 const classes = useStyles();

 const [visible, setVisible] = useState(false);

 function handleToggleVisible() {
  if (lenNotifications === 0) {
   return;
  }
  setVisible(!visible);
 }

 useEffect(() => {
  if (lenNotifications === 0) {
   setVisible(false);
  }
 }, [lenNotifications]);

 return (
  <AppBar {...rest} className={clsx(classes.root, className)}>
   <Toolbar>
    <RouterLink to="/">
     <img alt="Logo" src={logo} />
    </RouterLink>
    <div className={classes.flexGrow} />
    <Hidden mdDown>
     <IconButton color="inherit" onClick={handleToggleVisible}>
      <Badge badgeContent={lenNotifications} max={9} color="secondary">
       <NotificationsIcon />
      </Badge>
     </IconButton>
     <Notificacoes visible={visible} notifications={notifications} handleFinishedHistoric={handleFinishedHistoric} />
     <IconButton className={classes.signOutButton} color="inherit" onClick={() => dispatch(AuthActions.signOut())}>
      <InputIcon />
     </IconButton>
    </Hidden>
    <Hidden lgUp>
     <IconButton color="inherit" onClick={onSidebarOpen}>
      <MenuIcon />
     </IconButton>
    </Hidden>
   </Toolbar>
  </AppBar>
 );
};

Topbar.propTypes = {
 className: PropTypes.string,
 onSidebarOpen: PropTypes.func
};

export default Topbar;
