import produce from 'immer';

const INITIAL_STATE = {
 profile: null,
 completePerfil: null,
 loading: false
};

export default function user(state = INITIAL_STATE, action) {
 return produce(state, draft => {
  switch (action.type) {
   case '@auth/SIGN_OUT': {
    draft.profile = null;
    break;
   }

   case '@user/SAVE_PROFILE_SUCCESS': {
    draft.profile = action.payload.profile;
    break;
   }

   case '@user/IMAGE_SUCCESS': {
    draft.profile.avatar = action.payload.avatar;
    draft.loading = false;
    break;
   }

   case '@user/LOADING': {
    draft.loading = action.payload.load;
    break;
   }

   default:
    return state;
  }
 });
}
