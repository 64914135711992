import React from 'react';

import { Grid, Typography, Divider } from '@material-ui/core';

import useStyles from './styles';

export function Buyer() {
 const classes = useStyles();

 return (
  <Grid container className={classes.container}>
   <Grid item xs={12}>
    <Typography className={classes.title} variant="body1" color="textPrimary">
     IDENTIFICAÇÃO DO COMPRADOR
    </Typography>
    <Divider />
   </Grid>
   <Grid item container xs={12}>
    <Grid item xs={12}>
     <Typography noWrap className={classes.info} variant="body2" color="textPrimary" component="p">
      Nome:___________________________________________________________________________________
     </Typography>
    </Grid>
    <Grid item xs={12}>
     <Typography noWrap className={classes.info} variant="body2" color="textPrimary" component="p">
      Ident:________________________________________Órg. Emissor:______________________
     </Typography>
    </Grid>
    <Grid item xs={12}>
     <Typography noWrap className={classes.info} variant="body2" color="textPrimary">
      End:____________________________________________________________________________________
     </Typography>
    </Grid>
    <Grid item xs={12}>
     <Typography noWrap className={classes.info} variant="body2" color="textPrimary">
      Cidade:______________________________________________UF:________________________________
     </Typography>
    </Grid>
    <Grid item xs={12}>
     <Typography noWrap className={classes.info} variant="body2" color="textPrimary">
      Telefone:(_________)____________________________________________________________________
     </Typography>
    </Grid>
   </Grid>
  </Grid>
 );
}
