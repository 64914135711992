import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector, useDispatch } from 'react-redux';

import {
 Card,
 CardActions,
 CardHeader,
 CardContent,
 Button,
 Divider,
 Table,
 TableBody,
 TableCell,
 TableHead,
 TableRow,
 Tooltip,
 TableSortLabel
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { makeStyles } from '@material-ui/styles';
import history from '~/services/history';
import * as AgendaActions from '~/store/modules/agenda/actions';
import clsx from 'clsx';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
 root: {},
 content: {
  padding: 0
 },
 inner: {
  minWidth: 800
 },
 statusContainer: {
  display: 'flex',
  alignItems: 'center'
 },
 status: {
  marginRight: theme.spacing(1)
 },
 actions: {
  justifyContent: 'flex-end'
 }
}));

// const statusColors = {
//   delivered: 'success',
//   pending: 'info',
//   refunded: 'danger'
// };

const UltimosAtendimentos = props => {
 const dispatch = useDispatch();
 const { ultimosAtendimentos } = useSelector(state => state.agenda);
 const { className, ...rest } = props;

 const classes = useStyles();

 function handleCriarAgendamento() {
  const data = {
   data_agendamento: format(new Date(), "Y'-'MM'-'dd"),
   hora_agendamento: null,
   cliente: null
  };

  dispatch(AgendaActions.setAgendamentoAtivo(data));
  history.push('/agenda/editar');
 }

 return (
  <Card {...rest} className={clsx(classes.root, className)}>
   <CardHeader
    action={
     <Button color="primary" size="small" variant="outlined" onClick={handleCriarAgendamento}>
      Novo Agendamento
     </Button>
    }
    title="Próximos Atendimentos"
   />
   <Divider />
   <CardContent className={classes.content}>
    <PerfectScrollbar>
     <div className={classes.inner}>
      <Table>
       <TableHead>
        <TableRow>
         <TableCell sortDirection="desc">
          <Tooltip enterDelay={300} title="Sort">
           <TableSortLabel active direction="desc">
            Data
           </TableSortLabel>
          </Tooltip>
         </TableCell>
         <TableCell>Horário</TableCell>
         <TableCell>Cliente</TableCell>
         <TableCell>Celular</TableCell>
         <TableCell></TableCell>
        </TableRow>
       </TableHead>
       <TableBody>
        {ultimosAtendimentos &&
         ultimosAtendimentos.map(atend => (
          <TableRow hover key={atend.id}>
           <TableCell>{atend.data}</TableCell>
           <TableCell>{atend.hora}</TableCell>
           <TableCell>{atend.customer.name}</TableCell>
           <TableCell>{atend.cel}</TableCell>
           <TableCell>
            {/* <div className={classes.statusContainer}>
                        <StatusBullet
                          className={classes.status}
                          color={statusColors[atend.status]}
                          size="sm"
                        />
                        {atend.status}
                      </div> */}
           </TableCell>
          </TableRow>
         ))}
       </TableBody>
      </Table>
     </div>
    </PerfectScrollbar>
   </CardContent>
   <Divider />
   <CardActions className={classes.actions}>
    <Button color="primary" size="small" variant="text" onClick={() => history.push('/agenda')}>
     Ver todos <ArrowRightIcon />
    </Button>
   </CardActions>
  </Card>
 );
};

UltimosAtendimentos.propTypes = {
 className: PropTypes.string
};

export default UltimosAtendimentos;
