import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';

import { Grid, Button, TextField, Typography, CircularProgress, Box } from '@material-ui/core';
import siteSeguro from '~/assets/site-seguro.png';
import cardValidator from '~/helpers/cardValidator';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import useStyles from '../styles';

const schema = yup.object().shape({
 first_name: yup.string().required('Required field.'),
 last_name: yup.string().required('Required field.'),
 email: yup
  .string()
  .email()
  .required('Required field.'),
 cpf: yup.string().required('Required field.'),
 birthday: yup.string().required('Required field.'),
 postal_code: yup.string().required('Required field.'),
 address: yup.string().required('Required field.'),
 number: yup.string().required('Required field.'),
 neighborhood: yup.string().required('Required field.'),
 city: yup.string().required('Required field.'),
 state: yup.string().required('Required field.'),
 card_number: yup.string().required('Required field.'),
 expiration: yup.string().required('Required field.'),
 card_cvv: yup.string().required('Required field.')
});

export function OrderPlan({ activeOrder, handleSubmit, setPostalCodeCEP, setActiveOrder }) {
 const { plan, loading, newRegister } = useSelector(state => state.order);

 useEffect(() => {
  function loadEffect() {
   setActiveOrder({
    ...activeOrder,
    first_name: newRegister?.first_name,
    last_name: newRegister?.last_name,
    email: newRegister?.email
   });
  }
  loadEffect();
 }, [newRegister]);

 const classes = useStyles();
 return (
  <Formik initialValues={activeOrder} onSubmit={handleSubmit} validationSchema={schema} enableReinitialize={true}>
   {props => {
    const { values, errors, touched, handleBlur, handleChange } = props;

    function handleChangeCep(postal_code) {
     handleChange(postal_code);
     setPostalCodeCEP(postal_code);
     setActiveOrder(values);
    }

    function handleCardValidator(card_number) {
     handleChange(card_number);
     if (card_number.length === 19) {
      const brand = cardValidator(card_number);
      setActiveOrder({
       ...values,
       brand: brand,
       card_number
      });
     }
    }

    return (
     <Form>
      <Grid item xs={12} lg={12} container spacing={5} justify="center" alignContent="center">
       <Grid item xs={12} md={4} xl={4}>
        <Typography className={classes.title} variant="h5">
         Confirmar dados pessoais
        </Typography>
        <TextField
         size="small"
         required
         className={classes.textField}
         fullWidth
         label="Primeiro Nome"
         name="first_name"
         onChange={handleChange}
         onBlur={handleBlur}
         type="text"
         value={values.first_name}
         variant="outlined"
         error={errors.first_name && touched.first_name}
        />
        <TextField
         size="small"
         required
         className={classes.textField}
         fullWidth
         label="Último Nome"
         name="last_name"
         onChange={handleChange}
         onBlur={handleBlur}
         type="text"
         value={values.last_name}
         variant="outlined"
         error={errors.last_name && touched.last_name}
        />
        <TextField
         size="small"
         required
         className={classes.textField}
         fullWidth
         label="Email"
         name="email"
         onChange={handleChange}
         onBlur={handleBlur}
         type="text"
         value={values.email}
         variant="outlined"
         error={errors.email && touched.email}
        />
        <InputMask
         mask="999.999.999-99"
         maskChar=" "
         onChange={handleChange}
         onBlur={handleBlur}
         name="cpf"
         type="text"
         value={values.cpf}>
         {() => (
          <TextField
           size="small"
           required
           className={classes.textField}
           fullWidth
           label="CPF"
           name="cpf"
           // onChange={handleChange}
           type="text"
           // value={values.cpf}
           variant="outlined"
           error={errors.cpf && touched.cpf}
          />
         )}
        </InputMask>
        <InputMask
         mask="99/99/9999"
         maskChar=" "
         onChange={handleChange}
         onBlur={handleBlur}
         type="text"
         value={values.birthday}>
         {() => (
          <TextField
           size="small"
           required
           className={classes.textField}
           fullWidth
           label="Data de nascimento"
           name="birthday"
           // onChange={handleChange}
           type="text"
           // value={values.birthday}
           variant="outlined"
           error={errors.birthday && touched.birthday}
          />
         )}
        </InputMask>
       </Grid>
       <Grid item xs={12} md={4} xl={4}>
        <Typography className={classes.title} variant="h5">
         Endereço
        </Typography>
        <TextField
         size="small"
         required
         className={classes.textField}
         fullWidth
         label="CEP"
         name="postal_code"
         onChange={e => handleChangeCep(e.target.value)}
         onBlur={handleBlur}
         type="text"
         value={values.postal_code}
         variant="outlined"
         error={errors.postal_code && touched.postal_code}
        />
        <TextField
         size="small"
         required
         className={classes.textField}
         fullWidth
         label="Rua / Avenida"
         name="address"
         onChange={handleChange}
         onBlur={handleBlur}
         type="text"
         value={values.address}
         variant="outlined"
         error={errors.address && touched.address}
        />
        <TextField
         size="small"
         required
         className={classes.textField}
         fullWidth
         label="Numero"
         name="number"
         onChange={handleChange}
         onBlur={handleBlur}
         type="text"
         value={values.number}
         variant="outlined"
         error={errors.number && touched.number}
        />
        <TextField
         size="small"
         required
         className={classes.textField}
         fullWidth
         label="Bairro"
         name="neighborhood"
         onChange={handleChange}
         onBlur={handleBlur}
         type="text"
         value={values.neighborhood}
         variant="outlined"
         error={errors.neighborhood && touched.neighborhood}
        />
        <TextField
         size="small"
         required
         className={classes.textField}
         fullWidth
         label="Cidade"
         name="city"
         onChange={handleChange}
         onBlur={handleBlur}
         type="text"
         value={values.city}
         variant="outlined"
         error={errors.city && touched.city}
        />
        <TextField
         size="small"
         required
         className={classes.textField}
         fullWidth
         label="Estado"
         name="state"
         onChange={handleChange}
         onBlur={handleBlur}
         type="text"
         value={values.state}
         variant="outlined"
         error={errors.state && touched.state}
         inputProps={{ maxLength: 2 }}
        />
       </Grid>
       <Grid item xs={12} md={4} xl={4}>
        <Typography className={classes.title} variant="h5">
         Cobrança
        </Typography>
        <InputMask
         mask="9999 9999 9999 9999"
         maskChar=" "
         onChange={e => handleCardValidator(e.target.value)}
         onBlur={handleBlur}
         type="text"
         value={values.card_number}>
         {() => (
          <TextField
           size="small"
           required
           className={classes.textField}
           fullWidth
           label="Número do cartão"
           name="card_number"
           // onChange={handleChange}
           type="text"
           // value={values.card_number}
           variant="outlined"
           error={errors.card_number && touched.card_number}
          />
         )}
        </InputMask>
        <Typography className={classes.title} variant="caption">
         {values?.brand}
        </Typography>
        <Grid item container spacing={3}>
         <Grid item xs={12} md={6}>
          <InputMask
           mask="99/9999"
           maskChar=" "
           onChange={handleChange}
           onBlur={handleBlur}
           type="text"
           value={values.expiration}>
           {() => (
            <TextField
             size="small"
             required
             className={classes.textField}
             fullWidth
             label="MM/AAAA"
             name="expiration"
             // onChange={handleChange}
             type="text"
             // value={values.expiration}
             variant="outlined"
             error={errors.expiration && touched.expiration}
            />
           )}
          </InputMask>
         </Grid>
         <Grid item xs={12} md={6}>
          <TextField
           size="small"
           required
           className={classes.textField}
           fullWidth
           label="CVV"
           name="card_cvv"
           onChange={handleChange}
           onBlur={handleBlur}
           type="text"
           value={values.card_cvv}
           variant="outlined"
           error={errors.card_cvv && touched.card_cvv}
           inputProps={{ maxLength: 4 }}
          />
         </Grid>
         <Grid item xs={12} md={12}>
          <Typography
           component="h3"
           variant="subtitle1"
           style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'baseline'
           }}>
           Pagando somente:
          </Typography>
          <Box
           style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'baseline',
            mb: 2
           }}>
           <Typography component="h1" variant="h1" color="text.primary">
            R${plan?.value}
           </Typography>
           <Typography variant="h6" color="text.secondary">
            /mês
           </Typography>
          </Box>
          <Typography variant="caption" align="center" color="textPrimary" component="p">
           Você pode cancelar a <span style={{ fontWeight: 'bold' }}>qualquer</span> momento.
          </Typography>
         </Grid>
         <Grid item>
          <img src={siteSeguro} alt="Site Seguro." />
         </Grid>
        </Grid>
       </Grid>
       <Button
        className={classes.signUpButton}
        color="primary"
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        disabled={!(values.card_number && values.card_cvv && values.expiration)}>
        Assinar agora
        {loading && <CircularProgress size={24} className={classes.buttonProgress} color="secondary" />}
       </Button>
      </Grid>
     </Form>
    );
   }}
  </Formik>
 );
}
