import api from '~/services/api';
import history from '~/services/history';
import * as AlertActions from '~/store/modules/alert/actions';
import * as LoadingActions from '~/store/modules/loading/actions';
import { takeLatest, call, put, all, select, delay } from 'redux-saga/effects';

import { setActiveOrder, setModal } from './actions';

export function* createOrder({ payload }) {
 yield put(LoadingActions.setLoading());
 const { orderData } = payload;
 const { order } = yield select();

 const newOrder = {
  company_id: order.newRegister.company_id,
  user: {
   user_id: order.newRegister.id_user,
   address: orderData.address,
   number: orderData.number,
   neighborhood: orderData.neighborhood,
   postal_code: orderData.postal_code,
   city: orderData.city,
   state: orderData.state,
   first_name: orderData.first_name,
   last_name: orderData.last_name,
   email: orderData.email,
   cpf: orderData.cpf,
   birthday: orderData.birthday,
   phone_number: order.newRegister.phone,
   brand: orderData.brand,
   card_number: orderData.card_number,
   card_cvv: orderData.card_cvv,
   expiration: orderData.expiration
  },
  plan: {
   plan_id: order.plan.plan_id
  }
 };

 try {
  const res = yield call(api.post, `orders`, newOrder);

  yield put(setActiveOrder(res.data));
  yield put(LoadingActions.closeLoading());
  yield put(setModal(true));
  yield delay(15000);
  history.push('/entrar');
  yield put(setModal(false));
  // yield put(AlertActions.success('Plano assinado com sucesso'));
 } catch (error) {
  yield put(LoadingActions.closeLoading());
  yield put(AlertActions.error('Erro ao tentar assinar plano. Tente novamente.'));
 }
}

export default all([takeLatest('@order/SET_ORDER', createOrder)]);
