import { combineReducers } from 'redux';

import agenda from './agenda/reducer';
import alert from './alert/reducer';
import auth from './auth/reducer';
import clientes from './clientes/reducer';
import global from './global/reducer';
import header from './header/reducer';
import historico from './historico/reducer';
import loading from './loading/reducer';
import order from './order/reducer';
import register from './register/reducer';
import user from './user/reducer';

export default combineReducers({
 user,
 loading,
 auth,
 agenda,
 alert,
 header,
 clientes,
 historico,
 register,
 global,
 order
});
