import { all } from 'redux-saga/effects';

import agenda from './agenda/sagas';
import auth from './auth/sagas';
import clientes from './clientes/sagas';
import global from './global/sagas';
import historico from './historico/sagas';
import order from './order/sagas';
import register from './register/sagas';
import user from './user/sagas';

export default function* rootSaga() {
 return yield all([user, auth, agenda, clientes, historico, register, global, order]);
}
