export function setHistoricoAtivoRequest(id_agendamento) {
 return {
  type: '@historico/SET_HISTORICO_ATIVO_REQUEST',
  payload: {
   id_agendamento
  }
 };
}

export function setHistoricoAtivoSuccess(historico) {
 return {
  type: '@historico/SET_HISTORICO_ATIVO_SUCCESS',
  payload: {
   historico
  }
 };
}

export function addHistoricoRequest(data) {
 return {
  type: '@historico/ADD_HISTORICO_REQUEST',
  payload: {
   data
  }
 };
}

export function editHistoricoRequest(historico) {
 return {
  type: '@historico/EDIT_HISTORICO_REQUEST',
  payload: {
   historico
  }
 };
}

export function editHistoricoSuccess(historico) {
 return {
  type: '@historico/EDIT_HISTORICO_SUCCESS',
  payload: {
   historico
  }
 };
}

export function addExamRequest(exam) {
 return {
  type: '@historico/ADD_EXAM_REQUEST',
  payload: {
   exam
  }
 };
}

export function editExamRequest(exam) {
 return {
  type: '@historico/EDIT_EXAM_REQUEST',
  payload: {
   exam
  }
 };
}

export function editExamSuccess(exam) {
 return {
  type: '@historico/EDIT_EXAM_SUCCESS',
  payload: {
   exam
  }
 };
}

export function setLoading(status) {
 return {
  type: '@historico/LOADING',
  payload: {
   status
  }
 };
}

export function setOpenFotoRequest(listImages, index) {
 return {
  type: '@historico/OPEN_FOTO_REQUEST',
  payload: {
   listImages,
   index
  }
 };
}

export function setOpenFotoSuccess(openFoto, listImages) {
 return {
  type: '@historico/OPEN_FOTO_SUCCESS',
  payload: {
   openFoto,
   listImages
  }
 };
}

export function closeFoto() {
 return {
  type: '@historico/CLOSE_FOTO'
 };
}

export function finishedHistoricRequest(historic_id) {
 return {
  type: '@historico/FINISHED_HISTORIC_REQUEST',
  payload: {
   historic_id
  }
 };
}

export function finishedHistoricSuccess(historic_id) {
 return {
  type: '@historico/FINISHED_HISTORIC_SUCCESS',
  payload: {
   historic_id
  }
 };
}
