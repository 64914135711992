import React from 'react';

import { Typography, Grid, Box, CardContent, CardActions, CardHeader, Button, Card, Paper } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import FavoriteIcon from '@material-ui/icons/Favorite';

import useStyles from '../NewUser/styles';

export default function Plans({ plans, handleSubmitOrder }) {
 const classes = useStyles();

 return (
  <>
   <Typography className={classes.title} variant="h2">
    Assinar um plano
   </Typography>
   <Typography color="textSecondary" gutterBottom>
    Você poderá cancelar a qualquer momento de maneira simples
   </Typography>
   <Grid container spacing={5}>
    {plans.map(plan => (
     <Grid item key={plan.name} xs={12} md={6}>
      <Paper elevation={plan.name === 'Clínica' ? 4 : 1}>
       <Card>
        <CardHeader
         title={plan.name}
         subheader={plan.subtitle}
         titleTypographyProps={{ align: 'center' }}
         action={plan.name === 'Clínica' ? <FavoriteIcon color="secondary" /> : null}
         subheaderTypographyProps={{
          align: 'center'
         }}
        />
        <CardContent>
         <Box
          style={{
           display: 'flex',
           justifyContent: 'center',
           alignItems: 'baseline',
           mb: 2,
           marginBottom: 16
          }}>
          <Typography component="h1" variant="h1" color="text.primary">
           R${plan.value}
          </Typography>
          <Typography variant="h6" color="text.secondary">
           /mês
          </Typography>
         </Box>
         <ul>
          {plan.description.map(line => (
           <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item xs={1}>
             <DoneIcon fontSize="small" style={{ color: '#0abf53' }} />
            </Grid>
            <Grid item xs={11}>
             <Typography component="li" variant="subtitle1" align="left" key={line}>
              {line}
             </Typography>
            </Grid>
           </Grid>
          ))}
         </ul>
        </CardContent>
        <CardActions>
         <Button
          fullWidth
          variant={plan.name === 'Clínica' ? 'contained' : 'outlined'}
          color={plan.name === 'Clínica' ? 'secondary' : 'primary'}
          onClick={() => handleSubmitOrder(plan)}>
          {plan.name === 'Clínica' ? 'Assinar agora' : 'Assinar'}
         </Button>
        </CardActions>
       </Card>
      </Paper>
     </Grid>
    ))}
   </Grid>
  </>
 );
}
