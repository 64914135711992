import React from 'react';
import { Chart } from 'react-google-charts';
import { useSelector } from 'react-redux';

import {
 Card,
 CardHeader,
 CardContent,
 Divider,
 Button,
 Popper,
 Grow,
 MenuList,
 MenuItem,
 ClickAwayListener,
 Paper,
 CircularProgress
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
 root: {},
 chartContainer: {
  height: 400,
  position: 'relative'
 },
 actions: {
  justifyContent: 'flex-end'
 }
}));

const UltimosDias = props => {
 const { ultimosDias, loading } = useSelector(state => state.agenda);
 const { className, filter, setFilter, ...rest } = props;

 const classes = useStyles();

 const [open, setOpen] = React.useState(false);
 const anchorRef = React.useRef(null);

 const handleToggle = () => {
  setOpen(prevOpen => !prevOpen);
 };

 const handleClose = event => {
  if (anchorRef.current && anchorRef.current.contains(event.target)) {
   return;
  }

  setOpen(false);
 };

 function handleListKeyDown(event) {
  if (event.key === 'Tab') {
   event.preventDefault();
   setOpen(false);
  }
 }

 // return focus to the button when we transitioned from !open -> open
 const prevOpen = React.useRef(open);
 React.useEffect(() => {
  if (prevOpen.current === true && open === false) {
   anchorRef.current.focus();
  }

  prevOpen.current = open;
 }, [open]);

 function handleSetFilter(e) {
  let { value } = e.target;
  setFilter({ label: `Últimos ${value} dias`, value: value });
  handleClose(e);
 }

 return (
  <Card {...rest} className={clsx(classes.root, className)}>
   <CardHeader
    action={
     <>
      <Button
       disabled={loading && true}
       size="small"
       variant="text"
       ref={anchorRef}
       aria-haspopup="true"
       onClick={handleToggle}
       aria-controls={open ? 'menu-list-grow' : undefined}>
       {filter['label']}
       {loading ? <CircularProgress color="secondary" size={20} style={{ marginLeft: 5 }} /> : <ArrowDropDownIcon />}
      </Button>

      <Popper
       style={{ zIndex: 1000 }}
       open={open}
       anchorEl={anchorRef.current}
       role={undefined}
       transition
       disablePortal>
       {({ TransitionProps, placement }) => (
        <Grow
         {...TransitionProps}
         style={{
          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
         }}>
         <Paper>
          <ClickAwayListener onClickAway={handleClose}>
           <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
            <MenuItem name="7" value={7} onClick={e => handleSetFilter(e)}>
             Últimos 7 dias
            </MenuItem>
            <MenuItem value={15} onClick={e => handleSetFilter(e)}>
             Últimos 15 dias
            </MenuItem>
            <MenuItem value={30} onClick={e => handleSetFilter(e)}>
             Últimos 30 dias
            </MenuItem>
           </MenuList>
          </ClickAwayListener>
         </Paper>
        </Grow>
       )}
      </Popper>
     </>
    }
    title="Quantidade de atendimento"
   />
   <Divider />
   <CardContent>
    <div className={classes.chartContainer}>
     <Chart
      width={'100%'}
      height={'100%'}
      chartType="Bar"
      loader={<Skeleton animation="wave" variant="rect" width={'100%'} height={'100%'} />}
      data={ultimosDias}
      options={{
       title: 'Population of Largest U.S. Cities',
       chartArea: { width: '50%' },
       colors: ['#c89ffc'],
       hAxis: {
        title: 'Total Population',
        minValue: 0
       },
       vAxis: {
        title: 'City'
       },
       legend: { position: 'none' }
      }}
     />
    </div>
   </CardContent>
   <Divider />
   {/* <CardActions className={classes.actions}>
        <Button color="primary" size="small" variant="text">
          Overview <ArrowRightIcon />
        </Button>
      </CardActions> */}
  </Card>
 );
};

UltimosDias.propTypes = {
 className: PropTypes.string
};

export default UltimosDias;
