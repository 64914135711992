import api from '~/services/api';
import history from '~/services/history';
import * as AlertActions from '~/store/modules/alert/actions';
import { signInToOrderSuccess, signInSuccess } from '~/store/modules/auth/actions';
import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import { saveProfileSuccess } from '../user/actions';
import { setLoading } from './actions';

function* newRegister({ payload }) {
 yield put(setLoading(true));
 let { new_register } = payload;
 const { order } = yield select();

 new_register = {
  ...new_register,
  is_adm: true,
  is_professional: true,
  is_patient: false,
  is_collaborator: false,
  affiliate_id: order?.affiliate
 };

 try {
  const res = yield call(api.post, `register`, new_register);
  const token = res.data?.token;
  const refreshToken = res.data?.refresh_token;
  delete res.data.token;
  delete res.data.refresh_token;

  api.defaults.headers.Authorization = `Bearer ${token}`;
  const resUser = yield call(api.get, 'users/data');

  yield put(signInToOrderSuccess(res.data));
  yield put(saveProfileSuccess(resUser.data));
  yield put(signInSuccess(token, refreshToken));

  yield put(AlertActions.success('Cadastro feito com sucesso!'));

  history.push('/agenda');
  yield put(setLoading(false));
 } catch (error) {
  yield put(AlertActions.error('Erro interno. Tente novamente em alguns segundos.'));
  yield put(setLoading(false));
 }
}

export default all([takeLatest('@register/REGISTER_REQUEST', newRegister)]);
