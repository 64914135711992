import React from 'react';

import { Grid, Typography, Divider } from '@material-ui/core';

import useStyles from './styles';

export function Pharma() {
 const classes = useStyles();
 return (
  <Grid container className={classes.container}>
   <Grid item xs={12}>
    <Typography className={classes.title} variant="body1" color="textPrimary">
     IDENTIFICAÇÃO DO FORNECEDOR
    </Typography>
    <Divider />
   </Grid>
   <Grid item container xs={12}>
    <Grid item xs={12}>
     <Grid container justifyContent="center">
      <Grid item xs={12}>
       <Typography className={classes.signaturePharma} variant="body2" color="textPrimary">
        _____________________________________________________
       </Typography>
      </Grid>
      <Grid item xs={12}>
       <Typography className={classes.signaturePharma} variant="subtitle1" color="textPrimary">
        ASSINATURA DO FARMACÊUTICO
       </Typography>
      </Grid>
     </Grid>
    </Grid>
    <Grid item xs={12}>
     <Typography className={classes.signature} variant="body2" color="textPrimary">
      ____/____/{new Date().getUTCFullYear()}
     </Typography>
    </Grid>
   </Grid>
  </Grid>
 );
}
